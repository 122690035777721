import React from 'react';

import {useRecoilValue} from 'recoil';

import SurveyInputComboBox from "@/components/survey/SurveyInputComboBox";
import SurveyInputFile from "@/components/survey/SurveyInputFile";
import SurveyInputLikertScale from "@/components/survey/SurveyInputLikertScale";
import SurveyInputSelect from "@/components/survey/SurveyInputSelect";
import SurveyInputText from "@/components/survey/SurveyInputText";
import SurveyInputTitle from "@/components/survey/SurveyInputTitle";
import {surveyState} from "@/stores/survey";
import {SurveyTemplateProps} from "@/types/survey.type";

function SurveyAnswerInput({
  index,
  label,
  labelStyle,
  courseId,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  label: string;
  labelStyle: React.CSSProperties;
  courseId: string;
  upperQuestion?: SurveyTemplateProps;
  questionIndex?: number;
}) {
  const surveyRegisterState = useRecoilValue(surveyState.surveyRegisterState);

  const currentQuestion = upperQuestion
    ? surveyRegisterState.template
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : surveyRegisterState.template.find(({seq}) => seq === index);

  return (
    <>
      <div className="flex items-center">
        {upperQuestion ? (
          <SurveyInputTitle
            index={index}
            label={label}
            labelStyle={labelStyle}
            upperQuestion={upperQuestion}
            questionIndex={questionIndex}
          />
        ) : (
          <SurveyInputTitle index={index} label={label} labelStyle={labelStyle} />
        )}
      </div>
      <div className={'w-full pt-3 flex justify-start'}>
        {(currentQuestion?.type === 'SHORT_TEXT' ||
          currentQuestion?.type === 'LONG_TEXT') &&
          (upperQuestion ? (
            <SurveyInputText
              type={currentQuestion.type}
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <SurveyInputText type={currentQuestion.type} index={index} />
          ))}
        {(currentQuestion?.type === 'SINGLE_SELECT' ||
          currentQuestion?.type === 'MULTI_SELECT') &&
          (upperQuestion ? (
            <SurveyInputSelect
              type={currentQuestion.type}
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <SurveyInputSelect type={currentQuestion.type} index={index} />
          ))}
        {currentQuestion?.type === 'LIKERT_SCALE' &&
          (upperQuestion ? (
            <SurveyInputLikertScale
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <SurveyInputLikertScale index={index} />
          ))}
        {currentQuestion?.type === 'FILE_UPLOAD' &&
          (upperQuestion ? (
            <SurveyInputFile
              index={index}
              courseId={courseId}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <SurveyInputFile index={index} courseId={courseId} />
          ))}
        {currentQuestion?.type === 'COMBO_BOX' &&
          (upperQuestion ? (
            <SurveyInputComboBox
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <SurveyInputComboBox index={index} />
          ))}
      </div>
    </>
  );
}

export default SurveyAnswerInput;
