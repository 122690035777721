import {useState, useEffect, useMemo} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import {BoardLayout, Skeleton} from '@/components/common';
import {SurveyAnswerModal} from '@/components/survey/SurveyAnswerModal';
import {useChapter} from '@/hooks/useChapter';
import {useMyCourseDetail} from '@/hooks/useCourse';
import useModal from '@/hooks/useModal';
import {useSurvey, useSurveyAnswerList} from '@/hooks/useSurvey';
import useUser from '@/hooks/useUser';
import {
  ChapterInfoCard,
  CourseInfoDetailCard,
} from '@/pages/new-course/components';
import {Chapter} from '@/types/chapter.type';
import {replaceDateFormat} from '@/utils/format';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

export function NewCourseDetail() {
  const modal = useModal();
  const {user} = useUser();
  const navigate = useNavigate();
  const {id: courseId = ''} = useParams<{id: string}>();
  const [selectedMenu, setSelectedMenu] = useState('courseInfo');
  const [activeChapterList, setActiveChapterList] = useState<Chapter[]>();
  const {data: courseInfo, refetch: refetchCourseInfo} =
    useMyCourseDetail(courseId);
  const {
    chapterList,
    isLoading: isLoadingChapter,
    refetch: refetchChapter,
  } = useChapter(courseId);

  const {data: surveyInfo, refetch: refetchSurveyInfo} = useSurvey(courseId);
  const surveyData = surveyInfo?.content?.[0];

  const {data: surveyAnswerData} = useSurveyAnswerList(
    surveyData?.id,
    user?.id,
  );

  const onClickBoard = (type: string) => {
    if (selectedMenu !== type) {
      setSelectedMenu(type);
    }
  };

  useEffect(() => {
    if (!courseId) {
      alert('해당 강의가 없습니다.');
      navigate('/course');
    }
  }, [courseId]);

  useEffect(() => {
    if (!chapterList || chapterList.length === 0) return;

    const activeChapterListData = chapterList.filter(
      (item: Chapter) => item.status === 'ACTIVE',
    );
    setActiveChapterList(activeChapterListData);
  }, [chapterList]);

  useEffect(() => {
    if (selectedMenu === 'chapter') {
      refetchChapter().then();
    }
  }, [selectedMenu]);

  useEffect(() => {
    refetchCourseInfo().then();
  }, [modal.isShowing]);

  const showingSurvey = useMemo(() => {
    if (!courseInfo) {
      return false;
    }

    const {progressRate = 0, attendRate = 0, homeworkRate = 0} = courseInfo;
    return (
      (progressRate == 100 && attendRate == 100 && homeworkRate == 100) ||
      courseInfo.completeDate
    );
  }, [courseInfo]);

  useEffect(() => {
    if (surveyAnswerData?.content) return;

    setTimeout(() => {
      refetchCourseInfo && refetchCourseInfo();
      refetchSurveyInfo && refetchSurveyInfo();
      if (courseInfo && surveyInfo) {
        const isCourseIdAndUseSurvey = courseInfo.id && courseInfo.useSurvey;

        const currentDate = new Date();
        const startsAt = surveyData?.startsAt
          ? new Date(surveyData.startsAt)
          : null;
        const endsAt = surveyData?.endsAt ? new Date(surveyData.endsAt) : null;

        const isDateRange =
          (startsAt === null || currentDate >= startsAt) &&
          (endsAt === null || currentDate <= endsAt);

        if (
          isDateRange &&
            (isCourseIdAndUseSurvey &&
              surveyData?.displayCondition === 'FINISH' &&
              showingSurvey)
        ) {
          modal.openModal({});
        }
      }
    }, 1);
  }, [courseInfo]);

  const shouldShowModal =
    courseInfo?.id &&
    courseInfo.useSurvey &&
    (surveyData?.displayCondition === 'START' ||
      (surveyData?.displayCondition === 'FINISH' &&
        (showingSurvey || !!courseInfo.completeDate)));

  return (
    <BoardLayout
      boardName="교육수강"
      boardType="course"
      boardDetailName="세부강의"
      bgWhite>
      <div className=" pt-[80px]">
        {courseInfo && (
          <>
            {/* 상단 수강 정보 카드 */}
            {courseInfo.id && (
              <CourseInfoDetailCard
                courseInfo={courseInfo}
                isCourseDetail={true}
              />
            )}
            {/* 설문지 모달 */}
            {shouldShowModal && (
              <SurveyAnswerModal
                modal={modal}
                userName={user?.name || ''}
                courseInfo={courseInfo}
                surveyInfo={surveyData}
              />
            )}
            {/* 교육 자료 */}
            <div className="relative pt-[77px] pb-[100px]">
              {/* 메뉴 버튼 */}
              <div className="grid grid-cols-2 font-bold text-xl hover:cursor-pointer ">
                <div
                  className={`text-center pt-4 w-full h-[55px] border-t rounded-t-[40px] border-transparent ${
                    selectedMenu === 'courseInfo'
                      ? 'text-white bg-primary'
                      : 'text-gray-400 bg-readOnly'
                  }`}
                  onClick={() => onClickBoard('courseInfo')}>
                  강의 소개
                </div>
                <div
                  className={`text-center pt-4 w-full h-[55px] border-t rounded-t-[40px] border-transparent ${
                    selectedMenu === 'chapters'
                      ? 'text-white bg-primary'
                      : 'text-gray-400 bg-readOnly'
                  }`}
                  onClick={() => onClickBoard('chapters')}>
                  강의 듣기
                </div>
              </div>
              {/* 본문 */}
              {/* 강의 소개*/}
              {selectedMenu === 'courseInfo' && (
                <div className=" w-full bg-white border course-shadow px-[60px] py-10 ">
                  <div className="mb-12">
                    {/* 강의 정보 */}
                    <h4 className="font-bold text-3xl mb-[26px]">강의 정보</h4>
                    <div className="flex">
                      <div className="w-2/5 flex flex-col">
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-[5px]">
                            모집기간
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {replaceDateFormat(
                              courseInfo.recruitStartsAt,
                              'YYYY.MM.DD',
                            )}{' '}
                            ~{' '}
                            {replaceDateFormat(
                              courseInfo.recruitEndsAt,
                              'YYYY.MM.DD',
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-[5px]">
                            교육기간
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {replaceDateFormat(
                              courseInfo.startsAt,
                              'YYYY.MM.DD',
                            )}{' '}
                            ~{' '}
                            {replaceDateFormat(courseInfo.endsAt, 'YYYY.MM.DD')}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            수강료
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.courseFee}원
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            모집인원
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.applicantsCount}명
                          </div>
                        </div>
                      </div>
                      <div className="w-3/5 flex flex-col">
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            우대조건
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.preferentialCondition || '없음'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            교육형태
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.courseType === 'ONLINE'
                              ? '원격'
                              : courseInfo.courseType === 'OFFLINE'
                              ? '집합'
                              : '-'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            교육일정
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.schedule || ' 없음'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            지원조건
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.applicationCondition || ' 없음'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-[30px]">
                    {/* 세부 설명 */}
                    <h4 className="font-bold text-3xl mb-[26px]">세부 설명</h4>
                    <div className=" mx-auto flex flex-col align-center">
                      {parseHtmlContentWithSanitize(courseInfo?.content ?? '')}
                    </div>
                  </div>
                </div>
              )}
              {/* 강의 듣기 */}
              {selectedMenu === 'chapters' && (
                <div className="w-full bg-white border course-shadow">
                  {!isLoadingChapter ? (
                    activeChapterList && activeChapterList.length > 0 ? (
                      activeChapterList.map((item: Chapter, index: number) => (
                        <ChapterInfoCard
                          key={item.id}
                          courseInfo={courseInfo}
                          chapterItem={item}
                          index={index}
                        />
                      ))
                    ) : (
                      <div className="py-[120px] flex justify-center border border-b-1 font-bold text-xl text-[#808080]">
                        등록된 강의가 없습니다.
                      </div>
                    )
                  ) : (
                    <Skeleton />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </BoardLayout>
  );
}
