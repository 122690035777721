import {useState, ChangeEvent, MouseEvent, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as EyeInvisible} from '@/assets/newImg/eye-invisible-bold-s.svg';
import {ReactComponent as EyeVisible} from '@/assets/newImg/eye-visible-bold-s.svg';
import {NewLayout, FormLayout} from '@/components/common';
import {Input} from '@/components/common/NewInput';
import {Modal} from '@/components/common/NewModal';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {signInService} from '@/services/userService';
import siteState from '@/stores/site';
import {
  ValidateEmailSchema,
  ValidatePasswordSchema,
  ValidateSignInSchema,
} from '@/utils/validator';

const INIT_FORM_STATE = {email: '', password: ''};

export function NewSignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectQuery = queryParams.get('redirect');
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {refetchUser} = useUser();
  const siteData = useRecoilValue(siteState.siteInfoState);
  const [form, setForm] = useState(INIT_FORM_STATE);
  const [formCaption, setFormCaption] = useState(INIT_FORM_STATE);
  const [visiblePassword, setVisiblePassword] = useState(false);

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    const newForm = {...form, [id]: value};
    setForm(newForm);

    try {
      switch (id) {
        case 'email':
          ValidateEmailSchema.validateSync(value);
          break;
        case 'password':
          ValidatePasswordSchema.validateSync(value);
          break;
      }
      setFormCaption({
        ...formCaption,
        [id]: '',
      });
    } catch (e: any) {
      setFormCaption({
        ...formCaption,
        [id]: e.message,
      });
    }
  }

  async function handleSubmit(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const {email, password} = form;
    try {
      ValidateSignInSchema.validateSync({email, password});
    } catch (error) {
      openModal({
        title: '로그인 실패',
        message: '로그인 정보를 올바르게 입력해주세요.',
      });
      return;
    }

    const response = await signInService(siteData?.id || '', email, password);

    if(response?.data?.error?.code){
      if(response?.data?.error?.code === '1014'){
        openModal({
          title: '로그인 실패',
          message: '최근 로그인 시도로 인해 현재 로그인(JWT) 토큰이 비활성화되었습니다. 다시 로그인 해주세요.',
        });
        return;
      }else{
        openModal({
          title: '로그인 실패',
          message: '로그인 정보를 확인해주세요.',
        });
        return;
      }
    }

    await refetchUser();

    if (redirectQuery) {
      navigate(redirectQuery);
      return;
    }
    navigate('/');
  }

  useEffect(() => {
    const handleBackClick = (e: PopStateEvent) => {
      // 뒤로 가기 이벤트가 발생했을 때
      if (redirectQuery) {
        navigate('/');
      }
    };
    window.addEventListener('popstate', handleBackClick);
  }, []);

  return (
    <>
      <NewLayout>
        <FormLayout>
          <p className="font-bold text-[30px] mb-8">로그인</p>
          <form className="w-full flex flex-col items-center">
            <Input
              id="email"
              type="email"
              placeholder="이메일을 입력하세요."
              label="이메일"
              value={form.email}
              onChange={handleForm}
            />
            {formCaption.email ? (
              <p className="text-rose-500 w-full text-sm mb-6">
                {formCaption.email}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            <Input
              id="password"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호"
              label="비밀번호"
              value={form.password}
              onChange={handleForm}
              rightIcon={
                visiblePassword ? (
                  <EyeVisible
                    className="ml-2"
                    width={22}
                    height={15}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                ) : (
                  <EyeInvisible
                    className="ml-2"
                    width={22}
                    height={17}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                )
              }
            />
            {formCaption.password ? (
              <p className="text-rose-500 w-full text-sm mb-10">
                {formCaption.password}
              </p>
            ) : (
              <div className="mb-[42px]" />
            )}
            <button
              type="submit"
              onClick={handleSubmit}
              className="font-bold text-[20px] w-full border border-transparent py-4 bg-[#337CCF] text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-[#337CCF] hover:text-[#337CCF]">
              로그인하기
            </button>
          </form>

          {/* 로그인 관련 페이지 이동 버튼 */}
          <div className="mt-[25px] mb-[28px] w-full px-[52px] flex justify-between items-center">
            <Link
              to="/new-sign-up"
              className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
              회원가입
            </Link>
            <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
            <Link
              to="/new-user/find/password"
              className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
              비밀번호 찾기
            </Link>
            <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
            <Link
              to="/new-user/find/email"
              className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
              이메일 찾기
            </Link>
          </div>
        </FormLayout>
      </NewLayout>
      <Modal
        isShowing={isShowing}
        type="alert"
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                closeModal();
              },
            },
          ]
        }
      />
    </>
  );
}
