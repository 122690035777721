import {useEffect} from 'react';

import {useRecoilValue} from 'recoil';

import {NewLayout} from '@/components/common';
import {useBoards} from '@/hooks/useBoards';
import siteState from '@/stores/site';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

export function ServicePolicy() {
  const siteData = useRecoilValue(siteState.siteInfoState);
  const {
    data: policyData,
    isLoading,
    refetch,
  } = useBoards({
    category: 'SERVICE_POLICY',
  });

  // 페이지 새로고침 시
  useEffect(() => {
    refetch();
  }, [siteData]);

  return (
    <NewLayout>
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">이용 약관</h1>
      </div>

      {!isLoading && policyData.length > 0 && (
        <div>
          <div className="w-full px-4 my-4 pb-2 border-b border-gray-400">
            <h1 className="text-2xl font-bold">{policyData[0].title}</h1>
          </div>
          <div className="w-full px-4 mb-8">
            {parseHtmlContentWithSanitize(policyData[0].content)}
          </div>
        </div>
      )}
    </NewLayout>
  );
}
