import React from 'react';

import {useRecoilValue} from 'recoil';

import InputComboBox from  '@/components/applicationForm/InputComboBox';
import InputFile from '@/components/applicationForm/InputFile';
import InputLikertScale from '@/components/applicationForm/InputLikertScale';
import InputSelect from '@/components/applicationForm/InputSelect';
import InputText from '@/components/applicationForm/InputText';
import InputTitle from '@/components/applicationForm/InputTitle';
import applyState from '@/stores/application';
import {ApplicationFormProps} from '@/types/application.type';

function AnswerInput({
  index,
  label,
  labelStyle,
  courseId,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  label: string;
  labelStyle: React.CSSProperties;
  courseId: string;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const applyRegisterState = useRecoilValue(applyState.applyRegisterState);

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  return (
    <>
      <div className="flex items-center">
        {upperQuestion ? (
          <InputTitle
            index={index}
            label={label}
            labelStyle={labelStyle}
            upperQuestion={upperQuestion}
            questionIndex={questionIndex}
          />
        ) : (
          <InputTitle index={index} label={label} labelStyle={labelStyle} />
        )}
      </div>
      <div className={'w-full pt-3 flex justify-start'}>
        {(currentQuestion?.type === 'SHORT_TEXT' ||
          currentQuestion?.type === 'LONG_TEXT') &&
          (upperQuestion ? (
            <InputText
              type={currentQuestion.type}
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <InputText type={currentQuestion.type} index={index} />
          ))}
        {(currentQuestion?.type === 'SINGLE_SELECT' ||
          currentQuestion?.type === 'MULTI_SELECT') &&
          (upperQuestion ? (
            <InputSelect
              type={currentQuestion.type}
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <InputSelect type={currentQuestion.type} index={index} />
          ))}
        {currentQuestion?.type === 'LIKERT_SCALE' &&
          (upperQuestion ? (
            <InputLikertScale
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <InputLikertScale index={index} />
          ))}
        {currentQuestion?.type === 'FILE_UPLOAD' &&
          (upperQuestion ? (
            <InputFile
              index={index}
              courseId={courseId}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <InputFile index={index} courseId={courseId} />
          ))}
        {currentQuestion?.type === 'COMBO_BOX' &&
          (upperQuestion ? (
            <InputComboBox
              index={index}
              upperQuestion={upperQuestion}
              questionIndex={questionIndex}
            />
          ) : (
            <InputComboBox index={index} />
          ))}
      </div>
    </>
  );
}

export default AnswerInput;
