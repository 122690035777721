import {PaginationResponseProps} from '@/types/common.type';

export function PaginationInfo({
  paginationInfo,
}: {
  paginationInfo: PaginationResponseProps<any>['page'];
}) {
  return (
    <div className="flex justify-between pt-[3px] pb-[9px] text-[16px] text-[#b4b4b4]">
      <div>
        전체 <span className="text-primary">{paginationInfo.total}</span>건
      </div>
      <div>
        현재 페이지{' '}
        <span className="text-primary">{paginationInfo.currentPage}</span>/
        <span>{paginationInfo.totalPages || 1}</span>
      </div>
    </div>
  );
}
