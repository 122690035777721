import React, {InputHTMLAttributes, ReactNode} from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  rightButton?: ReactNode;
  caption?: ReactNode;
  labelStyle?: React.CSSProperties;
  addClass?: string;
}

export function Input({
  label,
  leftIcon,
  rightIcon,
  rightButton,
  labelStyle,
  addClass,
  ...props
}: InputProps) {
  return (
    <div className="w-full flex flex-col mb-[10px]">
      {label && (
        <label
          className="mb-[10px] text-[#808080] text-[18px] font-medium"
          style={{...labelStyle}}>
          {label}
        </label>
      )}
      <div className="flex items-center bg-white">
        <div
          className={`relative w-full h-[60px] px-[30px] flex items-center rounded-[10px] border border-[#e5e5e5] ${addClass} ${
            rightButton && 'mr-[20px]'
          }`}>
          <input
            {...props}
            className="w-full bg-transparent text-[#404040] placeholder-disabled p-0 appearance-none border-none placeholder:text-[16px] focus:ring-0 text-base focus:outline-none"
          />
          {rightIcon}
        </div>
        <div className="w-auto">{rightButton}</div>
      </div>
    </div>
  );
}
