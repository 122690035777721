import {ReactNode, useEffect, useMemo, useState} from 'react';

import {ReactComponent as ArrowRightIcon} from '@/assets/newImg/arrow-right-bold-s.svg';

function PageNumber({
  disable,
  selected,
  children,
  onClick,
}: {
  disable?: boolean;
  selected?: boolean;
  children: ReactNode;
  onClick: () => void;
}) {
  const fontColor = useMemo(() => {
    if (disable) {
      return '#cccccc';
    }
    if (selected) {
      return 'text-white';
    }
    return 'text-stone-300';
  }, [disable, selected]);

  const backgroundColor = useMemo(
    () => (selected ? 'bg-primary ' : 'bg-white'),
    [selected],
  );

  const hoverStyle = useMemo(
    () =>
      disable
        ? undefined
        : 'hover:bg-secondary hover:border-secondary hover:text-white',
    [disable],
  );

  const cursorStyle = useMemo(
    () => (disable ? 'cursor-default' : 'cursor-pointer'),
    [disable],
  );

  const borderColor = useMemo(() => {
    if (disable) {
      return 'border-stone-300';
    }
    if (selected) {
      return 'border-primary';
    }
    return 'border-stone-300';
  }, [disable, selected]);

  return (
    <div
      className={`flex justify-center items-center w-[36px] h-[36px] px-3 pt-[5px] pb-[6px] rounded-[10px] border-2 ${fontColor} ${backgroundColor} ${cursorStyle} ${hoverStyle} ${borderColor}`}
      onClick={onClick}>
      <p className="font-bold font-lg">{children}</p>
    </div>
  );
}


function CPageArrow({
  disable = false,
  direction = 'right',
  count = 1,
  onClick,
}: {
  disable?: boolean;
  direction?: 'left' | 'right';
  count?: number;
  onClick: () => void;
}) {
  const [mouseHover, setMouseHover] = useState(false);

  const backStyle = useMemo(() => {
    if (disable) {
      return 'border-stone-300 bg-white';
    }
    if (mouseHover) {
      return 'border-[#337ccf] bg-[#337ccf]';
    }
    return 'border-stone-300 bg-white';
  }, [mouseHover, disable]);

  const iconStyle = useMemo(() => {
    if (disable) {
      return 'fill-stone-300';
    }
    if (mouseHover) {
      return 'fill-white';
    }
    return 'fill-stone-300';
  }, [mouseHover, disable]);

  const rotationClass = direction === 'left' ? 'rotate-180' : '';

  return (
    <div
      className={`flex w-[36.8px] h-[36.8px]`}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      onClick={onClick}>
      <div
        className={`flex justify-center items-center w-[36.75px] h-[36.75px] rounded-[10px] border-2 ${backStyle}`}>
        {Array(count)
          .fill(null)
          .map((_, idx) => (
            <ArrowRightIcon
              key={idx}
              width="8.8"
              height="14.2"
              className={`${rotationClass} ${iconStyle}`}
            />
          ))}
      </div>
    </div>
  );
}

interface BoardPaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  onClick: (page: number) => void;
}

export function Pagination({
  totalCount,
  pageSize,
  currentPage,
  totalPage,
  onClick,
}: BoardPaginationProps) {
  const [pageList, setPageList] = useState<Array<number>>([]);
  useEffect(() => {
    const nextTotalPage = Math.ceil(totalCount / pageSize);
    const nextPageList: Array<number> = [];

    if (nextTotalPage === 0) {
      setPageList(nextPageList);
      return;
    }

    if (currentPage <= nextTotalPage && nextTotalPage <= 5) {
      for (let i = 1; i <= nextTotalPage; i++) {
        nextPageList.push(i);
      }
      setPageList(nextPageList);
      return;
    }

    if (currentPage === totalPage) {
      nextPageList.push(currentPage);
      setPageList(nextPageList);
      return;
    }

    if (currentPage % 5 === 0) {
      for (let i = currentPage - 5 + 1; i <= currentPage; i++) {
        nextPageList.push(i);
      }
      setPageList(nextPageList);
      return;
    }

    const startPage = Math.floor(currentPage / 5) * 5 + 1;
    for (let i = startPage; i < startPage + 5; i++) {
      nextPageList.push(i);
    }
    setPageList(nextPageList);
  }, [pageSize, currentPage, totalPage, totalCount]);

  return (
    <div
      className={
        'w-max min-w-full flex justify-center items-center gap-[6.5px]'
      }>
      <CPageArrow
        disable={currentPage === 1}
        direction="left"
        count={2}
        onClick={() => (currentPage === 1 ? {} : onClick(1))}
      />
      <CPageArrow
        disable={currentPage === 1}
        direction="left"
        count={1}
        onClick={() => (currentPage === 1 ? {} : onClick(currentPage - 1))}
      />
      {pageList.map(page => {
        return (
          <PageNumber
            key={page}
            selected={page === currentPage}
            onClick={() => onClick(page)}>
            {page}
          </PageNumber>
        );
      })}

      <CPageArrow
        disable={currentPage === totalPage || !totalPage || totalPage === 0}
        direction="right"
        count={1}
        onClick={() =>
          currentPage + 1 > totalPage ? {} : onClick(currentPage + 1)
        }
      />

      <CPageArrow
        disable={currentPage === totalPage || !totalPage || totalPage === 0}
        direction="right"
        count={2}
        onClick={() => (currentPage === totalPage ? {} : onClick(totalPage))}
      />
    </div>
  );
}
