import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import {PAGINATION_DATA} from '@/constants/common';
import {
  getNewHomeworkDetailService,
  getSubmitHomeworkDetailService,
} from '@/services/chapterService';

interface UseHomeworkParams {
  chapterId: string;
  userId?: string;
  homeworkId?: string;
  searchQuery?: string | null;
}

export function useHomework({
  chapterId,
  userId,
  homeworkId,
  searchQuery,
}: UseHomeworkParams) {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 10});

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['homework', {chapterId, userId, homeworkId, searchQuery}],
    queryFn: async ({queryKey}) => {
      const {chapterId, userId, searchQuery} = queryKey[1] as {
        chapterId: string;
        userId: string;
        homeworkId: string;
        searchQuery: string;
      };
      if (!chapterId || !userId) {
        return PAGINATION_DATA;
      }

      const data = {
        userId,
        page: pageParams.page,
        pageSize: pageParams.pageSize,
        ...(searchQuery ? {homeworkTitle: searchQuery} : {}),
      };

      return await getNewHomeworkDetailService(chapterId, data);
    },
    initialData: PAGINATION_DATA,
  });

  useEffect(() => {
    refetch().then().catch();
  }, [pageParams]);

  function onChangePage(page: number) {
    setPageParams({
      ...pageParams,
      page,
    });
  }

  return {
    chapterHomeworks: data?.content ?? [],
    isLoading,
    paginationInfo: data?.page ?? PAGINATION_DATA.page,
    onChangePage,
    refetch,
  };
}

export const useHomeworkGetSubmitList = ({
  chapterId,
  homeworkId,
  userId,
  pageInfo,
}: {
  chapterId: string;
  homeworkId?: string;
  userId?: string;
  pageInfo?: {
    page: number;
    pageSize: number;
  };
}) => {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: ['homeworkSubmitList', {chapterId, homeworkId, userId}],
    queryFn: async () => {
      return await getSubmitHomeworkDetailService({
        chapterId,
        homeworkId,
        userId,
        pageInfo,
      });
    },
  });

  return {
    data,
    refetch,
    isLoading: isLoading && isFetching,
  };
};
export const useHomeworkGetSubmitOne = ({
  chapterId,
  homeworkId,
  userId,
}: {
  chapterId: string;
  homeworkId: string;
  userId?: string;
}) => {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: ['homeworkSubmitItem', {chapterId, homeworkId, userId}],
    queryFn: async () => {
      return await getSubmitHomeworkDetailService({
        chapterId,
        homeworkId,
        userId,
      });
    },
    initialData: PAGINATION_DATA,
    enabled: !!homeworkId,
  });
  return {
    data: data?.content?.[0] ?? null,
    refetch,
    isLoading: isLoading && isFetching,
  };
};
