import {atom} from 'recoil';

import {AnswerFormProps, ApplyFormRequestProps} from '@/types/application.type';

export const applyRegisterState = atom<ApplyFormRequestProps>({
  key: 'applyRegisterState',
  default: {
    courseId: '',
    title: '',
    description: '',
    applicationForm: [
      {
        seq: 0,
        type: 'NONE',
        isRequired: false,
        question: '',
        options: null,
        config: {
          questionName: '',
        },
        subQuestions: null,
      },
    ],
  },
});

export const answerRegisterState = atom<AnswerFormProps>({
  key: 'answerRegisterState',
  default: {
    answers: [
      {
        seq: 0,
        type: 'NONE',
        isRequired: false,
        question: '',
        questionId: '',
        options: null,
        config: {
          questionName: '',
        },
        answer: '',
        subQuestions: null,
      },
    ],
  },
});
