import {
  reqApplyCourse,
  reqApplyKacwCourse,
  reqGetApplicationFormAnswer,
  reqGetCourseDetail,
  reqGetCourseList,
  reqGetCourseListExtendDone,
  reqGetXProgress,
  reqPutApplicationFormAnswer,
} from '@/api/courseApi';
import {
  AnsweredFormsDataProps,
  AnswerFormProps,
} from '@/types/application.type';
import {
  Attachment,
  PaginationResponseProps,
  XProgress,
} from '@/types/common.type';
import {
  Course,
  ReqGetXProgressParams,
  ReqUserCourseListParams,
  userCourseListResponse,
} from '@/types/course.type';

interface ReqGetMyCourseListParams {
  userId: string;
  siteId: string;
  academyId: string | null;
  searchQuery?: string | null;
  page?: number;
  pageSize?: number;
}

export async function getMyCourseListService({
  userId,
  siteId,
  academyId,
  searchQuery,
  page = 1,
  pageSize = 100,
}: ReqGetMyCourseListParams): Promise<PaginationResponseProps<Course>> {
  const data = {
    userId,
    siteId,
    academyId,
    status: 'ACTIVE',
    page,
    pageSize,
    filter: '@my',
    ...(searchQuery ? {name: searchQuery} : {}),
  };
  const courseResponse = await reqGetCourseList(data);

  return courseResponse.data.data;
}

export async function getXProgressService({
  siteId,
  xType,
  courseId,
}: ReqGetXProgressParams): Promise<XProgress[]> {
  try {
    const response = await reqGetXProgress({
      siteId,
      xType,
      courseId,
    });
    return response.data.data;
  } catch (e: any) {
    // TODO : 에러 처리
    return [];
  }
}

export async function searchCourseDetailService(
  courseId: string,
  userId?: string,
) {
  const result = await reqGetCourseDetail(courseId, userId);
  return result.data.data;
}

export async function applyCourseService(courseId: string, answerId?: string) {
  const response = await reqApplyCourse(courseId, answerId);
  return response.data.data.result;
}

export async function applyKacwCourseService(
  courseId: string,
  attachments?: Attachment[],
) {
  const response = await reqApplyKacwCourse(courseId, attachments);
  return response.data.data;
}

export async function getUserCourseListService({
  page,
  pageSize,
  uid,
  isComplete,
}: ReqUserCourseListParams): Promise<
  PaginationResponseProps<userCourseListResponse>
> {
  const data = {
    page,
    pageSize,
    uid,
    isComplete,
  };

  const courseResponse = await reqGetCourseListExtendDone(data);

  return courseResponse.data.data;
}

export async function getApplicationFormAnswer(
  surveySvrAnswerId: string,
): Promise<PaginationResponseProps<AnsweredFormsDataProps>> {
  const applicationFormAnswerResponse = await reqGetApplicationFormAnswer(
    surveySvrAnswerId,
  );
  return applicationFormAnswerResponse.data.data;
}

export async function putApplicationFormAnswer(
  answerId: string,
  editedAnswerFormData: AnswerFormProps,
): Promise<PaginationResponseProps<boolean>> {
  const editApplicationFormAnswerResponse = await reqPutApplicationFormAnswer(
    answerId,
    editedAnswerFormData,
  );
  return editApplicationFormAnswerResponse.data.data;
}
