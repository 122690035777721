import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import ArrowDownIcon from '@/assets/newImg/arrow-down.svg';
import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  ApplicationFormProps,
  optionsProps,
} from '@/types/application.type';

function InputComboBox({
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const [applyRegisterState] = useRecoilState(applyState.applyRegisterState);
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );

  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<optionsProps | null>(
    null,
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: optionsProps) => {
    setSelectedOption(option);
    setIsOpen(false);
    setAnswerRegisterState((prev: AnswerFormProps) => {
      const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
        currentQuestion?.seq === item.seq && !upperQuestion
          ? {
              ...item,
              answer: option,
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: option,
                    }
                  : subItem,
              ),
            }
          : item,
      );
      return {
        ...prev,
        answers: newAnswerRegisterState,
      };
    });
  };

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      answerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setAnswerRegisterState((prev: AnswerFormProps) => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentQuestion?.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: '',
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: '',
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);

  return (
    <div className="w-full">
      <div
        onClick={handleToggle}
        className={`w-full px-[30px] flex justify-between items-center rounded-[10px] border border-[#e5e5e5] py-2 focus:outline-none focus:border-indigo-500 hover:cursor-pointer ${
          disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
        }`}>
        {selectedOption && !disabled ? (
          typeof selectedOption.value !== 'object' && selectedOption.value
        ) : (
          <label className="text-[#C0C0C0]">선택해주세요</label>
        )}
        <img src={ArrowDownIcon} alt={''} />
      </div>
      {isOpen && !disabled && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[99999999]">
          <div className="py-1">
            {currentQuestion?.options?.map((option, optionIndex) => (
              <button
                key={optionIndex}
                onClick={() => handleSelectOption(option)}
                disabled={disabled}
                className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}>
                {typeof option.value !== 'object' ? option.value : null}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default InputComboBox;
