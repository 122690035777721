import {
  reqGetChapter,
  reqSavePlayTimeHistory,
  reqNewGetHomework,
  reqNewGetSubmitHomework,
} from '@/api/chapterApi';
import {Chapter} from '@/types/course.type';

export async function searchChapterService(
  chapterId: string,
): Promise<Chapter> {
  const response = await reqGetChapter(chapterId);
  return response.data.data;
}

export async function savePLayTimeHistoryService(
  chapterId?: string | null,
  eventType?: string,
  eventTime?: number,
) {
  if (!chapterId || !eventType || !eventTime) {
    return;
  }
  const response = await reqSavePlayTimeHistory(
    chapterId,
    eventType,
    Math.ceil(eventTime),
  );
  return response.data.data;
}


// chapter별 homework list
interface GetHomeworkDetailServiceParams {
  page: number;
  pageSize: number;
  searchQuery?: string;
}

// chapter별 제출 정보 list
export async function getNewHomeworkDetailService(
  chapterId: string,
  params: GetHomeworkDetailServiceParams,
) {
  const response = await reqNewGetHomework(chapterId, params);
  return response.data.data;
}

export async function getSubmitHomeworkDetailService({
  chapterId,
  homeworkId,
  userId,
  pageInfo,
}: {
  chapterId: string;
  homeworkId?: string;
  userId?: string;
  pageInfo?: {
    page: number;
    pageSize: number;
  };
}) {
  const response = await reqNewGetSubmitHomework({
    chapterId,
    homeworkId,
    userId,
    pageInfo: pageInfo || {
      page: 1,
      pageSize: 10,
    },
  });
  return response.data.data;
}
