import {useState} from 'react';

import {useRecoilValue} from "recoil";

import BannerWide2 from '@/assets/img/banner2.jpg';
import BannerWide3 from '@/assets/img/banner3.jpg';
import BannerWide1 from '@/assets/img/banner_wide.png';
import siteState from "@/stores/site";

/**
 * Main Banner
 * TODO: Carousel 로 변경하기
 */
export default function MainBanner() {
    const siteInfo = useRecoilValue(siteState.siteInfoState);
  // TODO: 실제 기관이 업로드 된 배너 이미지를 통해 배너를 구성해야 함
  const bannerItems = siteInfo?.subDomain === 'gwcloud'
    ? [{name: 'Banner1', url: BannerWide1}]
    : [
        {name: 'Banner1', url: BannerWide1},
        {name: 'Banner2', url: BannerWide2},
        {name: 'Banner3', url: BannerWide3},
      ];

  const [currentBanner, setCurrentBanner] = useState(bannerItems[0].name);
  const handleBanner = (index: number) => {
    setCurrentBanner(bannerItems[index].name);
  };
  return (
    <div className="relative h-[554px] justify-center px-auto flex  bg-[#f5f5f5]">
      {bannerItems.map(item => (
        <img
          key={item.name}
          src={item.url}
          alt=""
          className={`object-cover ${
            currentBanner !== item.name ? ' hidden ' : ''
          }`}
        />
      ))}
      {bannerItems.length > 1 && (
        <div className="absolute bottom-[30px] left-[50%] -translate-x-[50%] w-[270px] h-[12px] rounded-[10px] bg-[#0F1152] overflow-hidden ">
          <div className="flex w-full h-full cursor-pointer">
            <div
              className={`${
                currentBanner === bannerItems[0].name ? 'bg-white' : ''
              } w-1/3 h-full transition-all ease-in-out delay-50`}
              onClick={() => handleBanner(0)}></div>
            <div
              className={`${
                currentBanner === bannerItems[1].name ? 'bg-white' : ''
              } w-1/3 h-full transition-all ease-in-out delay-50`}
              onClick={() => handleBanner(1)}></div>
            <div
              className={`${
                currentBanner === bannerItems[2].name ? 'bg-white' : ''
              } w-1/3 h-full transition-all ease-in-out delay-50`}
              onClick={() => handleBanner(2)}></div>
          </div>
        </div>
      )}
    </div>
  );
}
