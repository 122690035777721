import {
  requestGetSurveyAnswerList,
  requestGetSurveyList,
  requestGetSurveyTemplateList,
  requestPostSurveyAnswerRegister,
} from '@/api/surveyApi';
import {
  SurveyAnswerInfoProps,
  SurveyListProps,
  SurveyTemplateRequestProps,
} from '@/types/survey.type';

export async function searchSurveyList({
  title,
  uid,
  surveySvrSurveyId,
  startsAt,
  endsAt,
  parentType,
  parentId,
  displayCondition,
  surveyStartsAt,
  surveyEndsAt,
  surveyProcessStatus,
  status,
}: SurveyListProps) {
  try {
    const response = await requestGetSurveyList({
      title,
      uid,
      surveySvrSurveyId,
      startsAt,
      endsAt,
      parentType,
      parentId,
      displayCondition,
      surveyStartsAt,
      surveyEndsAt,
      surveyProcessStatus,
      status,
    });
    return response.data.data;
  } catch (e: any) {
    throw e.error.message;
  }
}

export async function searchSurveyTemplateList({
  title,
  uid,
  surveySvrTemplateId,
  page,
  pageSize,
  startsAt,
  endsAt,
}: SurveyTemplateRequestProps) {
  try {
    const response = await requestGetSurveyTemplateList({
      title,
      uid,
      surveySvrTemplateId,
      page,
      pageSize,
      startsAt,
      endsAt,
    });
    return response.data.data;
  } catch (e: any) {
    throw e.error.message;
  }
}

export interface RegisterSurveyAnswerResponseProps {
  result: boolean;
}

export async function registerSurveyAnswer({
  answers,
  uid,
}: {
  answers: SurveyAnswerInfoProps[];
  uid: string;
}) {
  try {
    return requestPostSurveyAnswerRegister(answers, uid);
  } catch (e: any) {
    throw e.error.message;
  }
}

//답변 리스트 조회
export async function searchSurveyAnswerList({
  surveyId,
  userId,
}: {
  surveyId?: string;
  userId?: string;
}) {
  try {
    const response = await requestGetSurveyAnswerList(surveyId, userId);
    return response.data.data;
  } catch (e: any) {
    throw e.error.message;
  }
}
