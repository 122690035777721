import React, {ChangeEvent, SetStateAction, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as EyeInvisible} from '@/assets/newImg/eye-invisible-bold-s.svg';
import {ReactComponent as EyeVisible} from '@/assets/newImg/eye-visible-bold-s.svg';
import {NewLayout, FormLayout, Skeleton} from '@/components/common';
import {NewPrimaryButton} from '@/components/common/NewButton';
import {Input} from '@/components/common/NewInput';
import {Modal} from '@/components/common/NewModal';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {changeUserInfoService} from '@/services/userService';
import {encodeHyphenPhoneNumber} from '@/utils/format';
import {ValidatePasswordSchema} from '@/utils/validator';

type FormCaptionType = {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
};

const INIT_USER_INFO: FormCaptionType = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

function PasswordInput({
  form,
  formCaption,
  handleFormChange,
  visiblePassword,
  setVisiblePassword,
}: {
  form: typeof INIT_USER_INFO;
  formCaption: typeof INIT_USER_INFO;
  handleFormChange: (value: any) => void;
  visiblePassword: boolean;
  setVisiblePassword: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <Input
        id="currentPassword"
        type={visiblePassword ? 'text' : 'password'}
        placeholder="비밀번호를 입력하세요."
        label="기존 비밀번호"
        value={form.currentPassword}
        onChange={handleFormChange}
        rightIcon={
          visiblePassword ? (
            <EyeVisible
              className="ml-2"
              width={22}
              height={15}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          ) : (
            <EyeInvisible
              className="ml-2"
              width={22}
              height={17}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          )
        }
      />
      {formCaption.currentPassword ? (
        <p className="text-rose-500 w-full text-sm mt-[-15px] mb-[9px]">
          {formCaption.currentPassword}
        </p>
      ) : (
        <></>
      )}
      <Input
        id="newPassword"
        type={visiblePassword ? 'text' : 'password'}
        placeholder="비밀번호를 입력하세요."
        label="새로운 비밀번호"
        value={form.newPassword}
        onChange={handleFormChange}
        rightIcon={
          visiblePassword ? (
            <EyeVisible
              className="ml-2"
              width={22}
              height={15}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          ) : (
            <EyeInvisible
              className="ml-2"
              width={22}
              height={17}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          )
        }
      />
      {formCaption.newPassword ? (
        <p className="text-rose-500 w-full text-sm mt-[-15px] mb-[9px]">
          {formCaption.newPassword}
        </p>
      ) : (
        <></>
      )}
      <Input
        id="confirmPassword"
        type={visiblePassword ? 'text' : 'password'}
        placeholder="비밀번호를 입력하세요."
        label="새로운 비밀번호 확인"
        value={form.confirmPassword}
        onChange={handleFormChange}
        rightIcon={
          visiblePassword ? (
            <EyeVisible
              className="ml-2"
              width={22}
              height={15}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          ) : (
            <EyeInvisible
              className="ml-2"
              width={22}
              height={17}
              fill="#404040"
              onClick={() => {
                setVisiblePassword(prev => !prev);
              }}
            />
          )
        }
      />
      {formCaption.confirmPassword ? (
        <p className="text-rose-500 w-full text-sm mt-[-15px] mb-[9px]">
          {formCaption.confirmPassword}
        </p>
      ) : (
        <></>
      )}
    </>
  );
}

export function UserUpdate() {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {user} = useUser();
  const [form, setForm] = useState(INIT_USER_INFO);
  const [formCaption, setFormCaption] =
    useState<typeof INIT_USER_INFO>(INIT_USER_INFO);
  const [visiblePassword, setVisiblePassword] = useState(false);

  const updateForm = (value: string, id: string) => {
    const newValue = {
      ...form,
      [id]: value,
    };
    setForm(newValue);
  };
  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, id} = e.target;
    updateForm(value, id);

    try {
      ValidatePasswordSchema.validateSync(value);

      // 새로운 비밀번호와 기존 비밀번호가 같은지 확인
      if (id === 'newPassword' && value === form.currentPassword) {
        throw new Error('현재 비밀번호와 동일합니다.');
      }
      // 비밀번호 확인과 새 비밀번호가 같은지 확인
      if (id === 'confirmPassword' && value !== form.newPassword) {
        throw new Error('새 비밀번호와 일치하지 않습니다.');
      }

      if (id === 'currentPassword' && value === form.newPassword) {
        setFormCaption({
          ...formCaption,
          newPassword: '현재 비밀번호와 동일합니다.',
        });
        return;
      }
      if (id === 'newPassword' && form.confirmPassword) {
        if (form.confirmPassword !== value) {
          setFormCaption({
            ...formCaption,
            newPassword: '',
            confirmPassword: '새 비밀번호와 일치하지 않습니다.',
          });
          return;
        }
        setFormCaption({
          ...formCaption,
          newPassword: '',
          confirmPassword: '',
        });
        return;
      }
      setFormCaption(INIT_USER_INFO);
    } catch (e: any) {
      setFormCaption({...formCaption, [id]: e.message});
    }
  };

  const checkEmptyFormCaptionFields = useMemo(() => {
    for (const key in formCaption) {
      if (formCaption[key as keyof FormCaptionType]) return false;
    }
    return true;
  }, [formCaption]);

  const handleSubmit = async () => {
    if (!checkEmptyFormCaptionFields) return;

    if (!user?.id) {
      return;
    }
    if (!form.currentPassword || !form.newPassword) {
      return;
    }

    try {
      const res = await changeUserInfoService(user.id, {
        password: form.currentPassword,
        newPassword: form.newPassword,
      });

      if (res) {
        openModal({
          type: 'check',
          message: '정보 수정이 완료되었습니다.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                closeModal();
                navigate('/');
              },
            },
          ],
        });
      }
    } catch (e: any) {
      const error = e.data.error;
      if (error.code === '1202') {
        openModal({
          type: 'error',
          message: '기존 비밀번호가 일치하지 않습니다.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                closeModal();
                setFormCaption({
                  ...formCaption,
                  currentPassword: '기존 비밀번호가 일치하지 않습니다.',
                });
              },
            },
          ],
        });

        return;
      }
      if (error.code === '1201') {
        setFormCaption({
          ...formCaption,
          newPassword:
            '현재 비밀번호와 일치합니다. 새로운 비밀번호를 입력해주세요.',
        });
        return;
      }
    }
  };

  return (
    <>
      <NewLayout bgWhite>
        <FormLayout bgWhite>
          <p className="font-bold text-[30px] mb-8">비밀번호 변경</p>
          {user?.id ? (
            <>
              <form className="w-full flex flex-col items-center gap-[15px]">
                <Input
                  id="name"
                  type="text"
                  label="이름"
                  value={user.name}
                  disabled
                  addClass="bg-readOnly"
                />
                <Input
                  id="email"
                  type="email"
                  label="이메일"
                  value={user.email}
                  disabled
                  addClass="bg-readOnly"
                />
                <Input
                  id="phone"
                  type="text"
                  label="휴대폰 번호"
                  value={encodeHyphenPhoneNumber(user.phone)}
                  disabled
                  addClass="bg-readOnly"
                />
                <PasswordInput
                  form={form}
                  formCaption={formCaption}
                  handleFormChange={handleFormChange}
                  visiblePassword={visiblePassword}
                  setVisiblePassword={setVisiblePassword}
                />
                <NewPrimaryButton
                  type="button"
                  button={
                    checkEmptyFormCaptionFields &&
                    form.currentPassword &&
                    form.newPassword &&
                    form.confirmPassword
                      ? 'primary'
                      : 'disabled'
                  }
                  addClass=" w-full h-[61px] text-white text-xl font-bold my-5"
                  text="비밀번호 변경하기"
                  onClick={handleSubmit}
                />
              </form>
              <div
                className="flex items-center justify-center gap-1 cursor-pointer"
                onClick={() => {
                  navigate('/user/withdraw');
                }}>
                <p className="text-[#b4b4b4] text-[15px] font-medium">
                  탈퇴하고 싶으신가요?
                </p>
                <p className="text-[#337ccf] text-[15px] font-black">
                  회원탈퇴
                </p>
              </div>
            </>
          ) : (
            <Skeleton />
          )}
        </FormLayout>
      </NewLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
    </>
  );
}
