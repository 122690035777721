import {useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

import {
  BoardLayout,
  NewPrimaryButton,
  NewInputSearch,
  PaginationInfo,
} from '@/components/common';
import {useChapterDetail} from '@/hooks/useChapter';
import {useCourseDetail} from '@/hooks/useCourse';
import {useHomework, useHomeworkGetSubmitOne} from '@/hooks/useHomework';
import useUser from '@/hooks/useUser';
import {
  CourseInfoDetailCard,
  HomeworkDetail,
  HomeworkListTable,
} from '@/pages/new-course/components';

export function NewHomework() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chapterId = queryParams.get('chapter') || '';
  const searchQuery = queryParams.get('search') || '';
  const {courseId = ''} = useParams<{courseId?: string}>();

  const {user} = useUser();
  const userId = user?.id;

  const [selectedHomework, setSelectedHomework] = useState<string>('');

  const {data: courseInfo} = useCourseDetail(courseId);
  const {data: chapterInfo} = useChapterDetail(chapterId);
  const {chapterHomeworks, isLoading, paginationInfo, onChangePage} =
    useHomework({chapterId, userId, searchQuery});
  const {data: homeworkDetail, refetch: refetchHomeworkDetail} =
    useHomeworkGetSubmitOne({
      chapterId: chapterId,
      homeworkId: selectedHomework,
      userId: userId,
    });

  // 과제 관리 모달로 이동
  const onClickHomework = (id: string) => {
    setSelectedHomework(id);
  };
  const searchHomeworkList = (query: string) => {
    if (!query) {
      navigate(`/new-homework/${courseId}?chapter=${chapterId}`);
      return;
    }
    navigate(`/new-homework/${courseId}?chapter=${chapterId}&search=${query}`);
  };

  return (
    <BoardLayout
      boardName="교육수강"
      boardType="course"
      boardDetailName="세부강의"
      boardDetailUrl={`/course/${courseId}`}
      bgWhite
      isLecture>
      <div className=" pt-[83px]">
        {courseInfo && (
          <CourseInfoDetailCard courseInfo={courseInfo} isCourseDetail={true} />
        )}
        <h4 className="mt-[64px] mb-[35px] text-[40px] font-bold leading-[35px]">
          과제
        </h4>
        <div className=" w-full bg-white border course-shadow pt-[31px]">
          {!selectedHomework && (
            <div className="mb-12">
              {/* chapter 이름 */}
              <div className="w-full flex flex-col pl-[42px] pr-[52px]">
                <h5 className="pl-[13px] font-bold text-[25px] text-[#4d4d4d]">
                  {chapterInfo?.name}
                </h5>
                <div className="my-[-17px]">
                  <NewInputSearch
                    placeholder="검색어를 입력해주세요"
                    onSearch={searchHomeworkList}
                  />
                </div>
                <PaginationInfo paginationInfo={paginationInfo} />
              </div>
              <HomeworkListTable
                paginationInfo={paginationInfo}
                chapterHomeworks={chapterHomeworks}
                onClickHomework={onClickHomework}
                onChangePage={onChangePage}
              />
            </div>
          )}
          {selectedHomework && homeworkDetail && (
            <HomeworkDetail
              homeworkDetail={homeworkDetail}
              homeworkAttachments={
                chapterHomeworks.find(item => {
                  return item.id === selectedHomework;
                })?.attachments || []
              }
            />
          )}
        </div>

        {/* 목록으로 */}
        <div className="text-center pt-[42px] pb-[151px]">
          {selectedHomework ? (
            <NewPrimaryButton
              addClass="w-[162px]"
              text={'목록으로'}
              button={'primary'}
              onClick={() => setSelectedHomework('')}
            />
          ) : (
            <Link to={`/course/${courseId}`}>
              <NewPrimaryButton
                addClass="w-[162px]"
                text={'강의 목록으로'}
                button={'primary'}
              />
            </Link>
          )}
        </div>
      </div>
    </BoardLayout>
  );
}
