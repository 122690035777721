import {atom} from "recoil";

import {Site} from "@/types/site.type";

// const INIT_NAV_ITEMS = [
//     {name: '공지사항', path: '/new-notice'},
//     {name: '교육신청', path: '/new-register'},
//     {name: '교육수강', path: '/new-course'},
//     {name: '수료증', path: '/certificate'},
//     {name: 'FAQ / 교육 문의', path: '/new-faq'},
//     {name: '고객 문의', path: '/new-ask'},
// ];

const INIT_NAV_ITEMS = [
    {name: '공지사항', path: '/notice'},
    {name: '교육신청', path: '/register'},
    {name: '교육수강', path: '/course'},
    {name: '수료증', path: '/certificate'},
    {name: 'FAQ/교육 문의', path: '/question'},
    {name: '고객 문의', path: '/ask'},
];
export const siteInfoState = atom<Site | null>({
    key: 'siteInfoState',
    default: null,
});

export const siteNavState = atom<{name: string; path: string}[]>({
  key: 'siteNavState',
  default: INIT_NAV_ITEMS,
});


