import React, {ReactNode, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  DisabledSquareButton,
  NewLayout,
  NewTableData,
  NewTableHeader,
  NewTableRow,
  Pagination,
  PrimarySquareButton,
  Skeleton,
  TableRowEmpty,
} from '@/components/common';
import {ViewApplicationFormModal} from '@/components/ViewApplicationFormModal';
import {useMyCourseList} from '@/hooks/useCourse';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {downloadCertificateService} from '@/services/certificateService';
import {userCourseListResponse} from '@/types/course.type';
import {
  encodeHyphenPhoneNumber,
  formatDateToKorean,
  genDurationDate,
  genTableReverseIndex,
  truncateText,
} from '@/utils/format';

function UserInfoWrapper({label, value}: {label: string; value: string}) {
  return (
    <div className="flex items-center gap-2.5">
      <p className="text-primary text-xl font-bold">{label}</p>
      <p className="text-[#404040] text-[25px] font-medium">{value}</p>
    </div>
  );
}

function DownloadButton({
  //수료증 다운로드 버튼(수료증 pdf 형식으로 파일 다운로드)
  certificateFileUrl, // 다운로드 할 수 있는 파일 url
  onClick,
  addClass,
}: {
  certificateFileUrl?: string;
  onClick: () => void;
  addClass?: string;
}) {
  return certificateFileUrl ? (
    <div className="flex justify-center">
      <div
        className={`cursor-default w-max whitespace-nowrap py-[5px] px-[10px] rounded h-max-[34px] bg-white text-[#E35F00] border border-[#E35F00] hover:cursor-pointer ${addClass}`}
        onClick={() => {
          if (certificateFileUrl) {
            onClick();
          }
        }}>
        <p className="text-sm font-bold">{'다운로드'}</p>
      </div>
    </div>
  ) : (
    <DisabledSquareButton text={'미발급'} />
  );
}

function PrintButton({
  certificateFileUrl,
  onClick,
}: {
  certificateFileUrl?: string;
  onClick: () => void;
}) {
  return certificateFileUrl ? (
    <div className="flex justify-center">
      <div
        className={`cursor-default w-max whitespace-nowrap py-[5px] px-[10px] rounded h-max-[34px] bg-[#E35F00] text-white hover:cursor-pointer`}
        onClick={() => {
          if (certificateFileUrl) {
            onClick();
          }
        }}>
        <p className="text-sm font-bold">{'출력'}</p>
      </div>
    </div>
  ) : (
    <DisabledSquareButton text={'미발급'} />
  );
}

export function MyPage() {
  const navigate = useNavigate();
  const {user} = useUser();
  const modal = useModal();

  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');

  const {
    data: userCourseListInClass,
    isLoading: userCourseListInClassLoading,
    paginationInfo: courseListInClassPaginationInfo,
    onChangePage: courseListInClassOnChangePage,
  } = useMyCourseList({
    searchQuery,
    isComplete: false, //수강중인 교육과정
  });
  const {
    data: userCourseListInDone,
    isLoading: userCourseListInDoneLoading,
    paginationInfo: courseListInDonePaginationInfo,
    onChangePage: courseListInDoneOnChangePage,
  } = useMyCourseList({
    searchQuery,
    isComplete: true, //수강완료된 교육과정
  });

  const [selectedCourse, setSelectedCourse] =
    useState<userCourseListResponse | null>(null);

  const viewApplicationForm = (item: userCourseListResponse) => () => {
    setSelectedCourse(item);
    modal.openModal(item);
  };

  const handleOnPrintCertificate = async (certificateFileUrl: string) => {
    window.open(certificateFileUrl, '_blank');
  };

  const handleOnDownloadCertificate = async (certificateUrl: string) => {
    fetch(certificateUrl).then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    });
  };

  return (
    <NewLayout bgWhite>
      <div className="flex flex-col pt-[50px] pb-[100px] gap-[23px]">
        <div className="flex flex-col desktop:flex-row items-start desktop:items-center justify-between px-[50px] py-[40px] bg-white border-gray-200 rounded-[30px] course-shadow">
          {user?.id ? (
            <>
              <div className="whitespace-nowrap desktop:mb-0 mb-6">
                <p className="text-[#666666] text-lg font-medium mb-6">
                  내 정보
                </p>
                <div className="flex flex-col desktop:flex-row desktop:gap-10 gap-3">
                  <UserInfoWrapper label="이름" value={user.name} />
                  <UserInfoWrapper label="이메일" value={user.email} />
                  <UserInfoWrapper
                    label="전화번호"
                    value={encodeHyphenPhoneNumber(user.phone)}
                  />
                </div>
              </div>
              <div className="flex items-center self-center gap-[25px] whitespace-nowrap">
                <div
                  className="w-28 h-[49px] px-[19px] py-3.5 bg-primary rounded-[30px] justify-center items-center gap-2.5 inline-flex cursor-pointer"
                  onClick={() => {
                    navigate('/user/update');
                  }}>
                  <p className="text-center text-white text-xl font-bold">
                    정보수정
                  </p>
                </div>
              </div>
            </>
          ) : (
            <Skeleton /> // TODO: 내 정보 스켈레톤 필요
          )}
        </div>
        <div className="px-[50px] py-[40px] flex flex-col bg-white border-gray-200 rounded-[30px] course-shadow">
          <div className="items-center justify-between text-lg font-medium mb-6">
            <p className="text-[#666666]">수강중인 교육과정</p>
            <table className="w-full mt-[20px] ">
              <thead className="bg-[#f2f2f2] ">
                <NewTableRow header>
                  <NewTableHeader addClass="w-[65px]">순서</NewTableHeader>
                  <NewTableHeader>교육과정명</NewTableHeader>
                  <NewTableHeader>교육기간</NewTableHeader>
                  <NewTableHeader>수강시작일</NewTableHeader>
                  <NewTableHeader>신청폼</NewTableHeader>
                </NewTableRow>
              </thead>
              <tbody className=" border-b-[1.4px] border-[#bfbfbf]">
                {!userCourseListInClassLoading &&
                userCourseListInClass?.content &&
                userCourseListInClass.content.length > 0 ? (
                  userCourseListInClass.content.map(
                    (item: userCourseListResponse, index: number) => {
                      const rowIndex =
                        (courseListInClassPaginationInfo.currentPage - 1) *
                          courseListInClassPaginationInfo.size +
                        index +
                        1;
                      return (
                        <NewTableRow key={index}>
                          <NewTableData addClass="!text-base">
                            <p>{rowIndex}</p>
                          </NewTableData>
                          <NewTableData addClass="text-center !text-base">
                            {truncateText(item.name)}
                          </NewTableData>
                          <NewTableData addClass="whitespace-nowrap !text-base">
                            {genDurationDate(item.startsAt, item.endsAt)}
                          </NewTableData>
                          <NewTableData addClass={'!text-base'}>
                            {formatDateToKorean(item.recruitStartsAt)}
                          </NewTableData>
                          <NewTableData
                            addClass={'flex justify-center !text-base'}>
                            {item.surveySvrAnswerId ? (
                              <PrimarySquareButton
                                text={'신청폼 보기'}
                                onClick={viewApplicationForm(item)}
                              />
                            ) : (
                              <DisabledSquareButton text={'신청폼 없음'} />
                            )}
                          </NewTableData>
                        </NewTableRow>
                      );
                    },
                  )
                ) : (
                  <TableRowEmpty text="수강중인 교육과정이 없습니다." />
                )}

                {userCourseListInClassLoading && (
                  <tr className="w-full">
                    <td colSpan={99} className="text-center h-40">
                      <Skeleton />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="mt-[30px] mb-[10px]">
              <Pagination
                totalCount={courseListInClassPaginationInfo.total || 1}
                pageSize={courseListInClassPaginationInfo.size}
                currentPage={courseListInClassPaginationInfo.currentPage}
                totalPage={courseListInClassPaginationInfo.totalPages || 1}
                onClick={courseListInClassOnChangePage}
              />
            </div>
          </div>
        </div>

        <div className="px-[50px] py-[40px] flex flex-col bg-white border-gray-200 rounded-[30px] course-shadow">
          <div className=" items-center justify-between text-lg font-medium mb-6">
            <p className="text-[#666666]">수강완료된 교육과정</p>
            <table className="w-full mt-[20px] ">
              <thead className="bg-[#f2f2f2] ">
                <NewTableRow header>
                  <NewTableHeader addClass="w-[65px]">순서</NewTableHeader>
                  <NewTableHeader>교육과정명</NewTableHeader>
                  <NewTableHeader>교육기간</NewTableHeader>
                  <NewTableHeader>수강시작일</NewTableHeader>
                  <NewTableHeader>상태</NewTableHeader>
                  <NewTableHeader>수료증</NewTableHeader>
                </NewTableRow>
              </thead>
              <tbody className=" border-b-[1.4px] border-[#bfbfbf]">
                {!userCourseListInDoneLoading &&
                userCourseListInDone?.content &&
                userCourseListInDone.content.length > 0 ? (
                  userCourseListInDone.content.map(
                    (item: userCourseListResponse, index: number) => {
                      const rowIndex =
                        (courseListInClassPaginationInfo.currentPage - 1) *
                          courseListInClassPaginationInfo.size +
                        index +
                        1;
                      return (
                        <NewTableRow key={index}>
                          <NewTableData addClass="!text-base">
                            <p>{rowIndex}</p>
                          </NewTableData>
                          <NewTableData addClass="text-center !text-base">
                            {truncateText(item.name)}
                          </NewTableData>
                          <NewTableData addClass="whitespace-nowrap !text-base">
                            {genDurationDate(item.startsAt, item.endsAt)}
                          </NewTableData>
                          <NewTableData addClass={'!text-base'}>
                            {formatDateToKorean(item.recruitStartsAt)}
                          </NewTableData>
                          <NewTableData addClass={'!text-base'}>
                            {item.certificateFileUrl ? '수료' : '미수료'}
                          </NewTableData>
                          <NewTableData addClass={'flex justify-center'}>
                            <DownloadButton
                              certificateFileUrl={item?.certificateFileUrl}
                              onClick={() => {
                                handleOnDownloadCertificate(
                                  item.certificateFileUrl,
                                );
                              }}
                              addClass={'mr-1'}
                            />
                            <PrintButton
                              certificateFileUrl={item?.certificateFileUrl}
                              onClick={async () => {
                                await handleOnPrintCertificate(
                                  item?.certificateFileUrl,
                                );
                              }}
                            />
                          </NewTableData>
                        </NewTableRow>
                      );
                    },
                  )
                ) : (
                  <TableRowEmpty text="수강완료된 교육과정이 없습니다." />
                )}
              </tbody>
            </table>

            <div className="mt-[30px] mb-[10px]">
              <Pagination
                totalCount={courseListInDonePaginationInfo.total || 1}
                pageSize={courseListInDonePaginationInfo.size}
                currentPage={courseListInDonePaginationInfo.currentPage}
                totalPage={courseListInDonePaginationInfo.totalPages || 1}
                onClick={courseListInDoneOnChangePage}
              />
            </div>
          </div>
        </div>
      </div>
      {user && selectedCourse && (
        <ViewApplicationFormModal
          modal={modal}
          courseInfo={selectedCourse}
          userName={user.name}
        />
      )}
    </NewLayout>
  );
}
