import React, {useState, ChangeEvent} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as EyeInvisible} from '@/assets/newImg/eye-invisible-bold-s.svg';
import {ReactComponent as EyeVisible} from '@/assets/newImg/eye-visible-bold-s.svg';
import {FormLayout, NewLayout} from '@/components/common';
import {NewPrimaryButton as PrimaryButton} from '@/components/common/NewButton';
import {Input} from '@/components/common/NewInput';
import {Modal} from '@/components/common/NewModal';
import useModal from '@/hooks/useModal';
import {
  requestEmailCodeService,
  validateEmailCodeService,
  resetPasswordService,
} from '@/services/userService';
import siteState from '@/stores/site';
import {ValidateEmailSchema, ValidatePasswordSchema} from '@/utils/validator';

const INIT_FIND_PASSWORD_STATE = {
  email: '',
  code: '',
  password: '',
  rePassword: '',
};

export function NewUserFindPassword() {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const siteData = useRecoilValue(siteState.siteInfoState);
  const [form, setForm] = useState(INIT_FIND_PASSWORD_STATE);
  const [formCaption, setFormCaption] = useState(INIT_FIND_PASSWORD_STATE);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isSentCode, setIsSentCode] = useState(false);
  const [showSendButton, setShowSendButton] = useState(false);
  const [verifiedId, setVerifiedId] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);

  if (!siteData) return null;

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    const newForm = {...form, [id]: value};
    setForm(newForm);

    try {
      switch (id) {
        case 'email':
          if (value.length === 0) {
            setShowSendButton(false);
          } else {
            setShowSendButton(true);
            ValidateEmailSchema.validateSync(value);
          }
          break;
        case 'password':
          ValidatePasswordSchema.validateSync(value);
          break;
      }
      setFormCaption({
        ...formCaption,
        [id]: '',
      });
    } catch (e: any) {
      setFormCaption({
        ...formCaption,
        [id]: e.message,
      });
    }
  }

  async function requestEmailCode() {
    const email = form.email;
    try {
      ValidateEmailSchema.validateSync(email);
      setFormCaption(prev => {
        return {
          ...prev,
          email: '',
        };
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          email: e.message,
        };
      });
      return;
    }

    try {
      await requestEmailCodeService(siteData!.id, 'RESET_PWD', email);
      setIsSentCode(true);
      openModal({
        type: 'check',
        message: '인증 메일이 발송되었습니다.',
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          email: e.data.error.message,
        };
      });
    }
  }

  async function validateEmailCode() {
    const {email, code} = form;
    if (code.length === 0) {
      setFormCaption(prev => {
        return {
          ...prev,
          code: '이메일 인증 코드를 입력해주세요.',
        };
      });
      return;
    }

    try {
      const authId = await validateEmailCodeService(
        siteData!.id,
        'RESET_PWD',
        email,
        code,
      );

      setVerifiedId(authId);
      setIsEmailChecked(true);
      openModal({
        type: 'check',
        message: '인증되었습니다.\n새로운 비밀번호를 입력해주세요.',
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          code: e.data.error.message,
        };
      });
    }
  }

  async function handleFindEmail() {
    const {email, password, rePassword} = form;

    if (password !== rePassword) {
      setFormCaption(prev => {
        return {
          ...prev,
          rePassword: '동일한 비밀번호를 입력해주세요.',
        };
      });
      return;
    }

    try {
      await resetPasswordService(siteData!.id, email, password, verifiedId);
      openModal({
        type: 'check',
        message:
          '비밀번호가 변경되었습니다.\n새로운 비밀번호로 로그인해주세요.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              navigate('/new-sign-in');
            },
          },
        ],
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          password: e.data.error.message,
        };
      });
    }
  }

  return (
    <NewLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <FormLayout>
        <h3 className="font-bold text-[30px] mb-8">비밀번호 찾기</h3>
        <form className="w-full flex flex-col items-center">
          {/* 이메일 인증 */}
          <>
            <Input
              id="email"
              type="email"
              placeholder="이메일을 입력하세요."
              label="이메일"
              value={form.email}
              onChange={handleForm}
              onKeyDown={async (
                event: React.KeyboardEvent<HTMLInputElement>,
              ) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  !(isSentCode && isEmailChecked) && (await requestEmailCode());
                }
              }}
              addClass={
                isEmailChecked ? 'bg-readOnly cursor-default' : 'bg-white'
              }
              rightButton={
                !isEmailChecked &&
                (showSendButton ? (
                  !isSentCode ? (
                    <PrimaryButton
                      type="button"
                      button="primary"
                      onClick={requestEmailCode} // 폰명의 체크 api 호출, 번호 유효하면 isPhoneChecked true
                      text="인증번호 전송"
                      addClass={`w-[162px]`}
                    />
                  ) : (
                    <PrimaryButton
                      type="button"
                      button="primary"
                      onClick={requestEmailCode}
                      text="인증번호 재전송"
                      addClass={`w-[162px] ${
                        isEmailChecked
                          ? 'hidden'
                          : 'hover:bg-secondary hover:text-white'
                      }`}
                    />
                  )
                ) : (
                  <PrimaryButton
                    type="button"
                    button="disabled"
                    onClick={requestEmailCode}
                    text="인증번호 전송"
                    addClass={`w-[162px]`}
                  />
                ))
              }
            />
            {formCaption.email ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {formCaption.email}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            <>
              <Input
                id="code"
                type="text"
                placeholder="인증번호를 입력하세요."
                label="인증번호"
                value={form.code}
                onChange={handleForm}
                onKeyDown={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') await validateEmailCode();
                }}
                addClass={
                  isEmailChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                }
                rightButton={
                  <>
                    {!isEmailChecked ? (
                      !isSentCode ? (
                        <PrimaryButton
                          type="button"
                          button="disabled"
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      ) : (
                        <PrimaryButton
                          type="button"
                          button="primary"
                          onClick={validateEmailCode}
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      )
                    ) : (
                      <PrimaryButton
                        type="button"
                        button="success"
                        onClick={validateEmailCode}
                        text="인증 완료"
                        addClass={`w-[162px]`}
                      />
                    )}
                  </>
                }
              />
              {/*{form.code && (*/}
              {/*  <p className="text-red-500 w-full text-sm mt-[10px]"></p>*/}
              {/*)}*/}
              {formCaption.code ? (
                <p className="text-rose-500 w-full text-sm mb-5">
                  {formCaption.code}
                </p>
              ) : (
                <div className="mb-6" />
              )}
            </>
          </>
          {/* 새로운 비밀번호 */}
          {verifiedId && (
            <div className="w-full flex flex-col">
              <Input
                id="password"
                type={visiblePassword ? 'text' : 'password'}
                label="새로운 비밀번호"
                placeholder="비밀번호를 입력하세요."
                value={form.password}
                onChange={handleForm}
                rightIcon={
                  visiblePassword ? (
                    <EyeVisible
                      className="ml-2"
                      width={22}
                      height={15}
                      fill="#404040"
                      onClick={() => {
                        setVisiblePassword(prev => !prev);
                      }}
                    />
                  ) : (
                    <EyeInvisible
                      className="ml-2"
                      width={22}
                      height={17}
                      fill="#404040"
                      onClick={() => {
                        setVisiblePassword(prev => !prev);
                      }}
                    />
                  )
                }
              />
              {formCaption.password ? (
                <p className="text-rose-500 w-full text-sm mb-5">
                  {formCaption.password}
                </p>
              ) : (
                <div className="mb-6" />
              )}
              {/* 비밀번호 확인 */}
              <Input
                id="rePassword"
                type={visiblePassword ? 'text' : 'password'}
                label="새로운 비밀번호 확인"
                placeholder="비밀번호를 입력하세요."
                value={form.rePassword}
                onChange={handleForm}
              />
              {formCaption.rePassword ? (
                <p className="text-rose-500 w-full text-sm mb-10">
                  {formCaption.rePassword}
                </p>
              ) : (
                <div className="mb-[30px]" />
              )}
              <div className={'flex justify-center'}>
                <div
                  onClick={handleFindEmail}
                  className="h-[60px] font-bold cursor-pointer bg-primary text-white hover:bg-[#2963A6] hover:text-white text-center flex justify-center items-center whitespace-nowrap px-auto py-auto w-full text-xl border rounded-[30px] transition-all ease-in-out delay-50 ">
                  확인
                </div>
              </div>
            </div>
          )}
        </form>
        {/* 로그인 관련 페이지 이동 버튼 */}
        <div className="mt-[25px] mb-1 w-full px-[75px] flex justify-between items-center">
          <Link
            to="/new-sign-up"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            회원가입
          </Link>
          <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
          <Link
            to="/new-sign-in"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            로그인
          </Link>
          <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
          <Link
            to="/new-user/find/email"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            이메일 찾기
          </Link>
        </div>
      </FormLayout>
    </NewLayout>
  );
}
