import {useCallback, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import NoRegisteredThumbnail from '@/assets/newImg/no-registered-thumbnail.jpg';
import {NewPrimaryButton} from '@/components/common';
import {RegisterCourse} from '@/types/course.type';
import {getToday, replaceDateFormat} from '@/utils/format';

export function CourseInfoCard({
  courseInfo,
}: {
  courseInfo: RegisterCourse;
  isMyCourse?: boolean;
}) {
  const navigate = useNavigate();
  const today = useMemo(() => getToday(), []);

  const isRecruitEnd = new Date() > new Date(courseInfo.recruitEndsAt);
  const isRecruitStart = new Date() < new Date(courseInfo.recruitStartsAt);
  // const courseStatus =
  //   courseInfo.recruitStatus === 'Y' &&
  //   courseInfo.applicantsCount > 0 &&
  //   getToday() >= courseInfo.recruitStartsAt
  //     ? '모집중'
  //     : courseInfo.applicantsCount > 0 &&
  //       getToday() < courseInfo.recruitStartsAt
  //     ? '모집예정'
  //     : '모집완료';

  const getStatusUI = (text: string, bgColor: string) => (
    <div
      key={text}
      className={`cursor-default w-max whitespace-nowrap py-[7px] px-[19px] rounded-[30px] h-max-[40px] ${
        bgColor || 'bg-[#337CCF]'
      }`}>
      <p className="text-white text-lg font-bold">{text}</p>
    </div>
  );

  const currentCourseStatus = useCallback(() => {
    if (!courseInfo) return <p className="text-white text-xl font-bold"></p>;
    const statuses = [];

    switch (courseInfo.applicationStatus) {
      case 'ENROLLED':
      case 'WAITING':
      default:
        if (courseInfo.recruitStatus === 'N' && isRecruitEnd)
          statuses.push({bgColor: 'bg-[#bfbfbf]', text: '모집완료'});
        else if (courseInfo.recruitStatus === 'Y')
          statuses.push({bgColor: 'bg-[#337CCF]', text: '모집중'});
        else {
          statuses.push({bgColor: 'bg-[#ADCBEC]', text: '모집예정'});
        }
        if (today >= courseInfo.startsAt && today <= courseInfo.endsAt)
          statuses.push({bgColor: 'bg-[#FF5353]', text: '교육 진행 중'});

        return statuses.map(status => getStatusUI(status.text, status.bgColor));
    }
  }, [courseInfo]);

  return (
    <div className="max-w-[1180px] w-full relative">
      <div className="w-full h-[268px] flex border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden">
        <img
          src={courseInfo.thumbnailUrl || NoRegisteredThumbnail}
          alt="교육과정 썸네일"
          className="min-w-[432px] h-full object-cover"
        />
        <div className="relative w-full flex flex-col flex-grow px-[50px] pt-[30px]">
          {/*{!isMyCourse && (*/}
          {/*  <button*/}
          {/*    className={`cursor-default w-max whitespace-nowrap mb-[10px] h-[40px] px-[19px] border rounded-[30px] text-white text-[18px] font-bold ${*/}
          {/*      courseStatus === '모집중'*/}
          {/*        ? 'bg-primary '*/}
          {/*        : courseStatus === '모집예정'*/}
          {/*        ? 'bg-secondary '*/}
          {/*        : courseStatus === '모집완료'*/}
          {/*        ? 'bg-disabled '*/}
          {/*        : ''*/}
          {/*    }`}>*/}
          {/*    {courseStatus}*/}
          {/*  </button>*/}
          {/*)}*/}
          <div className="flex gap-2.5">{currentCourseStatus()}</div>
          <Link to={`/new-register/${courseInfo.id}`} className="flex-grow">
            <h4 className=" font-bold text-[#191D88] my-[11px] text-[25px] break-keep course-title-style">
              {courseInfo.name}
            </h4>
          </Link>
          <div className="mb-[35px]">
            <div className="flex mb-[5px] text-[18px]">
              <span className="font-bold mr-[18px] text-[#404040]">
                모집기간
              </span>
              <p className="text-[#00000080] font-medium">
                {replaceDateFormat(courseInfo.recruitStartsAt, 'YYYY.MM.DD')} ~
                {replaceDateFormat(courseInfo.recruitEndsAt, 'YYYY.MM.DD')}
              </p>
            </div>
            <div className="flex text-[18px]">
              <span className="font-bold mr-[18px] text-[#404040]">
                교육기간
              </span>
              <p className="text-[#00000080] font-medium">
                {replaceDateFormat(courseInfo.startsAt, 'YYYY.MM.DD')} ~
                {replaceDateFormat(courseInfo.endsAt, 'YYYY.MM.DD')}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-[30px] right-[50px]">
          {/* 신청됨 -> 신청 완료 */}
          {courseInfo.applicationStatus === 'ENROLLED' &&
            today >= courseInfo.startsAt &&
            today <= courseInfo.endsAt && (
              <NewPrimaryButton
                text="수강하기"
                addClass="w-[162px] bg-secondary border-transparent text-white"
                onClick={() => {
                  navigate(`/course/${courseInfo.id}`);
                  return;
                }}
              />
            )}
          {courseInfo.applicationStatus === 'ENROLLED' &&
            courseInfo.endsAt < today && (
              <NewPrimaryButton
                text="과정 종료"
                addClass="w-[162px] bg-disabled border-transparent text-white"
              />
            )}
          {/* 신청안했는데 신청못함 */}
          {courseInfo.applicationStatus === 'NULL' &&
            (isRecruitEnd ||
              isRecruitStart ||
              courseInfo.applicantsCount === 0) && (
              <NewPrimaryButton
                text="신청불가"
                addClass="w-[162px] bg-readOnly border-transparent text-disabled"
                hasNoHoverEffect={true}
              />
            )}
          {/* 신청안했는데 신청가능 */}
          {courseInfo.applicationStatus === 'NULL' &&
            !isRecruitEnd &&
            !isRecruitStart &&
            courseInfo.applicantsCount > 0 && (
              <NewPrimaryButton
                text="신청하기"
                addClass="w-[162px]"
                button="primary"
                onClick={() => {
                  navigate(`/register/${courseInfo.id}`);
                }}
              />
            )}
          {/* 승인대기중 */}
          {courseInfo.applicationStatus === 'WAITING' && (
            <NewPrimaryButton
              text="승인대기중"
              addClass="w-[162px]"
              button="success"
              hasNoHoverEffect={true}
            />
          )}
          {/* 탈락~! */}
          {courseInfo.applicationStatus === 'FAILURE' && (
            <NewPrimaryButton
              text="탈락"
              addClass="w-[162px]"
              button="disabled"
            />
          )}
        </div>
      </div>
    </div>
  );
}
