import React, {useEffect, useState} from 'react';

import {useRecoilState, useRecoilValue} from 'recoil';

import {surveyState} from '@/stores/survey';
import {
  SurveyAnswerInfoProps,
  SurveyAnswerProps,
  SurveyOptions,
  SurveyTemplateProps,
} from '@/types/survey.type';

function SurveyInputSelect({
  type,
  index,
  upperQuestion,
  questionIndex,
}: {
  type: string;
  index: number;
  upperQuestion?: SurveyTemplateProps;
  questionIndex?: number;
}) {
  const surveyRegisterState = useRecoilValue(surveyState.surveyRegisterState);
  const [surveyAnswerRegisterState, setSurveyAnswerRegisterState] =
    useRecoilState(surveyState.surveyAnswerRegisterState);
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? surveyRegisterState.template
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : surveyRegisterState.template.find(({seq}) => seq === index);

  useEffect(() => {
    if (
      !surveyAnswerRegisterState ||
      !surveyAnswerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      surveyAnswerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        surveyAnswerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setSurveyAnswerRegisterState((prev: SurveyAnswerProps) => {
        const newAnswerRegisterState = prev.answers.map(
          (item: SurveyAnswerInfoProps) =>
            currentQuestion?.seq === item.seq && !upperQuestion
              ? {
                  ...item,
                  answer: '',
                }
              : item.seq === upperQuestion?.seq
              ? {
                  ...item,
                  subQuestions: (item.subQuestions || []).map(subItem =>
                    subItem.seq === index
                      ? {
                          ...subItem,
                          answer: '',
                        }
                      : subItem,
                  ),
                }
              : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [surveyAnswerRegisterState.answers?.[questionIndex || 0]?.answer]);

  const handleOnOptionChange = (
    selectedOption: SurveyOptions,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSurveyAnswerRegisterState((prev: SurveyAnswerProps) => ({
      ...prev,
      answers: prev.answers.map((item: SurveyAnswerInfoProps) => {
        if (currentQuestion?.seq === item.seq && !upperQuestion) {
          return {
            ...item,
            answer:
              item.type === 'SINGLE_SELECT'
                ? e.target.checked
                  ? [selectedOption]
                  : []
                : e.target.checked
                ? [...(item.answer || []), selectedOption]
                : (item.answer || []).filter(
                    (option: SurveyOptions) =>
                      option.value !== selectedOption.value,
                  ),
          };
        } else if (item.seq === upperQuestion?.seq) {
          return {
            ...item,
            subQuestions: (item.subQuestions || []).map(subItem => {
              if (subItem.seq === index) {
                return {
                  ...subItem,
                  answer:
                    subItem.type === 'SINGLE_SELECT'
                      ? e.target.checked
                        ? [selectedOption]
                        : []
                      : e.target.checked
                      ? [...(subItem.answer || []), selectedOption]
                      : (subItem.answer || []).filter(
                          (option: SurveyOptions) =>
                            option.value !== selectedOption.value,
                        ),
                };
              } else {
                return subItem;
              }
            }),
          };
        } else {
          return item;
        }
      }),
    }));
  };

  return (
    <div>
      {currentQuestion?.options?.map((option, optionIndex) => (
        <div key={optionIndex} className="flex items-center mb-2">
          <div className="flex items-center p-2 my-2">
            <input
              type={type === 'SINGLE_SELECT' ? 'radio' : 'checkbox'}
              name={
                upperQuestion
                  ? upperQuestion &&
                    (upperQuestion.seq * 1000000).toString() + index
                  : index.toString()
              }
              // value={option.value?.content ?? ''}
              onChange={e => handleOnOptionChange(option, e)}
              disabled={disabled}
              checked={disabled ? false : undefined}
            />
          </div>
          {typeof option.value === 'object' &&
            (option.value?.type === 'IMAGE' ? (
              <img
                src={option.value?.content.toString()}
                alt={'업로드 이미지'}
                className="max-w-32"
              />
            ) : (
              <label
                className={`absolute left-[50px] ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}>
                {option.value?.content}
              </label>
            ))}
        </div>
      ))}
    </div>
  );
}

export default SurveyInputSelect;
