import React, {InputHTMLAttributes, useState} from 'react';

import {ReactComponent as ArrowThinIcon} from '@/assets/newImg/arrow-right-thin-s.svg';
import {ReactComponent as InputSearchIcon} from '@/assets/newImg/input-search.svg';

interface InputSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (query: string) => void;
}

export function NewInputSearch({onSearch, ...props}: InputSearchProps) {
  const dropdownItems: any[] = [{id: 'title', name: '제목', value: '제목'}];

  const [viewDropdown, setViewDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(dropdownItems[0].value);
  const [searchQuery, setSearchQuery] = useState('');

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewDropdown(!viewDropdown);
  };
  const handleItemClick = (id: string, value: string) => {
    setSelectedItem(value);
    setViewDropdown(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSearch) {
      onSearch(searchQuery);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="py-[37px] flex items-center w-full border-transparent gap-5">
      {/* 검색 카테고리 */}
      <div
        className={
          'w-[132px] relative h-[60px] flex items-center rounded-[10px] border-[#e5e5e5] border py-[17px] px-8'
        }>
        <button
          className={
            'flex items-center border border-transparent rounded-[10px] gap-[15px]'
          }
          onClick={handleButtonClick}>
          <p className={`text-lg text-[#404040] whitespace-nowrap `}>
            {selectedItem}
          </p>
          <ArrowThinIcon
            width={19}
            height={19}
            fill="#404040"
            className="rotate-90"
          />
        </button>
        {viewDropdown && (
          <div
            className={
              'overflow-hidden absolute w-full top-[59px] left-0 flex flex-col bg-white border rounded-[10px] z-[3000] '
            }>
            {dropdownItems.map(telecom => (
              <button
                className={
                  'text-start text-[18px] text-[#404040] hover:bg-gray-200 px-[30px] py-[18px] '
                }
                key={telecom.value}
                onClick={() => handleItemClick(telecom.id, telecom.value)}>
                {telecom.name}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* 검색어 입력창 */}
      <div className="w-[500px] flex items-center">
        <div
          className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
          <input
            type="search"
            id="search-input"
            value={searchQuery}
            onChange={handleInput}
            onKeyDown={handleKeyPress}
            className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
            {...props}
          />
          <label htmlFor="search-input">
            <InputSearchIcon
              width={24}
              height={24}
              fill="#337ccf"
              className="cursor-pointer mr-[2px]"
              onClick={() => onSearch && onSearch(searchQuery)}
            />
          </label>
        </div>
      </div>
    </div>
  );
}
