import React, {memo} from 'react';
import {createPortal} from 'react-dom';
import {
  AiFillAlert,
  AiOutlineCheckCircle,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import {BiErrorCircle} from 'react-icons/bi';

import {parseHtmlContentWithSanitize} from '@/utils/parse';

export interface ModalButton {
  text: string;
  style: 'normal' | 'negative' | 'positive' | 'cancel';
  onClick: () => void;
}

export interface ModalProps {
  isShowing: boolean;
  type?: 'error' | 'alert' | 'question' | 'check' | 'policy';
  title?: string;
  message?: string;
  buttons: ModalButton[];
  content?: React.ReactNode | string;
  noIcon?: boolean;
}

const ModalIcon = memo(({type}: {type?: string}) => {
  if (type === 'alert') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl">
        <AiFillAlert />
      </div>
    );
  }
  if (type === 'error') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-red-600">
        <BiErrorCircle />
      </div>
    );
  }
  if (type === 'question') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-gray-600">
        <AiOutlineQuestionCircle />
      </div>
    );
  }
  if (type === 'check') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-green-600">
        <AiOutlineCheckCircle />
      </div>
    );
  }

  return null;
});

ModalIcon.displayName = 'ModalIcon';

const ModalButton = memo(({onClick, style, text}: ModalButton) => {
  const styles = {
    normal:
      'w-full text-[20px]  h-[60px] bg-primary text-white hover:bg-[#2963A6] hover:text-white',
    positive:
      'w-full text-[20px] h-[60px]  bg-green-500 hover:bg-green-400 text-white',
    negative: 'w-full text-[20px]  h-[60px] bg-white', // Removed the hover:bg-readOnly as it's not standard
    cancel: 'w-full text-[20px]  h-[60px] bg-white hover:bg-readOnly',
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={`whitespace-nowrap px-4 py-2 font-bold h-[50px] text-[16px] border rounded-[30px] transition-all ease-in-out delay-50 ${
        styles[style] || ''
      }`}>
      {text}
    </button>
  );
});

ModalButton.displayName = 'ModalButton';

export const Modal = ({
  isShowing,
  type,
  title,
  content,
  message,
  buttons,
}: ModalProps) =>
  isShowing
    ? createPortal(
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="fixed inset-0 bg-[#000000] bg-opacity-30 transition-opacity" />

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center p-0">
              <div className="relative transform overflow-hidden min-w-[590px] max-w-[590px] max-h-[790px] rounded-[30px] bg-white  shadow-xl transition-all px-[70px] py-[46px]">
                <div className="flex flex-1 flex-col justify-center gap-[50px] ">
                  <p
                    className="text-black text-opacity-70 text-3xl font-bold"
                    id="modal-title">
                    {title
                      ? title
                      : type === 'error'
                      ? '오류'
                      : type === 'alert'
                      ? '알림'
                      : type === 'question'
                      ? '질문'
                      : type === 'check'
                      ? '확인'
                      : '알림'}
                  </p>
                  {content &&
                    (type === 'policy' && typeof content === 'string' ? (
                      <div className="mx-[-1rem] px-4 text-justify overflow-y-auto max-h-[500px]">
                        {parseHtmlContentWithSanitize(content)}
                      </div>
                    ) : (
                      content
                    ))}
                  {message && (
                    <p className="text-black text-opacity-70 text-[25px] font-medium whitespace-break-spaces">
                      {message}
                    </p>
                  )}
                  <div className="flex items-center justify-center gap-7">
                    {buttons.map((item, index) => (
                      <ModalButton
                        key={index}
                        text={item.text}
                        style={item.style}
                        onClick={item.onClick}
                      />
                    ))}
                  </div>
                </div>
                {/*{title === '설문조사 진행 안내' && message ? (*/}
                {/*  <div className="mt-2 ">*/}
                {/*    <p className="text-sm text-gray-500 px-2">*/}
                {/*      <div*/}
                {/*        dangerouslySetInnerHTML={{__html: message}}></div>*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*) : type === 'policy' ? (*/}
                {/*  <div className="mt-2 block">*/}
                {/*    <p className="text-sm text-gray-500 px-2 h-[50vh] overflow-y-auto">*/}
                {/*      {parseHtmlContentWithSanitize(message)}*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <div className="mt-2 ">*/}
                {/*    <p className="text-sm text-gray-500 px-2">*/}
                {/*      {parseHtmlContentWithSanitize(message)}*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;

export const ModalWrapper = ({
  isShowing,
  children,
}: {
  isShowing: boolean;
  children: React.ReactNode;
}) =>
  isShowing
    ? createPortal(
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex h-full justify-center items-center p-8">
              <div className="contain-modal max-w-[1180px] w-[590px] bg-white  border-gray-200 rounded-[30px] course-shadow max-h-[900px] h-4/5 overflow-y-auto scroll">
                {children}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
