import React, {useState, ChangeEvent, useEffect} from 'react';
import {IoCaretDown} from 'react-icons/io5';
import {Link, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as EyeInvisible} from '@/assets/newImg/eye-invisible-bold-s.svg';
import {ReactComponent as EyeVisible} from '@/assets/newImg/eye-visible-bold-s.svg';
import {NewLayout, FormLayout} from '@/components/common';
import {NewPrimaryButton as PrimaryButton} from '@/components/common/NewButton';
import {Input} from '@/components/common/NewInput';
import {Modal} from '@/components/common/NewModal';
import {useBoards} from '@/hooks/useBoards';
import useModal from '@/hooks/useModal';
import {useSitePreference} from '@/hooks/useSitePreference';
import useUser from '@/hooks/useUser';
import {
  checkIsDuplicatedEmailService,
  requestSmsCodeService,
  validateSmsCodeService,
  signUpService,
  signInService,
  validateAuthService,
} from '@/services/userService';
import siteState from '@/stores/site';
import {encodeHyphenPhoneNumber, decodeHyphenPhoneNumber} from '@/utils/format';
import {
  ValidateEmailSchema,
  ValidateSignUpFormSchema,
  ValidateSignUpAdditionalFormSchema,
  ValidateSignUpThirdPartySharePolicySchema,
} from '@/utils/validator';

export function NewSignUp() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState.siteInfoState);
  const preference = useSitePreference({siteId: siteData?.id ?? ''});

  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {refetchUser} = useUser();

  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    isAuthPhone: false,
    acceptPrivacyPolicy: false,
    acceptServicePolicy: false,
    acceptThirdPartySharePolicy: false,
    birthDate: '',
    gender: '',
  });
  const [verificationForm, setVerificationForm] = useState({
    phone: '',
    telecom: '', // KT, SKT
    birthDate: '',
    name: '',
    gender: '',
  });
  const [needAdditionalForm, setNeedAdditionalForm] = useState(false);
  const [code, setCode] = useState('');
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [checkedGender, setCheckedGender] = useState('');
  const [isSentCode, setIsSentCode] = useState(false);
  const [showSendButton, setShowSendButton] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [authId, setAuthId] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [formCaption, setFormCaption] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    isAuthPhone: '',
    acceptPrivacyPolicy: '',
    acceptServicePolicy: '',
    acceptThirdPartySharePolicy: '',
    birthDate: '',
    gender: '',
    telecom: '',
  });
  const telecoms: {
    id: 'SKT' | 'KT' | 'LGT';
    name: string;
    value: string;
  }[] = [
    {id: 'SKT', name: 'SKT', value: 'SKT'},
    {id: 'KT', name: 'KT', value: 'KT'},
    {id: 'LGT', name: 'LG U+', value: 'LG U+'},
  ];
  const [viewDropdown, setViewDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const {
    data: servicePolicyData,
    isLoading: isLoadingServicePolicy,
    refetch: refetchServicePolicy,
  } = useBoards({
    category: 'SERVICE_POLICY',
  });
  const {
    data: privacyPolicyData,
    isLoading: isLoadingPrivacyPolicy,
    refetch: refetchPrivacyPolicy,
  } = useBoards({
    category: 'PRIVACY_POLICY',
  });
  const {
    data: thirdPartySharePolicyData,
    isLoading: isLoadingThirdPartySharePolicy,
    refetch: refetchThirdPartySharePolicy,
  } = useBoards({
    category: 'THIRD_PARTY_SHARE_POLICY',
  });
  useEffect(() => {
    refetchServicePolicy();
    refetchPrivacyPolicy();
    refetchThirdPartySharePolicy();
  }, [siteData]);

  useEffect(() => {
    if (!preference) return;
    if (preference.sitePreference?.authVerification === true) {
      setNeedAdditionalForm(true);
    }
  }, [preference]);

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    let newValue;
    let newVerificationValue;
    if (id === 'phone') {
      if (value.length === 0) {
        setShowSendButton(false);
      } else {
        setShowSendButton(true);
      }
      newValue = {...form, phone: encodeHyphenPhoneNumber(value)};
      newVerificationValue = {
        ...verificationForm,
        phone: value,
      };
    } else {
      newValue = {...form, [id]: value};
      newVerificationValue = {...verificationForm, [id]: value};
    }

    setForm(newValue);
    setVerificationForm(newVerificationValue);

    // validation
    try {
      if (needAdditionalForm) {
        ValidateSignUpAdditionalFormSchema.validateSyncAt(id, {
          ...newValue,
          phone: decodeHyphenPhoneNumber(newValue.phone),
        });
      } else {
        ValidateSignUpFormSchema.validateSyncAt(id, {
          ...newValue,
          phone: decodeHyphenPhoneNumber(newValue.phone),
        });
      }

      if (id === 'name' && value === '')
        throw new Error('이름을 입력해주세요.');
      setFormCaption({...formCaption, [id]: ''});
    } catch (e: any) {
      setFormCaption({...formCaption, [id]: e.message});
    }
  }

  function handleGender(id: string) {
    if (id === 'M' && checkedGender !== id) {
      setCheckedGender('M');
    } else if (id === 'F' && checkedGender !== id) {
      setCheckedGender('F');
    }

    const newValue = {...form, gender: id};
    const newVerificationValue = {...verificationForm, gender: id};
    setForm(newValue);
    setVerificationForm(newVerificationValue);
  }

  async function checkEmailDuplicated() {
    if (formCaption.email) {
      setFormCaption({
        ...formCaption,
        email: '다른 이메일을 입력해주세요',
      });
      return;
    }

    const email = form.email;
    try {
      // 이메일 포맷 확인
      ValidateEmailSchema.validateSync(email);
      // 이메일 중복 여부 확인 요청
      const res = await checkIsDuplicatedEmailService(
        siteData?.id || '',
        email,
      );
      if (!res) {
        setFormCaption({
          ...formCaption,
          email: '이미 가입된 이메일입니다.',
        });
        return;
      }
      // 이메일 중복 확인 완료
      setIsEmailChecked(true);
    } catch (e: any) {
      openModal({
        type: 'error',
        message: '이메일을 확인해주세요.',
      });
    }
  }

  async function requestSmsCode() {
    if (!siteData?.id)
      return openModal({
        type: 'error',
        message: `사이트 정보가 없습니다.\n재접속 해주세요.`,
      });

    // 인증 완료 상태일 경우
    if (isPhoneChecked) return;

    // 핸드폰 포맷 확인
    try {
      ValidateSignUpFormSchema.validateSyncAt('phone', {
        phone: decodeHyphenPhoneNumber(form.phone),
      });
    } catch (err: any) {
      openModal({
        type: 'error',
        message: err.message,
      });
      return;
    }

    let authVerificationResult;
    if (needAdditionalForm) {
      if (!verificationForm.name) {
        openModal({
          type: 'error',
          message: '이름을 입력해주세요.',
        });
        return;
      }
      if (!verificationForm.birthDate) {
        openModal({
          type: 'error',
          message: '생년월일을 입력해주세요.',
        });
        return;
      }
      if (!verificationForm.gender) {
        openModal({
          type: 'error',
          message: '성별을 선택해주세요.',
        });
        return;
      }
      if (!verificationForm.telecom) {
        openModal({
          type: 'error',
          message: '통신사를 선택해주세요.',
        });
        return;
      }
      if (!verificationForm.phone) {
        openModal({
          type: 'error',
          message: '이름을 입력해주세요.',
        });
        return;
      }

      try {
        // 명의 인증 요청
        authVerificationResult = await validateAuthService(
          siteData.id,
          verificationForm.phone,
          verificationForm.telecom,
          verificationForm.birthDate,
          verificationForm.name,
          verificationForm.gender,
        );
      } catch (e: any) {
        openModal({
          type: 'error',
          message: '본인 인증에 실패했습니다. 개인 정보를 확인해주세요.',
        });
      }
    }
    if (!needAdditionalForm || authVerificationResult) {
      try {
        // 인증번호 발송 요청
        await requestSmsCodeService(
          siteData!.id,
          'SIGN_UP',
          decodeHyphenPhoneNumber(form.phone),
        );
        // 인증번호 발송 후 폰 캡션 제거
        setFormCaption({...formCaption, phone: ''});
        // 인증번호 발송 여부 갱신
        setIsSentCode(true);
        openModal({
          type: 'check',
          message: '인증 문자가 발송되었습니다.',
        });
      } catch (e: any) {
        setFormCaption({
          ...formCaption,
          phone: e.data.error.message,
        });
      }
    }
  }

  async function validateSmsCode() {
    if (form.isAuthPhone) return; // 이미 인증 완료 시

    try {
      const phoneNumber = decodeHyphenPhoneNumber(form.phone);
      const res = await validateSmsCodeService(
        siteData!.id,
        'SIGN_UP',
        phoneNumber,
        code,
      );
      setAuthId(res);

      setForm({...form, isAuthPhone: true});

      setIsPhoneChecked(true);
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  }

  async function handleSignUp() {
    if (!isEmailChecked) {
      openModal({
        type: 'error',
        message: '이메일 중복 확인을 해주세요.',
      });
      return;
    }

    if (!form.password) {
      openModal({
        type: 'error',
        message: '비밀번호를 입력해주세요.',
      });
      return;
    }

    if (!form.rePassword) {
      openModal({
        type: 'error',
        message: '비밀번호 확인을 입력해주세요.',
      });
      return;
    }

    if (!form.name) {
      openModal({
        type: 'error',
        message: '이름을 입력해주세요.',
      });
      return;
    }

    if (!form.isAuthPhone) {
      openModal({
        type: 'error',
        message: '휴대폰 인증을 진행해주세요.',
      });
      return;
    }

    try {
      ValidateSignUpFormSchema.validateSync({
        ...form,
        phone: decodeHyphenPhoneNumber(form.phone),
      });
      if (thirdPartySharePolicyData?.[0]) {
        ValidateSignUpThirdPartySharePolicySchema.validateSync({
          acceptThirdPartySharePolicy: form.acceptThirdPartySharePolicy,
        });
      }
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.message,
      });
      return;
    }

    try {
      await signUpService({
        authId,
        siteId: siteData!.id,
        name: form.name,
        gender: form.gender,
        birthDate: form.birthDate,
        email: form.email,
        phone: decodeHyphenPhoneNumber(form.phone),
        password: form.password,
      });

      openModal({
        type: 'check',
        title: '회원가입 성공',
        message: '회원가입이 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              // 자동 로그인 및 메인 페이지 이동
              newAutoSignIn();
            },
          },
        ],
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  }

  async function autoSignIn() {
    await signInService(siteData?.id ?? '', form.email, form.password);
    await refetchUser();
    navigate('/');
  }

  async function newAutoSignIn() {
    await signInService(siteData?.id ?? '', form.email, form.password);
    await refetchUser();
    navigate('/');
  }

  function popupServicePolicy() {
    if (!form.acceptServicePolicy && !isLoadingServicePolicy) {
      openModal({
        type: 'policy',
        title: servicePolicyData[0].title,
        content: servicePolicyData[0].content,
        buttons: [
          {
            text: '취소',
            style: 'negative',
            onClick: () => {
              closeModal();
            },
          },
          {
            text: '동의',
            style: 'normal',
            onClick: () => {
              // 동의 시
              setForm(prev => ({
                ...prev,
                acceptServicePolicy: true,
              }));
              closeModal();
            },
          },
        ],
      });

      return;
    } else {
      // 체크 해제
      setForm(prev => ({
        ...prev,
        acceptServicePolicy: false,
      }));
    }
  }

  function popupPrivacyPolicy() {
    if (!form.acceptPrivacyPolicy && !isLoadingPrivacyPolicy) {
      openModal({
        type: 'policy',
        title: privacyPolicyData[0].title,
        content: privacyPolicyData[0].content,
        buttons: [
          {
            text: '취소',
            style: 'negative',
            onClick: () => {
              closeModal();
            },
          },
          {
            text: '동의',
            style: 'normal',
            onClick: () => {
              // 동의 시
              setForm(prev => ({
                ...prev,
                acceptPrivacyPolicy: true,
              }));
              closeModal();
            },
          },
        ],
      });

      return;
    } else {
      // 체크 해제
      setForm(prev => ({
        ...prev,
        acceptPrivacyPolicy: false,
      }));
    }
  }

  function popupThirdPartySharePolicy() {
    if (!form.acceptThirdPartySharePolicy && !isLoadingThirdPartySharePolicy) {
      openModal({
        type: 'policy',
        title: thirdPartySharePolicyData[0].title,
        content: thirdPartySharePolicyData[0].content,
        buttons: [
          {
            text: '취소',
            style: 'negative',
            onClick: () => {
              closeModal();
            },
          },
          {
            text: '동의',
            style: 'normal',
            onClick: () => {
              // 동의 시
              setForm(prev => ({
                ...prev,
                acceptThirdPartySharePolicy: true,
              }));
              closeModal();
            },
          },
        ],
      });

      return;
    } else {
      // 체크 해제
      setForm(prev => ({
        ...prev,
        acceptThirdPartySharePolicy: false,
      }));
    }
  }

  /**
   * TODO: 쿼리스트링에 저장된 url이 있을 시 로그인 성공 이후 해당 페이지로 리다이렉트
   */
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewDropdown(!viewDropdown);
  };
  const handleItemClick = (id: string, value: string) => {
    setSelectedItem(value);
    setViewDropdown(false);
    const newVerificationValue = {...verificationForm, telecom: id};
    setVerificationForm(newVerificationValue);
  };

  return (
    <>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        content={modalData?.content}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <NewLayout>
        <FormLayout>
          <h3 className="font-bold text-[30px] mb-8">회원가입</h3>
          <form className="w-full flex flex-col mb-[21px]">
            {/* 로그인 정보 */}
            <Input
              id="email"
              type="email"
              placeholder="이메일을 입력하세요."
              label="이메일"
              disabled={isEmailChecked}
              value={form.email}
              onChange={handleForm}
              addClass={
                isEmailChecked ? 'bg-readOnly cursor-default' : 'bg-white'
              }
              onKeyDown={async (
                event: React.KeyboardEvent<HTMLInputElement>,
              ) => {
                if (event.key === 'Enter') await checkEmailDuplicated();
              }}
              rightButton={
                <PrimaryButton
                  type="button"
                  button={
                    isEmailChecked
                      ? 'success'
                      : formCaption.email
                      ? 'disabled'
                      : 'primary'
                  }
                  onClick={!isEmailChecked ? checkEmailDuplicated : () => ({})}
                  text={isEmailChecked ? '사용 가능' : '중복 확인'}
                  addClass={``}
                />
              }
            />
            {/* 이메일 */}
            {formCaption.email ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {formCaption.email}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            {/* 비밀번호 */}
            <Input
              id="password"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호를 입력하세요."
              label="비밀번호"
              value={form.password}
              onChange={handleForm}
              rightIcon={
                visiblePassword ? (
                  <EyeVisible
                    className="ml-2"
                    width={22}
                    height={15}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                ) : (
                  <EyeInvisible
                    className="ml-2"
                    width={22}
                    height={17}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                )
              }
            />
            {formCaption.password ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {formCaption.password}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            {/* 비밀번호 확인 */}
            <Input
              id="rePassword"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호를 입력하세요."
              label="비밀번호 확인"
              value={form.rePassword}
              onChange={handleForm}
            />
            {formCaption.rePassword ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {formCaption.rePassword}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            {/* 회원 정보 */}
            {/* 이름 */}
            <Input
              id="name"
              type="text"
              placeholder="이름을 입력하세요."
              label="이름"
              value={form.name}
              onChange={handleForm}
            />
            {formCaption.name ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {formCaption.name}
              </p>
            ) : (
              <div className="mb-6" />
            )}
            {/* 폼 추가(생년월일, 성별, 통신사, 휴대폰번호) */}
            {/* 생년월일 */}
            {needAdditionalForm && (
              <>
                <div className={'flex'}>
                  <Input
                    id="birthDate"
                    type="text"
                    placeholder="8자로 입력하세요.(YYYYMMDD)"
                    label="생년월일"
                    value={form.birthDate}
                    onChange={handleForm}
                    addClass={'bg-white'}
                    rightButton={<></>}
                  />
                  <div className="flex flex-col  min-w-[157px]">
                    <label className="mb-[10px] text-[#808080] text-[18px] font-medium">
                      성별
                    </label>
                    <div
                      className={`border-r overflow-hidden relative w-full h-[60px] flex items-center rounded-[10px] border`}>
                      <button
                        className={`transition-all ease-in-out delay-50 w-[50%] h-full text-disabled border-r ${
                          checkedGender === 'M'
                            ? ' bg-primary text-white font-semibold '
                            : ' hover:bg-secondary hover:text-white hover:font-semibold '
                        }
                              `}
                        id="M"
                        type="button"
                        value={form.gender}
                        onClick={() => handleGender('M')}>
                        <p>남성</p>
                      </button>
                      <button
                        className={`transition-all ease-in-out delay-50 w-[50%] h-full text-disabled ${
                          checkedGender === 'F'
                            ? ' bg-primary text-white font-semibold '
                            : ' hover:bg-secondary hover:text-white hover:font-semibold '
                        }
                              `}
                        id="F"
                        type="button"
                        value={form.gender}
                        onClick={() => handleGender('F')}>
                        <p>여성</p>
                      </button>
                    </div>
                  </div>
                </div>
                {formCaption.birthDate ? (
                  <p className="text-rose-500 w-full text-sm mb-5">
                    {formCaption.birthDate}
                  </p>
                ) : (
                  <div className="mb-6" />
                )}
              </>
            )}
            {/* 휴대폰 */}
            {/* 통신사 */}
            {needAdditionalForm && (
              <div className="w-full flex flex-col mb-6">
                <label className="mb-[10px] text-[#808080] text-[18px] font-medium">
                  통신사
                </label>
                <div
                  className={
                    'relative h-[60px] flex items-center rounded-[10px] border'
                  }>
                  <button
                    className={
                      'h-[100%] w-full flex justify-between items-center border border-transparent rounded-[10px] bg-white py-[18px] px-[30px]'
                    }
                    onClick={handleButtonClick}>
                    <p
                      className={`whitespace-nowrap ${
                        !selectedItem && ' text-disabled '
                      }`}>
                      {selectedItem ? selectedItem : '통신사'}
                    </p>
                    <IoCaretDown
                      className={'text-disabled text-sm react-icons'}
                    />
                  </button>
                  {viewDropdown && (
                    <div
                      className={
                        'overflow-hidden absolute w-full top-[59px] left-0 flex flex-col bg-white border rounded-[10px] z-[3000] '
                      }>
                      {telecoms.map(telecom => (
                        <button
                          className={
                            'text-start text-[16px] hover:bg-gray-200 px-[30px] py-[18px] '
                          }
                          key={telecom.value}
                          onClick={() =>
                            handleItemClick(telecom.id, telecom.value)
                          }>
                          {telecom.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* 휴대폰 번호 */}
            <div className={'flex flex-col w-full'}>
              <Input
                id="phone"
                type="text"
                placeholder="휴대폰 번호를 입력하세요."
                label="휴대폰 번호"
                value={form.phone}
                maxLength={13}
                onChange={handleForm}
                onKeyDown={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') await requestSmsCode();
                }}
                addClass={
                  isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                }
                rightButton={
                  !isPhoneChecked &&
                  (showSendButton ? (
                    !isSentCode ? (
                      <PrimaryButton
                        type="button"
                        button="primary"
                        onClick={requestSmsCode} // 폰명의 체크 api 호출, 번호 유효하면 isPhoneChecked true
                        text="인증번호 전송"
                        addClass={`w-[162px]`}
                      />
                    ) : (
                      <PrimaryButton
                        type="button"
                        button="primary"
                        onClick={requestSmsCode}
                        text="인증번호 재전송"
                        addClass={`w-[162px] ${
                          isPhoneChecked
                            ? 'hidden'
                            : 'hover:bg-secondary hover:text-white'
                        }`}
                      />
                    )
                  ) : (
                    <PrimaryButton
                      type="button"
                      button="disabled"
                      onClick={requestSmsCode}
                      text="인증번호 전송"
                      addClass={`w-[162px]`}
                    />
                  ))
                }
              />
              {formCaption.phone ? (
                <p className="text-rose-500 w-full text-sm mb-5">
                  {formCaption.phone}
                </p>
              ) : (
                <div className="mb-6" />
              )}
            </div>
            {/* 인증번호 */}
            <>
              <Input
                id="code"
                type="text"
                placeholder="인증번호를 입력하세요."
                label="인증번호"
                value={code}
                onChange={e => setCode(e.target.value)}
                onKeyDown={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') await validateSmsCode();
                }}
                addClass={
                  isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                }
                rightButton={
                  <>
                    {!isPhoneChecked ? (
                      !isSentCode ? (
                        <PrimaryButton
                          type="button"
                          button="disabled"
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      ) : (
                        <PrimaryButton
                          type="button"
                          button="primary"
                          onClick={validateSmsCode}
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      )
                    ) : (
                      <PrimaryButton
                        type="button"
                        button="success"
                        onClick={validateSmsCode}
                        text="인증 완료"
                        addClass={`w-[162px]`}
                      />
                    )}
                  </>
                }
              />
              {code && <p className="text-red-500 w-full text-sm pl-8"></p>}
            </>
            {/* 약관 동의 */}
            <div
              className={`flex flex-row ${
                thirdPartySharePolicyData.length
                  ? 'justify-between'
                  : 'justify-center'
              }`}>
              <div
                className={`${
                  thirdPartySharePolicyData?.[0] ? 'mr-[5px]' : 'ml-4 mr-[18px]'
                } my-4`}>
                <input
                  id="privacy_policy"
                  type="checkbox"
                  checked={form.acceptPrivacyPolicy}
                  onChange={popupPrivacyPolicy}
                  className={`border focus:outline-none rounded-[2px] focus:ring-0 ${
                    thirdPartySharePolicyData?.[0] ? 'mr-[4px]' : 'mr-[9px]'
                  } border-gray-300 text-primary w-3 h-3`}
                />
                <label
                  htmlFor="privacy_policy"
                  className="text-primary no-drag inline-block hover:cursor-pointer text-sm">
                  개인정보처리방침 동의
                </label>
              </div>
              <div
                className={`${
                  thirdPartySharePolicyData?.[0] ? 'mr-[5px]' : ''
                } my-4`}>
                <input
                  id="service_policy"
                  type="checkbox"
                  checked={form.acceptServicePolicy}
                  onChange={popupServicePolicy}
                  className={`focus:outline-none rounded-[2px] focus:ring-0 ${
                    thirdPartySharePolicyData?.[0] ? 'mr-[4px]' : 'mr-[9px]'
                  } border-gray-300 text-primary w-3 h-3`}
                />
                <label
                  htmlFor="service_policy"
                  className="text-primary no-drag inline-block hover:cursor-pointer text-sm">
                  서비스이용약관 동의
                </label>
              </div>
              {thirdPartySharePolicyData[0] && (
                <div className="my-4">
                  <input
                    id="third_party_share_policy"
                    type="checkbox"
                    checked={form.acceptThirdPartySharePolicy}
                    onChange={popupThirdPartySharePolicy}
                    className={`focus:outline-none rounded-[2px] focus:ring-0 ${
                      thirdPartySharePolicyData ? 'mr-[4px]' : 'mr-[9px]'
                    } border-gray-300 text-primary w-3 h-3`}
                  />
                  <label
                    htmlFor="third_party_share_policy"
                    className="text-primary no-drag inline-block hover:cursor-pointer text-sm">
                    제3자정보제공 동의
                  </label>
                </div>
              )}
            </div>
            <div className={'flex justify-center'}>
              <div
                onClick={handleSignUp}
                className="h-[60px] font-bold cursor-pointer bg-primary text-white hover:bg-[#2963A6] hover:text-white text-center flex justify-center items-center mt-[42px] whitespace-nowrap px-auto py-auto w-full text-xl border rounded-[30px] transition-all ease-in-out delay-50 ">
                회원가입하기
              </div>
            </div>
          </form>

          {/* 로그인 관련 페이지 이동 버튼 */}
          <Link
            to="/new-sign-in"
            className="w-full flex justify-center text-[15px] font-medium text-primary mb-1">
            <p className="text-[15px] text-[#b4b4b4] mr-1">
              이미 가입한 계정이 있으신가요?
            </p>
            로그인
          </Link>
        </FormLayout>
      </NewLayout>
    </>
  );
}
