import React, {useEffect, useState} from 'react';

import {useRecoilValue} from 'recoil';

import {surveyState} from '@/stores/survey';
import {SurveyTemplateProps} from '@/types/survey.type';

function SurveyInputTitle({
  label,
  labelStyle,
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  label?: string;
  labelStyle?: React.CSSProperties;
  upperQuestion?: SurveyTemplateProps;
  questionIndex?: number;
}) {
  const surveyRegisterState = useRecoilValue(surveyState.surveyRegisterState);
  const surveyAnswerRegisterState = useRecoilValue(
    surveyState.surveyAnswerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? surveyRegisterState.template
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : surveyRegisterState.template.find(({seq}) => seq === index);

  const calculateLeftSize = (index: number) => {
    const question = currentQuestion?.question || '';
    const questionLength = question.length * 16;
    return `${questionLength}px`;
  };

  useEffect(() => {
    if (
      !surveyAnswerRegisterState ||
      !surveyAnswerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      surveyAnswerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        surveyAnswerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [surveyAnswerRegisterState.answers?.[questionIndex || 0]?.answer?.[0]]);

  return (
    <div className={'w-full relative'}>
      {!upperQuestion ? (
        <div className={'mb-3'}>
          <label className={'font-semibold '}>{index + 1}번 항목</label>
        </div>
      ) : (
        <div className={'mb-2'}>
          <label
            className={`text-18 font-semibold ${
              disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
            }`}>
            {upperQuestion?.seq + 1}-{index + 1}번 항목 (
            {upperQuestion?.seq + 1}번 항목 답변 시 활성화)
          </label>
        </div>
      )}

      <div
        className={`w-full ${
          label && label.length > 50 ? 'flex-col' : 'flex'
        }`}>
        {label &&
          (label.length > 50 ? (
            <textarea
              disabled={true}
              // style={{width: calculateLeftSize(label.length)}}
              className={`ml-2 text-18 font-medium w-full ${
                label.length > 50 ? 'h-[150px] ' : 'h-[50px] resize-none'
              } border-none readonly ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}
              value={label}></textarea>
          ) : (
            <label
              className={`ml-2 text-18 font-medium w-fit ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              {label}
            </label>
          ))}
        <div className={'flex'}>
          <div className={'flex'}>
            {currentQuestion?.type === 'FILE_UPLOAD' && (
              <span
                className={`pl-[2px] ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}
                style={{left: calculateLeftSize(index)}}>
                (최대 파일 수 : {currentQuestion.config.maxCount || 1}개)
              </span>
            )}
          </div>
          {currentQuestion?.isRequired && !disabled && (
            <div className="flex items-center">
              {/*<div className="absolute bottom-[20px] w-[6px] h-[6px] bg-[#FF5353] rounded-3xl "></div>*/}
              <span
                className=" font-medium top-[7px] text-[#FF5353] text-[11px] ml-[10px] w-fit pl-2 "
                style={{left: calculateLeftSize(index)}}>
                필수 입력 항목입니다.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyInputTitle;
