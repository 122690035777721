import {useEffect} from 'react';
import {useQuery} from 'react-query';

import {PAGINATION_DATA} from '@/constants/common';
import {searchSurveyAnswerList, searchSurveyList, searchSurveyTemplateList} from '@/services/surveyService';

export function useSurvey(parentId: string) {
  const {data, refetch} = useQuery({
    queryKey: ['survey', parentId],
    queryFn: async () => {
      return await searchSurveyList({
        parentType: 'COURSE',
        parentId: parentId,
      });
    },
    enabled: !!parentId,
    initialData: PAGINATION_DATA,
  });

  useEffect(() => {
    refetch();
  }, []);

  return {
    data,
    refetch,
  };
}

export function useSurveyTemplate(templateId?: string) {
  const {data, refetch} = useQuery({
    queryKey: ['surveyTemplate', templateId],
    queryFn: async () => {
      return await searchSurveyTemplateList({
        uid: templateId,
      });
    },
    enabled: !!templateId,
    initialData: PAGINATION_DATA,
  });

  useEffect(() => {
    refetch();
  }, []);

  return {
    data,
    refetch,
  };
}

export function useSurveyAnswerList(surveyId?: string, userId?: string) {
  const {data, refetch} = useQuery({
    queryKey: ['surveyAnswerList', surveyId, userId],
    queryFn: async () => {
      return await searchSurveyAnswerList({
        surveyId : surveyId,
        userId : userId,
      });
    },
    enabled: !!surveyId && !!userId,
  });

  useEffect(() => {
    refetch();
  }, []);

  return {
    data,
    refetch,
  };
}
