import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import SampleThumbnail from '@/assets/img/sample-02.png';
import {NewAttendButton} from '@/components/common';
import {Chapter} from '@/types/chapter.type';
import {AttendStatus, Course} from '@/types/course.type';
import {getToday} from '@/utils/format';

const StatusBar = ({width}: {width: string}) => (
  <div className="w-[370px] h-[10px] bg-disabled overflow-hidden rounded-full">
    <div className="bg-primary h-[10px]" style={{width}}></div>
  </div>
);

const ProgressBar = ({chapterItem}: {chapterItem: Chapter}) => {
  const statusBarWidth = chapterItem.progressRate
    ? `${chapterItem.progressRate}%`
    : '0';

  return (
    <div className="flex flex-col gap-1 py-[8px]">
      <StatusBar width={statusBarWidth} />
      <div className="text-[#666666] font-medium text-xs">
        {chapterItem.progressRate}% 완료
      </div>
    </div>
  );
};

export function ChapterInfoCard({
  courseInfo,
  chapterItem,
  index,
}: {
  courseInfo: Course;
  chapterItem: Chapter;
  index: number;
}) {
  const navigate = useNavigate();
  // 출석 상태 생성
  const genAttendStatus = (attendStatus?: string): AttendStatus => {
    switch (attendStatus) {
      case 'ATTEND':
        return {
          type: 'primary',
          value: '출석',
        };
      case 'NOT_ATTEND':
        return {
          type: 'secondary',
          value: '결석',
        };
      case 'E_LEAVE':
        return {
          type: 'secondary',
          value: '조퇴',
        };
      default:
        return {
          type: 'disabled',
          value: '예정',
        };
    }
  };
  const genChapterDuration = (durationTimeInSec: number): string => {
    return `${Math.floor(durationTimeInSec / 360)}:${
      Math.floor(durationTimeInSec / 60) < 10
        ? `0${Math.floor(durationTimeInSec / 60)}`
        : Math.floor(durationTimeInSec / 60)
    }`;
  };

  const attendStatus = useMemo(
    () => genAttendStatus(chapterItem.attendStatus),
    [chapterItem.attendStatus],
  );

  const handleOnClickHomework = () => {
    navigate(`/new-homework/${chapterItem.courseId}?chapter=${chapterItem.id}`);
  };

  const handleOnClickChapter = () => {
    const now = getToday();
    if (now < courseInfo.startsAt || now > courseInfo.endsAt) {
      alert('교육기간이 아닙니다. 교육기간을 확인해주세요. ');
      return;
    }
    navigate(`/lecture/${chapterItem.courseId}?chapter=${chapterItem.id}`);
  };

  return (
    <div className="px-10 pt-[37px] pb-10 flex border border-b-1">
      <div className="relative flex items-center min-w-[360px] max-w-[360px] h-[200px] border-none rounded-[30px] mr-10 overflow-hidden">
        <img
          src={chapterItem.thumbnailUrl || SampleThumbnail}
          alt=""
          className="w-full object-cover"
        />
        <NewAttendButton
          type={attendStatus.type}
          text={attendStatus.value}
          addClass="absolute top-5 left-5"
        />
        <div className="absolute right-0 bottom-0 w-[92px] h-[29px] flex items-center bg-black bg-opacity-50 text-white text-base  pl-[13px] py-[3px]">
          {genChapterDuration(chapterItem.durationTimeInSec)}
        </div>
      </div>
      <div className="w-full flex flex-col justify-between">
        <div className="flex text-xl font-bold text-[#4d4d4d]">
          <div>{`${index + 1}. ${chapterItem.name}`}</div>
        </div>
        <div className="flex justify-between items-center">
          <ProgressBar chapterItem={chapterItem} />
          <div className="flex gap-2.5">
            <button
              onClick={() => handleOnClickHomework()}
              className="whitespace-nowrap rounded-full text-lg font-bold  py-[6px] md:px-3 lg:px-[19px] duration-100 bg-primary text-white border border-transparent hover:bg-primary_hover">
              과제
            </button>
            {courseInfo.endsAt < getToday() ? (
              <button className="whitespace-nowrap rounded-full text-lg font-bold  py-[6px] md:px-3 lg:px-[19px] duration-100 bg-disabled text-white border border-transparent">
                과정종료
              </button>
            ) : (
              <button
                onClick={() => {
                  handleOnClickChapter();
                }}
                className="whitespace-nowrap rounded-full text-lg font-bold  py-[6px] md:px-3 lg:px-[19px] duration-100 bg-primary text-white border border-transparent hover:bg-primary_hover">
                수강하기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
