import {reqUploadHomework} from '@/api/courseApi';
import {Attachment} from '@/types/common.type';

export interface SubmitHomeworkParams {
  title: string;
  content: string;
  attachments?: Attachment[];
}

export async function submitHomeworkService(
  homeworkId: string,
  homework: SubmitHomeworkParams,
) {
  const response = await reqUploadHomework(homeworkId, homework);
  return response.data.data;
}
