import {useMemo, useState} from 'react';
import {useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import {PAGINATION_DATA} from '@/constants/common';
import useUser from '@/hooks/useUser';
import {
  getMyCourseListService,
  getXProgressService,
} from '@/services/courseService';
import siteState from '@/stores/site';
import {Course, CourseWithProgress} from '@/types/course.type';
import {Site} from '@/types/site.type';
import {User} from '@/types/user.type';

export function useMyCourse(searchQuery?: string | null) {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 10});
  const {user} = useUser();
  const siteData = useRecoilValue(siteState.siteInfoState);

  const queryInfo = useMemo(
    () => ({
      user,
      site: siteData,
      pageParams,
    }),
    [user, siteData, pageParams],
  );

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['myCourse', queryInfo],
    queryFn: async ({queryKey}) => {
      const {user, site, pageParams} = queryKey[1] as {
        user: User;
        site: Site;
        pageParams: {page: number; pageSize: number};
      };

      if (!user.id || !site.id) return PAGINATION_DATA;

      const myCourseResponse = await getMyCourseListService(
          {
            userId: user.id,
            siteId: site.id,
            academyId: null,
            searchQuery,
            ...pageParams,
          }
      );

      const progressResponse = await getXProgressService({
        siteId: site.id,
        xType: 'COURSE',
      });

      const newMyCourseContent: CourseWithProgress[] =
        myCourseResponse.content.map((item: Course) => {
          const matchedProgress = progressResponse.find(
            progress => progress.courseId === item.id,
          );
          return matchedProgress ? {...item, ...matchedProgress} : item;
        });

      return {
        ...myCourseResponse,
        content: newMyCourseContent,
      };
    },
    initialData: PAGINATION_DATA,
  });

  function onChangePage(page: number) {
    setPageParams(prev => ({
      ...prev,
      page,
    }));
  }

  return {
    myCourseList: data?.content || PAGINATION_DATA.content,
    paginationInfo: data?.page || PAGINATION_DATA.page,
    onChangePage,
    isLoading,
    refetchMyCourses: refetch,
  };
}
