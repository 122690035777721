import {Api} from '@/App';

import {
  ReqBoardListParams,
  QNADataParams,
  QuestionData,
} from '@/types/boards.type';

export function reqGetBoardList(params: ReqBoardListParams) {
  return Api.get(`/boards`, {
    params,
  });
}

export function reqGetBoardDetail(boardId: string) {
  return Api.get(`/boards/${boardId}`);
}

export function reqGetComments({
  boardId,
  siteId,
  page,
  pageSize,
}: {
  boardId: string;
  siteId: string;
  page: number;
  pageSize: number;
}) {
  return Api.get('/boards', {
    params: {
      boardId,
      siteId,
      category: 'COMMENT',
      page,
      pageSize,
    },
  });
}

export function reqSiteQNA(data: QNADataParams) {
  const newData = {
    userEmail: data.userEmail,
    title: data.title,
    content: data.content,
    userName: data.userName,
    userPhone: data.userPhone,
    siteId: data.siteId,
  };
  return Api.post('/qnas', newData);
}

export function reqWriteQuestion(data: QuestionData) {
  const newData = {
    category: 'QNA',
    type: 'COURSE',
    title: data.title,
    content: data.content,
    typeId: data.course,
    attachments: [],
  };
  return Api.post('/users/boards', newData);
}
