import {
  ButtonWithIconProps,
  ButtonWithTextProps,
  NewButtonWithTextProps,
} from '@/types/common.type';


export function CircleButton({icon, onClick, size}: ButtonWithIconProps) {
  return (
    <button
      onClick={onClick}
      className={`w-6 h-6 w-${size} h-${size} flex justify-center items-center border border-black rounded-full text-center text-black text-lg hover:bg-black hover:text-white transition duration-300 cursor-pointer`}>
      {icon}
    </button>
  );
}

export function TableButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap text-secondary border border-secondary py-2 md:px-2 rounded hover:bg-secondary hover:text-white">
      {text}
    </button>
  );
}

export function PrimarySquareButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap bg-transparent text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-primary rounded text-primary hover:bg-primary hover:text-white hover:border-transparent duration-100">
      {text}
    </button>
  );
}

export function PrimarySquareButtonWithIcon({
  icon,
  onClick,
}: {
  icon: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className="bg-primary text-sm font-[Pippins] px-4 py-1 mx-4 mt-1 border border-transparent rounded text-white hover:bg-transparent hover:text-primary hover:border-primary duration-100">
      {icon}
    </button>
  );
}

export function SecondarySquareButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap bg-transparent text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-secondary rounded text-secondary hover:bg-secondary hover:text-white hover:border-transparent duration-100">
      {text}
    </button>
  );
}

export function DisabledSquareButton({text}: ButtonWithTextProps) {
  return (
    <button className="whitespace-nowrap bg-disabled text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-disabled rounded text-gray-500 duration-100 cursor-default">
      {text}
    </button>
  );
}

export function SquareButton({
  text,
  onClick,
  type,
  hasNoHoverEffect,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;
  let hoverStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-primary text-primary';
    hoverStyle = 'hover:bg-primary hover:text-white hover:border-transparent';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-secondary text-secondary';
    hoverStyle = 'hover:bg-secondary hover:text-white hover:border-transparent';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-gray-400 border-gray-400';
    hoverStyle = 'cursor-default';
  }

  // No hover effect
  if (hasNoHoverEffect) {
    hoverStyle = 'cursor-default';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`whitespace-nowrap px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border rounded text-sm font-[Pippins] duration-100 
    ${typeOfStyle} ${hoverStyle} ${addClass}
    `}>
      {text}
    </button>
  );
}

export function NewPrimaryButton({
  type,
  button,
  text,
  onClick,
  addClass,
  hasNoHoverEffect,
}: NewButtonWithTextProps) {
  let typeOfStyle;
  let hoverStyle;

  if (button === 'primary') {
    typeOfStyle = 'bg-primary text-white';
    hoverStyle = 'cursor-pointer hover:bg-[#2963A6] hover:text-white';
  }
  if (button === 'secondary') {
    typeOfStyle = 'border-secondary text-secondary';
    hoverStyle =
      'cursor-pointer hover:bg-secondary hover:text-white hover:border-transparent';
  }
  if (button === 'disabled') {
    typeOfStyle = 'bg-disabled text-white border-transparent';
    hoverStyle = 'cursor-default';
  }
  if (button === 'success') {
    typeOfStyle = 'text-primary border-primary bg-success';
    hoverStyle = 'cursor-pointer';
  }
  if (button === 'emphasis') {
    typeOfStyle = 'bg-emphasis text-white';
    hoverStyle = 'cursor-pointer ';
  }
  if (button === 'cancel') {
    typeOfStyle = 'bg-white border border-[#e5e5e5]';
    hoverStyle = 'cursor-pointer hover:bg-[#f2f2f2]';
  }

  // No hover effect
  if (hasNoHoverEffect) {
    hoverStyle = 'cursor-default';
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={button === 'disabled'}
      className={`whitespace-nowrap px-auto py-auto font-bold w-[130px] h-[50px] text-base border rounded-[30px] transition-all ease-in-out delay-50 
        ${typeOfStyle} ${hoverStyle} ${addClass} `}>
      {text}
    </button>
  );
}

export function NewAttendButton({
  text,
  onClick,
  type,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-2 border-primary text-primary bg-success';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-transparent text-primary bg-success ';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-[#999999] border-transparent bg-[#f2f2f2]';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`w-[72px] h-[40px] px-[19px] py-[7px] justify-center items-center inline-flex rounded-[30px] whitespace-nowrap  cursor-default duration-100 text-center text-lg font-bold 
    ${typeOfStyle} ${addClass}
    `}>
      {text}
    </button>
  );
}

export function NewStatusButton({
  text,
  onClick,
  type,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-primary text-primary bg-success';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-transparent text-primary bg-success ';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-[#999999] border-transparent bg-[#f2f2f2]';
  }
  if (type === 'readOnly') {
    typeOfStyle = 'text-white border-transparent bg-[#bfbfbf]';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`cursor-default whitespace-nowrap rounded-full text-sm font-bold py-[6px] lg:px-[13px] border-2 duration-100 
    ${typeOfStyle} ${addClass}
    `}>
      {text}
    </button>
  );
}
