import Profile1 from '@/assets/img/profile1.png';
import Profile2 from '@/assets/img/profile2.png';
import Profile3 from '@/assets/img/profile3.png';
import Profile4 from '@/assets/img/profile4.png';
import StarRating from '@/components/common/StarRating';

const INIT_REVIEWS = [
  {
    id: 1,
    profile: Profile1,
    name: '김**님',
    content: '어려운 부분도 너무 쉽게 알려 주셔서 좋았어요~',
    rating: 5,
  },
  {
    id: 2,
    profile: Profile2,
    name: '이**님',
    content: '재미있고 유익한 강의였습니다 감사합니다.',
    rating: 4,
  },
  {
    id: 3,
    profile: Profile3,
    name: '강**님',
    content: '하루하루 배워가는 재미가 있네요!!',
    rating: 3.5,
  },
  {
    id: 4,
    profile: Profile4,
    name: '윤**님',
    content: '자격증 시험이 무섭지 않아요!! 너무 감사합니다.',
    rating: 4,
  },
  {
    id: 5,
    profile: Profile1,
    name: '박**님',
    content: '실습과 함께하니깐 실력이 더 잘 느는 것 같아요.',
    rating: 4.5,
  },
  {
    id: 6,
    profile: Profile2,
    name: '김**님',
    content: '문제풀이가 친절해서 잘 들을 수 있었습니다~^^',
    rating: 4,
  },
  {
    id: 7,
    profile: Profile3,
    name: '이**님',
    content: '자세하게 배울 수 있어서 좋았어요 또 듣고싶어요\n.',
    rating: 5,
  },
];

const ReviewCard = ({
  profile,
  name,
  content,
  rating,
}: {
  profile: string;
  name: string;
  content: string;
  rating: number;
}) => (
  <div className="flex flex-col w-[280px] h-[254px] px-[40px] py-[30px] bg-white rounded-[30px]">
    <div className="flex justify-center h-[54px] w-[54px] mb-3 rounded-full bg-[#EEEEEE] overflow-hidden">
      <img
        src={profile}
        alt=""
        className="w-full h-full text-[#191D88] text-center"
      />
    </div>
    <h5 className="mb-[17px] font-bold text-xl text-[#404040]">{name}</h5>
    <p className="text-[#999999] font-medium text-[16px] mb-[14px] course-title-style">
      {content}
    </p>
    <StarRating
      rating={rating}
      classNames="flex h-[22px] text-[#337CCF] gap-1"
    />
  </div>
);

export default function MainReviews() {
  return (
    <div className="pb-[130px] bg-[#fbfbfb]">
      <div className="flex justify-center px-auto pt-[70px] pb-[109px] bg-[#337CCF]">
        <div className="max-w-[1180px] w-full flex flex-col gap-5">
          <h3 className="font-bold text-[40px] text-white mb-[49px]">
            다양한 수강후기
          </h3>
          <div className="flex flex-wrap justify-center gap-5">
            {INIT_REVIEWS.map(review => (
              <ReviewCard key={review.id} {...review} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
