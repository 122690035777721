import {atom} from 'recoil';

import {
  SurveyAnswerProps,
  SurveyTemplateResponseProps,
} from '@/types/survey.type';

export const surveyRegisterState = atom<SurveyTemplateResponseProps>({
  key: 'surveyRegisterState',
  default: {
    id: '',
    siteId: '',
    surveySvrTemplateId: '',
    title: '',
    template: [
      {
        seq: 0,
        type: '',
        isRequired: false,
        question: '',
        options: null,
        config: {
          questionName: '',
        },
        subQuestions: null,
      },
    ],
    useSurveyCount: 0,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
});

export const surveyAnswerRegisterState = atom<SurveyAnswerProps>({
  key: 'surveyAnswerRegisterState',
  default: {
    answers: [
      {
        seq: 0,
        type: 'NONE',
        isRequired: false,
        question: '',
        questionId: '',
        options: null,
        config: {
          questionName: '',
        },
        answer: '',
        subQuestions: null,
      },
    ],
  },
});
