import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import moment from 'moment';
// @ts-ignore
import {VideoJsPlayer} from 'video.js';

import SampleThumbnail from '@/assets/img/sample-02.png';
import {ReactComponent as DownloadIcon} from '@/assets/newImg/download.svg';
import {BoardLayout, NewPrimaryButton} from '@/components/common';
import {useChapter} from '@/hooks/useChapter';
import VideoJS from '@/pages/new-lecture/components/Video';
import {
  savePLayTimeHistoryService,
  searchChapterService,
} from '@/services/chapterService';
import {Chapter} from '@/types/chapter.type';
import {Attachment} from '@/types/common.type';
import {getEndpoint} from '@/utils/env';

export function NewLecture() {
  const navigate = useNavigate();
  const location = useLocation();

  const {courseId: rawCourseId} = useParams<{courseId?: string}>();
  const courseId = rawCourseId ?? '"';
  const {chapterList, isLoading} = useChapter(courseId);
  const [activeChapterList, setActiveChapterList] = useState<Chapter[]>();

  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);

  // Get now chapter id from query parameter in URL
  const params = new URLSearchParams(location.search);
  const chapterId = params.get('chapter') || '';
  const [streamKey, setStreamKey] = useState<string>('');
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (!chapterId || !chapterList) {
      return;
    }
    const chapter = chapterList.find(item => item.id === chapterId);
    setSelectedChapter(chapter || null);
  }, [chapterId, chapterList]);
  const getStreamKey = async () => {
    if (!chapterId) {
      return;
    }
    try {
      const chapter = await searchChapterService(chapterId);
      setStreamKey(chapter.streamKey);
    } catch (e) {
      // console.log(e)
    }
  };

  useEffect(() => {
    (async () => {
      await getStreamKey();
    })();
  }, [chapterId]);

  const playTimeHandler = async () => {
    if (!playerRef.current) {
      return;
    }
    const currentTime = playerRef.current?.currentTime();
    const eventType = playerRef.current?.ended()
      ? 'FINISH'
      : playerRef.current?.paused()
      ? 'PAUSE'
      : 'PLAY';
    await savePLayTimeHistoryService(chapterId, eventType, currentTime).catch(
      error => {
        console.log(error);
      },
    );
  };

  function handleChapterClick(clickedChapter: Chapter) {
    if (clickedChapter && clickedChapter.url.endsWith('.html')) {
      if (playerRef.current) {
        playerRef.current = null;
      }
    }
    navigate(`/lecture/${courseId}?chapter=${clickedChapter.id}`);
  }

  const handlePlayerReady = (player: VideoJsPlayer, item: Chapter) => {
    playerRef.current = player;
    if (!playerRef.current) return;
    player.on('xhr-hooks-ready', () => {
      const playerRequestHook = (options: any) => {
        return {
          ...options,
          headers: {
            ...options.headers,
            'Request-Time': moment().format('X'),
            'Stream-Key': `${streamKey}`,
            'Chapter-Id': `${chapterId}`,
            Authorization: `Bearer ${localStorage.getItem('at')}`,
          },
        };
      };
      player?.tech().vhs.xhr.onRequest(playerRequestHook);
    });

    const videoSourceType = item.url.endsWith('.mp4')
      ? 'video/mp4'
      : 'application/x-mpegURL';

    player.src({
      src: item.url,
      type: videoSourceType,
      headers: {
        'Request-Time': moment().format('X'),
        'Stream-Key': `${streamKey}`,
        'Chapter-Id': `${chapterId}`,
        Authorization: `Bearer ${localStorage.getItem('at')}`,
      },
    });
    player.on('timeupdate', async () => {
      await playTimeHandler();
    });
  };

  useEffect(() => {
    if (!courseId) {
      alert(
        '일시적인 오류가 발생했습니다. 새로고침을 진행해도 오류가 발생하면 관리자에게 문의해주세요.',
      );
      navigate('/course');
    }
  }, [courseId]);

  useEffect(() => {
    if (!chapterList || chapterList.length === 0) return;

    const activeChapterListData = chapterList.filter(
      (item: Chapter) => item.status === 'ACTIVE',
    );
    setActiveChapterList(activeChapterListData);
  }, [chapterList]);

  useEffect(() => {
    if (!selectedChapter || !streamKey || !playerRef.current) {
      return;
    }
    if (selectedChapter.url.endsWith('.html')) {
      return;
    }
    playerRef.current?.off('timeupdate');
    playerRef.current?.off('xhr-hooks-ready');
    playerRef.current?.ready(() => {
      handlePlayerReady(playerRef.current, selectedChapter);
    });
  }, [selectedChapter, streamKey, playerRef]);

  const renderContent = (item: Chapter) => {
    if (item.url.endsWith('.html')) {
      const htmlUrl =
        item.url +
        `?streamKey=${streamKey}&token=${
          localStorage.getItem('at') || ''
        }&chapterId=${item.id}&callback_url=${getEndpoint()}`;
      return (
        <div
          className={'w-full flex flex-col items-center'}
          style={{
            height: '100%',
          }}>
          <iframe
            style={{
              width: '1024px',
              height: '576px',
            }}
            src={htmlUrl}
            title="Korean Content"
            className="full-size-iframe"
            allow={'autoplay; fullscreen; encrypted-media;'}></iframe>
        </div>
      );
    } else if (
      item.url.endsWith('.m3u8') ||
      item.url.endsWith('.mp4') ||
      item.url.endsWith('.ts')
    ) {
      const videoSourceType = item.url.endsWith('.mp4')
          ? 'video/mp4'
          : 'application/x-mpegURL';
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: item.url,
            type: videoSourceType,
            headers: {
              'Request-Time': moment().format('X'),
              'Stream-Key': `${streamKey}`,
              'Chapter-Id': `${chapterId}`,
              Authorization: `Bearer ${localStorage.getItem('at')}`,
            },
          },
        ],
      };
      return (
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          item={item}
        />
      );
    } else {
      return <>강의 주소가 잘못되었습니다.</>;
    }
  };

  function handleDownload(attachments: Attachment[]) {
    const fileUrl = attachments[0]?.fileUrl ?? '';
    if (!fileUrl) {
      return;
    }
    window.location.assign(fileUrl);
  }

  return (
    <BoardLayout
      boardName="교육수강"
      boardType="course"
      boardDetailName="세부강의"
      boardDetailUrl={`/course/${courseId}`}
      bgWhite
      isLecture>
      <div className="flex flex-col justify-center mb-[100px]">
        <div className="flex justify-between mt-[56px] mb-[30px]">
          {/* 강의 영상 */}
          <div className="flex flex-col w-[880px] cursor-default course-shadow">
            {selectedChapter && (
              <div className="w-full flex flex-col items-center">
                {/* selected */}
                <div
                  className="cursor-default w-[100%]"
                  onClick={e => {
                    e.stopPropagation();
                  }}>
                  {renderContent(selectedChapter)}
                </div>
                <div className="w-full flex flex-col ">
                  <div className="pt-[17px] pb-[33px] pl-[22px] pr-[30px] mb-[73px]">
                    <h2 className="text-[22px] font-bold break-keep text-[#4d4d4d]">
                      {/*{`${index + 1}. ${item.name}`}*/}
                      {selectedChapter.name}
                    </h2>
                  </div>

                  <div className="w-full flex items-center bg-[#f2f2f2] px-[32px] py-[17px] text-[#737373]">
                    <span className="mr-[10px] text-lg font-bold">
                      강의자료
                    </span>
                    {selectedChapter.attachments.length > 0 ? (
                      <div className="flex items-center cursor-pointer">
                        <span className="mr-[10px] text-base font-medium">
                          {selectedChapter.attachments[0].fileName}
                        </span>
                        <DownloadIcon
                          width={24}
                          height={24}
                          fill="#337ccf"
                          onClick={() =>
                            handleDownload(selectedChapter.attachments)
                          }
                        />
                      </div>
                    ) : (
                      <span className=" text-base font-medium">없음</span>
                    )}
                  </div>
                </div>
                {!selectedChapter.url ? (
                  <p className={'w-full flex-start pb-4 px-8'}>
                    등록된 강의가 없습니다.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
            {chapterList && chapterList.length === 0 ? (
              <div className="w-full flex justify-center p-4 text-xl md:text-2xl  ">
                등록된 강의가 없습니다.
              </div>
            ) : (
              ''
            )}
          </div>

          {/* 오른쪽 - 강의 리스트 */}
          <div className="w-[280px]">
            <div className=" flex flex-col bg-white course-shadow cursor-pointer">
              {!isLoading &&
                activeChapterList &&
                activeChapterList.map((item: Chapter, index: number) => (
                  <div key={item.id} onClick={() => handleChapterClick(item)}>
                    <div
                      className={`grid grid-cols-2 items-center h-[100px] px-5 py-[17px] gap-2.5 hover:bg-[#f2f2f2] ${
                        item.chapterId === chapterId ? 'bg-[#f2f2f2]' : ''
                      }`}>
                      <div className="flex items-center bg-white w-[119px] h-[66px] border border-transparent rounded-[10px] overflow-hidden">
                        <img
                          src={item.thumbnailUrl ?? SampleThumbnail}
                          alt={''}
                          className="w-full object-cover"
                        />
                      </div>
                      <div className=" font-bold text-xs text-[#4d4d4d]">
                        <div className="flex">
                          <div className="flex pt-[0.1rem]">
                            {index + 1}.&nbsp;
                          </div>
                          <div className="flex flex-col gap-[6px]">
                            <div className="course-list-title-style leading-relaxed">
                              {`${item.name}afdfaffadf asdfad fadfaafad`}
                            </div>
                            <div className="text-[#337ccf]">
                              {item.progressRate}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="w-full text-center">
          <NewPrimaryButton
            text={'목록으로'}
            button={'primary'}
            onClick={() => navigate(`/course/${courseId}`)}
          />
        </div>
      </div>
    </BoardLayout>
  );
}
