import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import applyState from '@/stores/application';
import {ApplicationFormProps, optionsProps} from '@/types/application.type';

function InputLikertScale({
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentValue = upperQuestion
    ? answerRegisterState.answers
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState.answers.find(({seq}) => seq === index);

  const updateAnswer = (selectedOption: optionsProps | null) => {
    setAnswerRegisterState(prev => {
      const newAnswers = prev.answers.map(item => {
        if (currentValue?.seq === item.seq && !upperQuestion) {
          return {
            ...item,
            answer: selectedOption || null,
          };
        } else if (item.seq === upperQuestion?.seq) {
          return {
            ...item,
            subQuestions: (item.subQuestions || []).map(subItem => {
              if (subItem.seq === index) {
                return {
                  ...subItem,
                  answer: selectedOption || null,
                };
              }
              return subItem;
            }),
          };
        }
        return item;
      });
      return {...prev, answers: newAnswers};
    });
  };

  useEffect(() => {
    if (currentValue?.answer?.value !== undefined && selectedItem === null) {
      const optionIndex = currentValue.options?.findIndex(
        option => option.value === currentValue.answer.value,
      );
      if (optionIndex !== undefined && optionIndex !== -1) {
        setSelectedItem(optionIndex);
      }
    }
  }, [currentValue]);

  useEffect(() => {
    if (selectedItem !== null && currentValue) {
      const selectedOption: optionsProps | undefined =
        currentValue.options?.[selectedItem];
      updateAnswer(selectedOption || null);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!answerRegisterState || questionIndex === undefined || !currentValue) {
      return;
    }

    const upperAnswerValues =
      answerRegisterState.answers?.[questionIndex]?.answer;
    const ifConditionAnswerKeys = currentValue?.config?.ifConditionAnswerKey;

    const isConditionMatched = Array.isArray(upperAnswerValues) // disabled 여부 판단
      ? ifConditionAnswerKeys?.some((key: string) =>
          upperAnswerValues.find((ans: optionsProps) => ans.key === key),
        )
      : false;

    setDisabled(!isConditionMatched);

    if (!isConditionMatched) {
      setSelectedItem(null);
      updateAnswer(null);
    } else if (selectedItem !== null) {
      const selectedOption: optionsProps | undefined =
        currentValue.options?.[selectedItem];
      updateAnswer(selectedOption || null);
    }
  }, [answerRegisterState.answers?.[questionIndex || 0]?.answer]);

  return (
    <div className="w-full mb-4">
      {currentValue?.options?.map((option, optionIndex) => {
        const isSelected = selectedItem === optionIndex;
        const borderColorClass =
          isSelected && !disabled ? 'border-2 border-[#191D88]' : 'border';
        return (
          <div
            key={optionIndex}
            className={`flex rounded-xl p-4 my-2 ${borderColorClass}`}
            onClick={() => !disabled && setSelectedItem(optionIndex)}>
            <div
              className={`mr-4 ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              {Number(option.value) + 1}
            </div>
            <div
              className={`${disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'}`}>
              {option.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default InputLikertScale;
