import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {
  reqGenKacwPayUrl,
  reqGenPayUrl,
  reqKacwPayResults,
  reqPayResults,
} from '@/api/siteApi';
import {NewLayout} from '@/components/common';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import siteState from '@/stores/site';

export function PayResults() {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const siteInfo = useRecoilValue(siteState.siteInfoState);
  const siteId = siteInfo?.id;
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const host = window.location.host.split('.')[0];

  // 페이지 새로고침 시
  useEffect(() => {
    generateImpayUrl();
  }, []);

  const generateImpayUrl = async () => {
    queryParams = new URLSearchParams(location.search);
    if (location.search.length > 0) {
      // 결제 결과 처리
      await payCallback();
    } else {
      // 결제 실행
      if (!user.user || user.user?.id === '') {
        navigate(`/sign-in?redirect=${location.pathname}`);
        return;
      }
      try {
        const response =
          host === 'kacw' || host === 'r-dev'
            ? await reqGenKacwPayUrl(user.user.id, siteId)
            : await reqGenPayUrl(user.user.id, siteId);
        window.location.href = response.data.url;
      } catch (e: any) {
        openModal({
          type: 'alert',
          title: '수강권 결제',
          message:
              e.message ||
            '결제를 실패했습니다. 수강 신청 내역을 확인해주세요.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                navigate('/register');
                closeModal();
              },
            },
          ],
        });
      }
    }
  };

  const payCallback = async () => {
    queryParams = new URLSearchParams(location.search);
    const params = {
      msg1: queryParams.get('msg1') || '',
      msg2: queryParams.get('msg2') || '',
      price: queryParams.get('price') || '',
      product: queryParams.get('product') || '',
      result: queryParams.get('result') || '',
      txid: queryParams.get('txid') || '',
      userId: queryParams.get('userId') || '',
      username: queryParams.get('username') || '',
    };
    try {
      const response =
        host === 'kacw' || host === 'r-dev'
          ? await reqKacwPayResults(params)
          : await reqPayResults(params);
      if (response.data?.result) {
        // 성공 모달 띄우기
        openModal({
          type: 'alert',
          message: '결제에 성공했습니다.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                navigate('/register');
                closeModal();
              },
            },
          ],
        });
        return;
      } else if (response.data.error.message) {
        //   실패 모달 띄우기
        openModal({
          type: 'error',
          message: response.data.error.message,
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                navigate('/');
                closeModal();
              },
            },
          ],
        });
        return;
      } else {
        //   실패 모달 띄우기
        openModal({
          type: 'error',
          message: '결제를 실패하였습니다. ',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                navigate('/');
                closeModal();
              },
            },
          ],
        });
      }
    } catch (e: any) {
      //   실패 모달 띄우기
      openModal({
        type: 'error',
        message: e.data.error.message ?? '결제를 실패하였습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              navigate('/');
              closeModal();
            },
          },
        ],
      });
    }
  };

  return (
    <NewLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">수강권 결제</h1>
      </div>

      <div className="mt-10 flex justify-center w-full px-4 my-4 pb-2  ">
        <h1 className="text-2xl font-bold">결제 대기 중입니다...</h1>
      </div>
    </NewLayout>
  );
}
