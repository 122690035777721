import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import applyState from '@/stores/application';
import {ApplicationFormProps} from '@/types/application.type';

function InputTitle({
  label,
  labelStyle,
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  label?: string;
  labelStyle?: React.CSSProperties;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );

  const currentAnswer = upperQuestion
    ? answerRegisterState?.answers
        ?.find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState?.answers?.find(({seq}) => seq === index);

  const [disabled, setDisabled] = useState<boolean>(() => {
    if (
      questionIndex !== undefined &&
      currentAnswer?.config?.ifConditionAnswerKey &&
      answerRegisterState?.answers?.[questionIndex]?.answer?.[0]?.key
    ) {
      return !currentAnswer.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers[questionIndex].answer[0].key,
      );
    }
    return false;
  });

  const calculateLeftSize = (index: number) => {
    const question = currentAnswer?.question || '';
    const questionLength = question.length * 16;
    return `${questionLength}px`;
  };

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    const answerKey =
      answerRegisterState.answers?.[questionIndex]?.answer?.[0]?.key;

    if (
      answerKey &&
      currentAnswer?.config?.ifConditionAnswerKey?.includes(answerKey)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [answerRegisterState?.answers, questionIndex, currentAnswer]);

  return (
    <div className={'w-full relative'}>
      {!upperQuestion ? (
        <div className={'mb-3'}>
          <label className={'font-semibold '}>{index + 1}번 항목</label>
        </div>
      ) : (
        <div className={'mb-2'}>
          <label
            className={`text-18 font-semibold ${
              disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
            }`}>
            {upperQuestion?.seq + 1}-{index + 1}번 항목 (
            {upperQuestion?.seq + 1}번 항목 답변 시 활성화)
          </label>
        </div>
      )}

      <div
        className={`w-full ${
          label && label.length > 50 ? 'flex-col' : 'flex'
        }`}>
        {label &&
          (label.length > 50 ? (
            <textarea
              disabled={true}
              className={`ml-2 text-18 font-medium w-full ${
                label.length > 50 ? 'h-[150px] ' : 'h-[50px] resize-none'
              } border-none readonly ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}
              value={label}></textarea>
          ) : (
            <label
              className={`ml-2 text-18 font-medium w-fit ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              {label}
            </label>
          ))}
        <div className={'flex'}>
          <div className={'flex'}>
            {currentAnswer?.type === 'FILE_UPLOAD' && (
              <span
                className={`pl-[2px] ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}
                style={{left: calculateLeftSize(index)}}>
                (최대 파일 수 : {currentAnswer?.config?.maxCount || 1}개)
              </span>
            )}
          </div>
          {currentAnswer?.isRequired && !disabled && (
            <div className="flex items-center">
              <span
                className=" font-medium top-[7px] text-[#FF5353] text-[11px] ml-[10px] w-fit pl-2 "
                style={{left: calculateLeftSize(index)}}>
                필수 입력 항목입니다.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InputTitle;
