import {useEffect} from 'react';
import {useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import {searchUserInfoService} from '@/services/userService';
import siteState from '@/stores/site';
import userState from '@/stores/userAtom';
import {User} from '@/types/user.type';
import {parseJwt} from '@/utils/token';

export default function useUser() {
  const site = useRecoilValue(siteState.siteInfoState);
  const user = useRecoilValue(userState);

  const {
    data: userInfo,
    error,
    refetch,
  } = useQuery(
    ['userInfo', site?.id],
    async () => {
      const accessToken = localStorage.getItem('at');
      if (!accessToken || !site?.id) return null;

      const {userId} = parseJwt(accessToken).payloadObj as {userId: string};

      return searchUserInfoService(site?.id, userId);
    },
    {
      retry: false,
      enabled: !!site?.id,
      refetchOnWindowFocus: true,
    },
  ) as {
    data: User;
    isLoading: boolean;
    error: {
      code: string;
      message: string;
      description: string;
      status: number;
    } | null;
    refetch: () => void;
  };

  useEffect(() => {
    refetch();
  }, [site?.id, refetch]);

  return {
    user: userInfo ?? user,
    userError: error,
    refetchUser: refetch,
  };
}
