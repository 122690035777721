import {useEffect, useState} from 'react';
import {HiBars3, HiXMark} from 'react-icons/hi2';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as IconAuthUser} from '@/assets/newImg/auth-user.svg';
import defaultLogo from '@/assets/newImg/logo-main.png';
import Modal from '@/components/common/Modal';
import Nav from '@/components/common/NewNav';
import useModal from '@/hooks/useModal';
import siteState from '@/stores/site';
import {clearLoginStatus} from '@/utils/common';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const siteData = useRecoilValue(siteState.siteInfoState);
  const {logo} = siteData ? siteData : {logo: defaultLogo};
  const handleToggleClick = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  function onLogout() {
    clearLoginStatus();
    setIsLoggedIn(!!localStorage.getItem('at'));
    openModal({
      title: '로그아웃',
      message: '정상적으로 로그아웃되었습니다.',
    });
  }

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('at'));
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <header>
      <Modal
        isShowing={isShowing}
        type="alert"
        title={modalData?.title}
        message={modalData?.message}
        buttons={[
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              navigate('/');
            },
          },
        ]}
      />
      <div className="flex justify-center px-auto fixed top-0 left-0 right-0 z-50 bg-white main_shadow">
        <div className="max-w-[1180px] w-full flex justify-between h-[120px]">
          {/* 헤더 좌측 */}
          <div className="flex items-center gap-[26px]">
            {/* 헤더 좌측 - 로고 */}
            <Link
              className="max-w-200 w-[200px] h-[37px] flex items-center"
              to={'/'}>
              <img src={logo} alt="logo" />
            </Link>
            {/* 헤더 좌측 - 네비게이션 영역 */}
            <Nav isMobile={false} />
          </div>
          {/* 헤더 우측 */}
          <div className="hidden md:flex md:items-center ml-5">
            {!isLoggedIn && (
              <button
                className="flex items-center text-[20px] gap-2.5"
                onClick={() => {
                  navigate('/new-sign-in');
                }}>
                <IconAuthUser width={20} height={20} fill="#337ccf" />
                <p className="text-[20px] leading-[21px] mt-[1.5px] text-[#404040] font-medium whitespace-nowrap">
                  로그인/회원가입
                </p>
              </button>
            )}
            {isLoggedIn && (
              <div className="flex items-center justify-center gap-6">
                <button
                  type="button"
                  onClick={() => navigate('/user')}
                  className={`flex items-center max-h-[50px] gap-2.5 px-[19px] py-[14px] border rounded-[30px] transition-all ease-in-out delay-50 bg-primary text-xl text-white whitespace-nowrap cursor-pointer hover:bg-[#2963A6]`}>
                  <IconAuthUser width={20} height={20} fill="white" />
                  <p className="text-[20px] leading-[21px] font-bold">
                    마이페이지
                  </p>
                </button>
                <button
                  className="text-[20px] leading-[21px] mt-[1.5px] text-[#808080] font-medium whitespace-nowrap"
                  onClick={onLogout}>
                  로그아웃
                </button>
              </div>
            )}
          </div>
          {/* 햄버거 메뉴 토글 버튼 */}
          <button
            onClick={handleToggleClick}
            className="cursor-pointer md:hidden block">
            {isMobileMenuOpen ? (
              <HiXMark name="menu" className="text-[32px]" />
            ) : (
              <HiBars3 name="menu" className="text-[32px]" />
            )}
          </button>
        </div>
      </div>
      <div className="h-[120px]"></div>
      {/* 토글 오픈 시 보이는 메뉴 */}
      {isMobileMenuOpen && (
        <Nav isMobile={true} setIsMobileMenuOpen={setIsMobileMenuOpen} />
      )}
    </header>
  );
}
