import React, {useEffect} from 'react';
import {useNavigate, useParams, useLocation, Link} from 'react-router-dom';

import {ReactComponent as DownloadIcon} from '@/assets/newImg/download.svg';
import {BoardLayout, NewPrimaryButton, Skeleton} from '@/components/common';
import Divider from '@/components/common/Divider';
import {useBoardDetailWithComment} from '@/hooks/useBoards';
import {BoardList} from '@/types/boards.type';
import {replaceDateFormat} from '@/utils/format';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

type ParamsType = {
  item: BoardList;
  prevItem: BoardList;
  nextItem: BoardList;
};

export function NewFAQDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    item: noticeItem,
    prevItem,
    nextItem,
  } = (location.state as ParamsType) || {
    item: undefined,
    prevItem: undefined,
    nextItem: undefined,
  };
  const {id: faqId} = useParams<{id: string}>();

  const {boardDetailData, commentData, isLoading, refetch} =
    useBoardDetailWithComment({
      boardId: faqId,
    });

  const handleOnClickAdjacentFaq = (query: string) => {
    if (!query) {
      navigate('/question');
      return;
    }
    navigate(`/question/${query}`);
  };

  useEffect(() => {
    // 페이지 맨 위로
    window.scrollTo(0, 0);
    if (!faqId) {
      navigate('/question');
      return;
    }
    if (!noticeItem) {
      refetch().then();
      return;
    }
  }, [faqId]);

  return (
    <BoardLayout
      boardType="question/service"
      boardName="FAQ"
      boardDetailName="게시글"
      bgWhite>
      <div className="my-[60px]">
        {isLoading && <Skeleton />}
        {boardDetailData && (
          <>
            {/* 제목, 정보 */}
            <Divider />
            <div className="px-[30px]">
              <div className="w-full py-7">
                <h3 className="text-3xl font-bold text-neutral-700">
                  {boardDetailData.title}
                </h3>
              </div>
              <div className="w-full flex items-center mb-8 gap-5 text-neutral-500">
                <div className="flex items-center">
                  <p className="font-bold text-lg mr-2">등록일</p>
                  <p className="font-medium text-base">
                    {replaceDateFormat(boardDetailData.createdAt, 'YYYY-MM-DD')}
                  </p>
                </div>
                {/*<div className="flex items-center">*/}
                {/*  <p className="font-bold text-lg mr-[10px]">조회수</p>*/}
                {/*  <p className="font-medium text-base">92</p>*/}
                {/*</div>*/}
              </div>
            </div>
            {/* 첨부파일 */}
            <div className="flex items-center px-[30px] h-[60px] bg-[#f2f2f2] gap-2.5 text-neutral-500">
              <span className="font-bold text-lg">첨부파일</span>
              {boardDetailData.attachments.length > 0 ? (
                <div
                  className="flex items-center gap-2.5 cursor-pointer"
                  onClick={() => {
                    window.open(
                      boardDetailData.attachments[0].fileUrl.split('?')[0],
                      '_blank',
                      'noopener noreferrer',
                    );
                  }}>
                  <span className="font-medium text-base">
                    {boardDetailData.attachments[0].fileName}
                  </span>
                  <DownloadIcon width={24} height={24} fill="#337ccf" />
                </div>
              ) : (
                <span className="text-base font-medium">없음</span>
              )}
            </div>
            <Divider />
            {/* 본문 */}
            <div className="px-[35px] pt-[30px] pb-[44px]">
              {parseHtmlContentWithSanitize(boardDetailData.content)}
            </div>
            <Divider />
            {/* 답글 */}
            {commentData &&
              commentData.content.length > 0 &&
              commentData.content.map((item: BoardList, index: number) => (
                <React.Fragment key={index}>
                  <div>
                    <div className="w-full flex flex-col px-[30px] bg-[#FBFBFB]">
                      <h3 className="text-3xl font-bold py-7">답변</h3>
                      <div className="w-full flex items-center px-[2px] pt-[2px] pb-[40px] gap-5 text-[#737373]">
                        <div className="flex items-center ">
                          <p className="font-bold text-lg mr-2">등록일</p>
                          <p className="text-base font-medium">
                            {replaceDateFormat(item.createdAt, 'YYYY-MM-DD')}
                          </p>
                        </div>
                        <div className="flex items-center ">
                          <p className="font-bold text-lg mr-2">답변자</p>
                          <p className="text-base font-medium">
                            {item.author.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="px-[35px] pt-[30px] pb-[44px]">
                      {parseHtmlContentWithSanitize(item?.content)}
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              ))}
            {(prevItem || nextItem) && (
              <>
                {/* 이전글 */}
                <div className="flex items-center px-[35px] py-[17px] cursor-pointer hover:bg-readOnly">
                  <p className="font-bold text-lg text-neutral-500 mr-[27px] whitespace-nowrap">
                    이전글
                  </p>
                  <p
                    className="text-lg font-medium text-[#808080]"
                    onClick={() => {
                      handleOnClickAdjacentFaq(prevItem?.id ?? '');
                    }}>
                    {prevItem?.title ?? '이전글이 없습니다.'}
                  </p>
                </div>
                <Divider />
                {/* 다음글 */}

                <div className="flex items-center px-[35px] py-[17px] cursor-pointer hover:bg-readOnly">
                  <p className="font-bold text-lg text-neutral-500 mr-[27px] whitespace-nowrap">
                    다음글
                  </p>
                  <p
                    className="text-lg font-medium text-[#808080]"
                    onClick={() => {
                      handleOnClickAdjacentFaq(nextItem?.id ?? '');
                    }}>
                    {nextItem?.title ?? '다음글이 없습니다.'}
                  </p>
                </div>
                <Divider />
              </>
            )}
            {/* 목록으로 */}
            <div className="text-center py-[34px]">
              <Link to={'/question'}>
                <NewPrimaryButton
                  addClass="w-[162px]"
                  text={'목록으로'}
                  button={'primary'}
                />
              </Link>
            </div>
          </>
        )}
      </div>
      {!isLoading && !boardDetailData && (
        <div>잘못된 요청이거나 오류가 발생했습니다.</div>
      )}
    </BoardLayout>
  );
}
