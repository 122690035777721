import moment from 'moment';

import {
  reqChangeUserInfo,
  reqDeleteUser,
  reqFindEmail,
  reqGetUserInfo,
  reqIsDuplicatedEmail,
  reqPostSignIn,
  reqResetPassword,
  reqSendEmailCode,
  reqSendSmsCode,
  reqSignUp,
  reqValidateAuth,
  reqValidateEmailCode,
  reqValidateSmsCode,
} from '@/api/userApi';
import {ChangeUserInfo, Role} from '@/types/user.type';
import {pick} from '@/utils/common';

export const DefaultUserRole: Role = {
  isSystemManager: false,
  isSiteManager: false,
  isAcademyManager: false,
  isUserManager: false,
  isCourseManager: false,
};

export async function signInService(
  siteId: string,
  email: string,
  password: string,
) {
  try {
    const response = await reqPostSignIn(siteId, email, password);

    const accessToken = response.data.data.accessToken;
    const refreshToken = response.data.data.refreshToken;

    localStorage.setItem('at', accessToken);
    localStorage.setItem('rt', refreshToken);

    return true;
  } catch (e: any) {
    return e;
  }
}

export async function searchUserInfoService(siteId: string, userId: string) {
  const accessToken = localStorage.getItem('at');
  if (!accessToken) return null;

  try {
    const response = await reqGetUserInfo(siteId, userId);
    return pick(response.data.data, ['id', 'name', 'email', 'phone', 'status']);
  } catch (e: any) {
    throw e.data.error;
  }
}

export async function checkIsDuplicatedEmailService(
  siteId: string,
  email: string,
): Promise<boolean> {
  const response = await reqIsDuplicatedEmail(siteId, email);
  return response.data.data.result;
}

export async function validateAuthService(
  siteId: string,
  phone: string,
  telecom: string, // KT, SKT
  birthDate: string,
  name: string,
  gender: string,
): Promise<boolean> {
  const response = await reqValidateAuth({
    siteId,
    phone: phone.replace(/-/g, ''),
    telecom,
    birthDate,
    name,
    gender,
  });
  return response.data.data.result;
}

export async function requestSmsCodeService(
  siteId: string,
  type: string,
  target: string,
  user?: string,
): Promise<boolean> {
  await reqSendSmsCode(siteId, type, target, user);
  return true;
}

export async function validateSmsCodeService(
  siteId: string,
  type: string,
  target: string,
  code: string,
  user?: string,
) {
  const res = await reqValidateSmsCode(siteId, type, target, code, user);
  return res.data.data.authId;
}

export async function requestEmailCodeService(
  siteId: string,
  type: string,
  target: string,
  user?: string,
): Promise<boolean> {
  await reqSendEmailCode(siteId, type, target, user);
  return true;
}

interface SignUpServiceParams {
  authId: string;
  siteId: string;
  name: string;
  gender?: string;
  birthDate?: string;
  email: string;
  phone: string;
  password: string;
}

export async function signUpService(
  data: SignUpServiceParams,
): Promise<boolean> {
  const privacyPolicy = moment().format('YYYY-MM-DD-HH:mm');
  const servicePolicy = moment().format('YYYY-MM-DD-HH:mm');
  const thirdPartySharePolicy = moment().format('YYYY-MM-DD-HH:mm');

  const signUpData = {
    ...data,
    privacyPolicy,
    servicePolicy,
    thirdPartySharePolicy,
  };

  await reqSignUp(signUpData);

  return true;
}

export async function validateEmailCodeService(
  siteId: string,
  type: string,
  target: string,
  code: string,
  user?: string,
): Promise<string> {
  const response = await reqValidateEmailCode(siteId, type, target, code, user);

  return response.data.data.authId;
}

export async function resetPasswordService(
  siteId: string,
  email: string,
  newPassword: string,
  authId: string,
): Promise<boolean> {
  await reqResetPassword(siteId, email, newPassword, authId);
  return true;
}

export async function findEmailService(
  siteId: string,
  phone: string,
  authId: string,
): Promise<string> {
  const response = await reqFindEmail(siteId, phone, authId);
  return response.data.data.email;
}

export async function changeUserInfoService(
  userId: string,
  changeInfo: ChangeUserInfo,
): Promise<boolean> {
  const response = await reqChangeUserInfo(userId, changeInfo);
  return response.data.data.result;
}

export async function deleteUserService(password: string, userId: string) {
  const response = await reqDeleteUser(password, userId);
  return response.data.data;
}
