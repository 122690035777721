import {HiOutlineChevronRight} from 'react-icons/hi';
import {Link} from 'react-router-dom';

import {ReactComponent as ArrowThinIcon} from '@/assets/newImg/arrow-right-thin-s.svg';
import {ReactComponent as HomeIcon} from '@/assets/newImg/home-default.svg';
import NewFooter from '@/components/common/NewFooter';
import NewHeader from '@/components/common/NewHeader';
import {LayoutProps} from '@/types/common.type';

export function NewLayout({children, isHiddenFooter, bgWhite}: LayoutProps) {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex justify-center w-full ${
          bgWhite ? 'bg-white' : 'bg-[#fbfbfb]'
        }`}>
        <div className="relative flex flex-col w-full max-w-[1180px] mx-auto min-w-[360px]">
          <NewHeader />
          {children}
        </div>
      </div>
      {!isHiddenFooter && <NewFooter />}
    </div>
  );
}

export function FormLayout({bgWhite, children}: LayoutProps) {
  return (
    <div
      className={`flex justify-center px-auto ${
        bgWhite ? 'bg-white' : 'bg-[#fbfbfb]'
      }`}>
      <div className="max-w-[1180px] w-[590px] mt-[77px] mb-[108px]">
        <div className="py-[46px] px-[70px] flex flex-col bg-white border-gray-200 rounded-[30px] course-shadow">
          {children}
        </div>
      </div>
    </div>
  );
}

export function BoardLayout({
  children,
  isHiddenFooter,
  boardType,
  boardName,
  boardDetailName,
  boardDetailUrl,
  isLecture,
  bgWhite,
}: LayoutProps) {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-col justify-center w-full ${
          bgWhite ? 'bg-white' : 'bg-[#fbfbfb]'
        }`}>
        <div className="relative flex flex-col w-full max-w-[1180px] mx-auto min-w-[360px]">
          <NewHeader />
        </div>

        <div className="w-full h-[160px] flex items-center board-background-style">
          <div className="flex items-center w-full max-w-[1180px] mx-auto min-w-[360px]">
            <h3 className="text-[40px] font-bold text-white cursor-default">
              {boardName}
            </h3>
          </div>
        </div>
        <div className="w-full h-[50px] bg-[#f2f2f2]">
          <div className="h-full flex items-center text-[15px] font-semibold text-gray-400 w-full max-w-[1180px] mx-auto min-w-[360px]">
            <div className="cursor-pointer flex items-center">
              <Link to={'/'}>
                <HomeIcon
                  width={15}
                  height={15}
                  fill="#999999"
                  className="mr-[11px]"
                />
              </Link>
              <ArrowThinIcon
                width={15}
                height={15}
                fill="#999999"
                className="mr-[9px]"
              />
              <Link to={`/${boardType}`}>
                <p>{boardName}</p>
              </Link>
              {boardDetailName && (
                <>
                  <HiOutlineChevronRight className="ml-[9px]" />
                  {boardDetailUrl ? (
                    <Link to={boardDetailUrl}>
                      <span className="ml-[9px]">{boardDetailName}</span>
                    </Link>
                  ) : (
                    <span className="ml-[9px]">{boardDetailName}</span>
                  )}
                </>
              )}
              {isLecture && (
                <>
                  <HiOutlineChevronRight className="ml-[9px]" />
                  <span className="ml-[9px]">{'강의영상'}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="relative flex flex-col w-full max-w-[1180px] mx-auto min-w-[360px]">
          {children}
        </div>
      </div>
      {!isHiddenFooter && <NewFooter />}
    </div>
  );
}
