import React, {
  ReactNode,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import {useRecoilState, useRecoilValue} from 'recoil';

import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  ApplicationFormProps,
  configProps,
} from '@/types/application.type';
import {EMAIL_REGEX, NUMBER_REGEX} from '@/utils/regex';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  rightButton?: ReactNode;
  caption?: ReactNode;
  labelStyle?: React.CSSProperties;
  addClass?: string;
  type?: string;
  config?: configProps;
  index: number;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}

const calcHeight = (type: string | undefined) => {
  if (type === 'SHORT_TEXT') {
    return '60px';
  } else if (type === 'LONG_TEXT') {
    return '200px';
  } else {
    return '60px';
  }
};

function InputText({
  label,
  leftIcon,
  rightIcon,
  rightButton,
  labelStyle,
  addClass,
  type,
  config,
  index,
  upperQuestion,
  questionIndex,
  ...props
}: InputProps) {
  const applyRegisterState = useRecoilValue(applyState.applyRegisterState);

  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );

  const [disabled, setDisabled] = useState<boolean>(false);

  const [matchedRegex, setMatchedRegex] = useState<string>('');
  const [fitLength, setFitLength] = useState<string>('');

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  const minHeight = calcHeight(type);
  const handleOnChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (
      currentQuestion?.config.minLength &&
      e.target.value !== '' &&
      e.target.value.length < currentQuestion?.config?.minLength
    ) {
      setFitLength(
        `* 최소 ${currentQuestion?.config?.minLength}자 이상 입력해주세요.`,
      );
    } else {
      setFitLength('');
    }
    setAnswerRegisterState((prev: AnswerFormProps) => {
      const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
        index === item.seq && !upperQuestion
          ? {
              ...item,
              answer: e.target.value,
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: e.target.value,
                    }
                  : subItem,
              ),
            }
          : item,
      );
      return {
        ...prev,
        answers: newAnswerRegisterState,
      };
    });
  };

  const handleOnChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      currentQuestion?.config.regex &&
      currentQuestion.config.regex === 'NUMBER' &&
      !NUMBER_REGEX.test(e.target.value) &&
      e.target.value !== ''
    ) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }

    if (currentQuestion?.config.regex) {
      if (
        currentQuestion.config.regex === 'NUMBER' &&
        !NUMBER_REGEX.test(e.target.value) &&
        e.target.value !== ''
      ) {
        setMatchedRegex('* 숫자 형식이 일치하지 않습니다.');
      } else if (
        currentQuestion.config.regex === 'EMAIL' &&
        !EMAIL_REGEX.test(e.target.value) &&
        e.target.value !== ''
      ) {
        setMatchedRegex('* 이메일 형식이 일치하지 않습니다.');
      } else if (
        currentQuestion.config.regex === 'NUMBER' &&
        NUMBER_REGEX.test(e.target.value) &&
        e.target.value !== ''
      ) {
        setMatchedRegex('* 숫자 형식이 일치합니다.');
      } else if (
        currentQuestion.config.regex === 'EMAIL' &&
        EMAIL_REGEX.test(e.target.value) &&
        e.target.value !== ''
      ) {
        setMatchedRegex('* 이메일 형식이 일치합니다.');
      } else {
        setMatchedRegex('');
      }
    }

    if (
      currentQuestion?.config.minLength &&
      e.target.value !== '' &&
      e.target.value.length < currentQuestion?.config?.minLength
    ) {
      setFitLength(
        `* 최소 ${currentQuestion?.config?.minLength}자 이상 입력해주세요.`,
      );
    } else {
      setFitLength('');
    }

    // if(currentQuestion?.config.regex){
    //   if(currentQuestion.config.regex == 'NUMBER' && !NUMBER_REGEX.test(e.target.value)){
    //
    //   }
    // }

    setAnswerRegisterState(prev => {
      const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
        index === item.seq && !upperQuestion
          ? {
              ...item,
              answer: e.target.value,
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: e.target.value,
                    }
                  : subItem,
              ),
            }
          : item,
      );
      return {
        ...prev,
        answers: newAnswerRegisterState,
      };
    });
  };

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      answerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setMatchedRegex('');
      setFitLength('');
      setAnswerRegisterState((prev: AnswerFormProps) => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentQuestion?.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: '',
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: '',
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);

  return (
    <div className="w-full flex flex-col mb-[10px]">
      <div className="flex-col items-center bg-white">
        <div
          className={`relative w-full px-[30px] flex items-center rounded-[10px] border border-[#e5e5e5] ${addClass} ${
            rightButton && 'mr-[20px]'
          }`}
          style={{minHeight}}>
          {type === 'LONG_TEXT' ? (
            <textarea
              placeholder={
                currentQuestion?.config?.placeHolder || '장문형 텍스트'
              }
              maxLength={currentQuestion?.config?.maxLength || undefined}
              className={`w-full min-h-[180px] p-[10px] bg-transparent placeholder-disabled appearance-none border-none placeholder:text-[16px] focus:ring-0 text-base resize-none ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              } `}
              onChange={e => {
                handleOnChangeTextArea(e);
              }}
              disabled={disabled}
              value={disabled ? '' : props.value}
            />
          ) : (
            <div className={'w-full'}>
              <input
                placeholder={
                  currentQuestion?.config?.placeHolder || '단답형 텍스트'
                }
                {...props}
                className={`w-full h-full bg-transparent text-[#404040] placeholder-disabled p-0 appearance-none border-none placeholder:text-[16px] focus:ring-0 text-base resize-none focus:outline-none ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}
                onChange={e => {
                  handleOnChangeText(e);
                }}
                disabled={disabled}
                value={disabled ? '' : props.value}
                maxLength={currentQuestion?.config?.maxLength || undefined}
                type="text"
              />
            </div>
          )}
          {rightIcon}
        </div>
        {!disabled && (
          <label
            className={`${
              matchedRegex.includes('일치합니다')
                ? 'text-primary'
                : 'text-[#FF5353]'
            } text-xs`}>
            {matchedRegex}
          </label>
        )}
        {fitLength && !disabled && (
          <label className={'text-[#FF5353] text-xs'}>{fitLength}</label>
        )}
        <div className="w-auto">{rightButton}</div>
      </div>
    </div>
  );
}

export default InputText;
