import {useState} from 'react';
import {useMutation, useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import {PAGINATION_DATA} from '@/constants/common';
import useUser from '@/hooks/useUser';
import {printCertificateService} from '@/services/certificateService';
import {
  getApplicationFormAnswer,
  getUserCourseListService,
  getXProgressService,
  searchCourseDetailService,
} from '@/services/courseService';
import siteState from '@/stores/site';
import {CourseWithProgress} from '@/types/course.type';

export const useCourseDetail = (courseId: string) => {
  const {user} = useUser();

  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: ['courseDetail', courseId, user?.id],
    queryFn: async ({queryKey}) => {
      const courseId = queryKey[1] as string;
      return await searchCourseDetailService(courseId, user?.id);
    },
  });

  return {data, isLoading: isLoading || isFetching, refetch};
};

export const useCourseGetCertificate = () => {
  const {mutateAsync, data, status, reset} = useMutation({
    mutationFn: async (courseId: string) => {
      return await printCertificateService(courseId);
    },
    onError: (error: any) => {
      throw error?.data?.error;
    },
  });

  return {mutateAsync, data: data?.data, status, reset};
};

export const useMyCourseDetail = (courseId: string) => {
  const {user} = useUser();
  const siteInfo = useRecoilValue(siteState.siteInfoState);

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['myCourseDetail', courseId, user?.id],
    queryFn: async ({queryKey}) => {
      const courseId = queryKey[1] as string;

      if (!siteInfo?.id) return;

      const myCourseResponse = await searchCourseDetailService(
        courseId,
        user?.id,
      );
      const progressResponse = await getXProgressService({
        siteId: siteInfo.id,
        xType: 'COURSE',
        courseId,
      });

      const newMyCourseContent: CourseWithProgress = {
        ...myCourseResponse,
        ...progressResponse[0],
      };
      return newMyCourseContent;
    },
  });

  return {data, isLoading, refetch};
};

export const useMyCourseList = ({
  searchQuery,
  isComplete,
}: {
  searchQuery: string | null;
  isComplete: boolean;
}) => {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 3});

  const {user} = useUser();

  function onChangePage(page: number) {
    setPageParams(prev => ({
      ...prev,
      page,
    }));
  }

  const {data, isLoading} = useQuery({
    queryKey: ['myCourseList', pageParams, user.id, isComplete, searchQuery],
    queryFn: async ({queryKey}) => {
      const [_, pageParams, uid, isComplete, searchQuery] = queryKey as [
        string,
        {page: number; pageSize: number},
        string,
        boolean,
        string,
      ];

      return getUserCourseListService({
        page: pageParams.page,
        pageSize: pageParams.pageSize,
        uid,
        isComplete,
      });
    },
    keepPreviousData: true,
  });

  return {
    data,
    isLoading,
    onChangePage,
    paginationInfo: data?.page || PAGINATION_DATA.page,
  };
};

export const useCourseApplicationFormAnswer = (surveySvrAnswerId: string) => {
  const {data, refetch} = useQuery({
    queryKey: ['applicationForms', surveySvrAnswerId],
    queryFn: async ({queryKey}) => {
      return await getApplicationFormAnswer(queryKey[1] as string);
    },
    // enabled: !!surveySvrAnswerId,
    staleTime: 0,
  });

  return {data: data || PAGINATION_DATA, refetch};
};
