import {useMemo, useRef} from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import '@/styles/react-quill-style.css';
import {uploadImageService} from '@/services/commonService';

interface BoardContentEditorProps {
  value: string;
  onChange: (v: string) => void;
}

export function BoardContentEditor({value, onChange}: BoardContentEditorProps) {
  const boardRef = useRef<ReactQuill>(null);
  const uploadImageHandler = () => {
    uploadImageService({parentType: 'BOARD'}, ({fileUrl}) => {
      const range = boardRef.current?.getEditorSelection();
      if (!range) {
        return;
      }

      boardRef.current?.getEditor().insertEmbed(range.index, 'image', fileUrl);
      boardRef.current
        ?.getEditor()
        .setSelection(range.index + 1, range.length + 1);
    });
  };

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{size: [false, 'large', 'huge']}],
          [{header: [1, 2, 3, 4, 5, false]}],
          ['bold', 'italic', 'underline', 'clean'],
          [
            {align: ''},
            {align: 'center'},
            {align: 'right'},
            {align: 'justify'},
          ],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
          [{color: []}, {background: []}],
          ['link', 'image'],
          // ['link'],
        ],
        handlers: {
          image: uploadImageHandler,
        },
      },
    }),
    [boardRef],
  );

  return (
    <ReactQuill
      ref={boardRef}
      theme={'snow'}
      value={value}
      onChange={onChange}
      modules={quillModules}
    />
  );
}
