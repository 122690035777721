import {
  reqGetBoardList,
  reqGetBoardDetail,
  reqGetComments,
  reqSiteQNA,
  reqWriteQuestion,
} from '@/api/boardApi';
import {
  ReqBoardListParams,
  QNADataParams,
  QuestionData,
} from '@/types/boards.type';

export async function getBoardList(params: ReqBoardListParams) {
  try {
    const response = await reqGetBoardList(params);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
  return;
}

export async function getBoardDetailService(boardId: string) {
  try {
    const response = await reqGetBoardDetail(boardId);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function getCommentsService({
  boardId,
  siteId,
  page = 1,
  pageSize = 10,
}: {
  boardId: string;
  siteId: string;
  page?: number;
  pageSize?: number;
}) {
  try {
    const response = await reqGetComments({boardId, siteId, page, pageSize});
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function submitQuestionService(data: QuestionData) {
  const response = await reqWriteQuestion(data);
  return response.data.data;
}

export async function sendSiteQNA(data: QNADataParams) {
  const response = await reqSiteQNA(data);
  return response.data.data;
}
