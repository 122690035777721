import {useCallback, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import NoRegisteredThumbnail from '@/assets/newImg/no-registered-thumbnail.jpg';
import {NewPrimaryButton} from '@/components/common';
import {CourseWithProgress} from '@/types/course.type';
import {getToday, replaceDateFormat} from '@/utils/format';

export function CourseInfoCard({courseInfo}: {courseInfo: CourseWithProgress}) {
  const navigate = useNavigate();
  const today = useMemo(() => getToday(), []);
  const getStatusUI = (text: string, bgColor: string) => (
    <div
      key={text}
      className={`cursor-default w-max whitespace-nowrap py-[7px] px-[19px] rounded-[30px] h-max-[40px] ${
        bgColor || 'bg-[#337CCF]'
      }`}>
      <p className="text-white text-lg font-bold">{text}</p>
    </div>
  );

  const currentCourseStatus = useCallback(() => {
    if (!courseInfo) return <p className="text-white text-xl font-bold"></p>;
    const statuses = [];

    switch (courseInfo.applicationStatus) {
      case 'ENROLLED':
      case 'WAITING':
      default:
        if (courseInfo.recruitStatus === 'N')
          statuses.push({bgColor: 'bg-[#bfbfbf]', text: '모집완료'});
        else if (courseInfo.recruitStatus === 'Y')
          statuses.push({bgColor: 'bg-[#337CCF]', text: '모집중'});
        if (today >= courseInfo.startsAt && today <= courseInfo.endsAt)
          statuses.push({bgColor: 'bg-[#FF5353]', text: '교육 진행 중'});

        return statuses.map(status => getStatusUI(status.text, status.bgColor));
    }
  }, [courseInfo]);

  return (
    <div className="max-w-[1180px] w-full relative">
      <div className="w-full h-[268px] flex border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden">
        <img
          src={courseInfo.thumbnailUrl || NoRegisteredThumbnail}
          alt="교육과정 썸네일"
          className="min-w-[432px] h-full object-cover"
        />
        <div className="relative w-full flex flex-col flex-grow px-[50px] pt-[30px]">
          <div className="flex gap-2.5">{currentCourseStatus()}</div>
          <Link to={`/new-register/${courseInfo.id}`} className="flex-grow">
            <h4 className=" font-bold text-[#191D88] my-[11px] text-[25px] break-keep course-title-style">
              {courseInfo.name}
            </h4>
          </Link>
          <div className="mb-[35px]">
            <div className="flex mb-[5px] text-[18px]">
              <span className="font-bold mr-[18px] text-[#404040]">
                교육기간
              </span>
              <p className="text-[#00000080] font-medium">
                {replaceDateFormat(courseInfo.startsAt, 'YYYY.MM.DD')} ~
                {replaceDateFormat(courseInfo.endsAt, 'YYYY.MM.DD')}
              </p>
            </div>
            <div className="flex text-[18px]">
              <span className="font-bold mr-8 text-[#404040]">수강률</span>
              <p className="text-[#00000080] font-medium">
                {courseInfo.progressRate}%
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-[30px] right-[50px]">
          {/* 신청됨 -> 신청 완료 */}
          {courseInfo.applicationStatus === 'ENROLLED' &&
            today >= courseInfo.startsAt &&
            today <= courseInfo.endsAt && (
              <NewPrimaryButton
                text="수강하기"
                addClass="w-[162px] bg-primary border-transparent text-white"
                onClick={() => navigate(`/course/${courseInfo.id}`)}
              />
            )}
          {courseInfo.applicationStatus === 'ENROLLED' &&
            today > courseInfo.endsAt && (
              <NewPrimaryButton
                text="과정종료"
                addClass="w-[162px] bg-disabled border-transparent text-white"
                button="disabled"
              />
            )}
        </div>
      </div>
    </div>
  );
}
