import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as AttachmentClipIcon} from '@/assets/newImg/attachment-clip.svg';
import {
  Pagination,
  Skeleton,
  TableRowEmpty,
  BoardLayout,
  NewInputSearch,
  NewTableRow,
  NewTableData,
  NewTableHeader,
} from '@/components/common';
import {useBoards} from '@/hooks/useBoards';
import siteState from '@/stores/site';
import {BoardList} from '@/types/boards.type';
import {genTableReverseIndex, replaceDateFormat} from '@/utils/format';

export function NewFAQ() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState.siteInfoState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');

  const {
    data: faqData,
    isLoading,
    paginationInfo,
    onChangePage,
    refetch,
  } = useBoards({
    category: 'Service',
    searchQuery,
  });

  function searchFaqList(query: string) {
    if (!query) {
      navigate('/question');
      return;
    }
    navigate(`/question?search=${query}`);
  }

  useEffect(() => {
    refetch();
  }, [siteData, searchQuery]);

  return (
    <BoardLayout boardType="question/service" boardName="FAQ" bgWhite>
      <div className="w-full flex flex-col">
        <div className="flex mt-[17px] py-[14px] gap-5">
          <button
            className="px-[34px] py-[11px] text-[25px] font-bold bg-primary text-white rounded-[30px]"
            onClick={() => navigate('/question')}>
            FAQ
          </button>
          <button
            className="px-[34px] py-[11px] text-[25px] font-bold bg-readOnly text-neutral-400 rounded-[30px]"
            onClick={() => navigate('/question/course')}>
            교육문의
          </button>
        </div>
        <NewInputSearch
          placeholder="검색어를 입력해주세요"
          onSearch={searchFaqList}
        />
      </div>
      <div className="w-full">
        <div className="flex justify-between pt-[3px] pb-[9px] text-[16px] text-[#b4b4b4]">
          <div>
            전체 <span className="text-primary">{paginationInfo.total}</span>건
          </div>
          <div>
            현재 페이지{' '}
            <span className="text-primary">{paginationInfo.currentPage}</span>/
            <span>{paginationInfo.totalPages || 1}</span>
          </div>
        </div>
        <table className="w-full">
          <thead className="bg-[#f2f2f2] ">
            <NewTableRow header>
              <NewTableHeader addClass="w-[96px]">번호</NewTableHeader>
              <NewTableHeader>제목</NewTableHeader>
              <NewTableHeader addClass="w-[96px]">첨부</NewTableHeader>
              <NewTableHeader addClass="w-[104px] pr-7">등록일</NewTableHeader>
              {/*<NewTableHeader>조회수</NewTableHeader>*/}
            </NewTableRow>
          </thead>
          <tbody className=" border-b-[1.4px] border-[#bfbfbf]">
            {!isLoading &&
              faqData.length > 0 &&
              faqData.map((item: BoardList, index: number) => {
                const prevItem = faqData[index - 1] ?? '';
                const nextItem = faqData[index + 1] ?? '';
                const rowReverseIndex = genTableReverseIndex(
                  paginationInfo.currentPage,
                  paginationInfo.size,
                  index,
                  paginationInfo.total,
                );
                return (
                  <NewTableRow
                    key={index}
                    addClass="cursor-pointer"
                    onClick={() =>
                      navigate(`/question/service/${item.id}`, {
                        state: {item, prevItem, nextItem},
                      })
                    }>
                    <NewTableData addClass="text-[18px]">
                      {rowReverseIndex}
                    </NewTableData>
                    <NewTableData addClass="font-medium text-lg text-start">
                      {item.title}
                    </NewTableData>
                    <NewTableData addClass="flex justify-center">
                      {item.attachments && item.attachments.length > 0 && (
                        <AttachmentClipIcon
                          width={24}
                          height={24}
                          fill="#808080"
                        />
                      )}
                    </NewTableData>
                    <NewTableData addClass="whitespace-nowrap text-[16px] pr-7">
                      <p>{replaceDateFormat(item.createdAt, 'YYYY-MM-DD')}</p>
                    </NewTableData>
                    {/*<NewTableData addClass="text-[16px]">100</NewTableData>*/}
                  </NewTableRow>
                );
              })}
            {!isLoading && faqData.length === 0 && (
              <TableRowEmpty text="게시글이 존재하지 않습니다." />
            )}
            {isLoading && <Skeleton />}
          </tbody>
        </table>
      </div>
      <div className="mt-[30px] mb-[100px]">
        <Pagination
          totalCount={paginationInfo.total || 1}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages || 1}
          onClick={onChangePage}
        />
      </div>
    </BoardLayout>
  );
}
