import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  optionsProps,
  optionValueProps,
} from '@/types/application.type';

function InputSelect({
  type,
  index,
  upperQuestion,
  questionIndex,
}: {
  type: string;
  index: number;
  upperQuestion?: AnswerProps;
  questionIndex?: number;
}) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentValue = upperQuestion
    ? answerRegisterState.answers
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState.answers.find(({seq}) => seq === index);

  const isChecked = (option: optionsProps) => {
    if (
      !currentValue ||
      !currentValue.answer ||
      !Array.isArray(currentValue.answer)
    ) {
      return false;
    }

    return currentValue.answer.some((answerOption: optionsProps) => {
      if (
        typeof answerOption.value === 'object' &&
        answerOption.value !== null
      ) {
        return (
          answerOption.key === option.key &&
          answerOption.value.content ===
            (option.value as optionValueProps)?.content
        );
      } else {
        return answerOption.key === option.key;
      }
    });
  };

  const updateAnswer = (value: optionsProps[]) => {
    setAnswerRegisterState(prev => {
      const newAnswers = prev.answers.map(item => {
        if (item.seq === (upperQuestion ? upperQuestion.seq : index)) {
          if (upperQuestion) {
            return {
              ...item,
              subQuestions: item.subQuestions
                ? item.subQuestions.map(subItem => {
                    if (subItem.seq === index) {
                      return {...subItem, answer: value};
                    }
                    return subItem;
                  })
                : null,
            };
          }
          return {...item, answer: value};
        }
        return item;
      });
      return {...prev, answers: newAnswers};
    });
  };

  useEffect(() => {
    if (!answerRegisterState || questionIndex === undefined || !currentValue) {
      return;
    }

    const upperAnswerValues = upperQuestion?.answer;
    const ifConditionAnswerKeys = currentValue?.config?.ifConditionAnswerKey;

    const isConditionMatched = Array.isArray(upperAnswerValues)
      ? ifConditionAnswerKeys?.some((key: string) =>
          upperAnswerValues.find((ans: {key: string}) => ans.key === key),
        )
      : false;

    if (isConditionMatched !== !disabled) {
      setDisabled(!isConditionMatched);

      if (!isConditionMatched) {
        updateAnswer([]);
      }
    }
  }, [answerRegisterState.answers?.[questionIndex || 0]?.answer]);

  const handleOnOptionChange = (
    selectedOption: optionsProps,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAnswerRegisterState((prev: AnswerFormProps) => {
      const newAnswers = prev.answers.map((item: AnswerProps) => {
        if (currentValue?.seq === item.seq && !upperQuestion) {
          const updatedAnswer =
            type === 'SINGLE_SELECT'
              ? [selectedOption]
              : e.target.checked
              ? [
                  ...(Array.isArray(item.answer) ? item.answer : []),
                  selectedOption,
                ]
              : (Array.isArray(item.answer) ? item.answer : []).filter(
                  (option: optionsProps) => option.key !== selectedOption.key,
                );

          return {
            ...item,
            answer: updatedAnswer,
          };
        } else if (item.seq === upperQuestion?.seq) {
          return {
            ...item,
            subQuestions: (item.subQuestions || []).map(subItem => {
              if (subItem.seq === index) {
                const updatedSubAnswer =
                  type === 'SINGLE_SELECT'
                    ? [selectedOption]
                    : e.target.checked
                    ? [
                        ...(Array.isArray(subItem.answer)
                          ? subItem.answer
                          : []),
                        selectedOption,
                      ]
                    : (Array.isArray(subItem.answer)
                        ? subItem.answer
                        : []
                      ).filter(
                        (option: optionsProps) =>
                          option.key !== selectedOption.key,
                      );

                return {
                  ...subItem,
                  answer: updatedSubAnswer,
                };
              } else {
                return subItem;
              }
            }),
          };
        } else {
          return item;
        }
      });

      return {...prev, answers: newAnswers};
    });
  };

  return (
    <div>
      {currentValue?.options?.map((option, optionIndex) => (
        <div key={optionIndex} className="flex items-center mb-2">
          <div className="flex items-center p-2 my-2">
            <input
              type={type === 'SINGLE_SELECT' ? 'radio' : 'checkbox'}
              name={
                upperQuestion
                  ? (upperQuestion.seq * 1000000).toString() + index
                  : index.toString()
              }
              value={
                (typeof option.value === 'object' && option.value?.content) ||
                ''
              }
              onChange={e => handleOnOptionChange(option, e)}
              disabled={disabled}
              checked={disabled ? false : isChecked(option)}
            />
          </div>
          {typeof option.value === 'object' && option.value.type === 'TEXT' ? (
            <label
              className={`absolute left-[50px] ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              {option.value.content}
            </label>
          ) : (
            typeof option.value === 'object' &&
            option.value.type === 'IMAGE' && (
              <img src={option.value.content.toString()} alt="Uploaded" />
            )
          )}
        </div>
      ))}
    </div>
  );
}

export default InputSelect;
