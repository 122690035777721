import {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as ArrowRightThin} from '@/assets/newImg/arrow-right-thin-s.svg';
import {ReactComponent as NonCoursesIcon} from '@/assets/newImg/main-non-course.svg';
import NoRegisteredThumbnail from '@/assets/newImg/no-registered-thumbnail.jpg';
import {useRegisterCourse} from '@/hooks/useRegisterCourse';
import {RegisterCourse} from '@/types/course.type';
import {getToday, replaceDateFormat} from '@/utils/format';

export default function MainCourses() {
  const {registerCourses, isLoading} = useRegisterCourse(null, 100);
  const [applicableCourses, setApplicableCourses] =
    useState<RegisterCourse[]>();
  const [currentCourse, setCurrentCourse] = useState<RegisterCourse>();

  const today = useMemo(() => getToday(), []);

  useEffect(() => {
    if (!registerCourses || registerCourses.length === 0) return;
    const newRegisterCourses = registerCourses
      .filter(
        (item: RegisterCourse) =>
          (today >= item.startsAt && today <= item.endsAt) ||
          (today >= item.recruitStartsAt &&
            today <= item.recruitEndsAt &&
            item.applicantsCount > 0),
      )
      .sort((a: RegisterCourse, b: RegisterCourse) => {
        if (a.recruitStartsAt === b.recruitStartsAt) return 0;
        return a.recruitStartsAt < b.recruitStartsAt ? -1 : 1;
      })
      .sort((a: RegisterCourse, b: RegisterCourse) => {
        // created_at이 최신 순으로 정렬
        if (a.createdAt === b.createdAt) return 0;
        return a.createdAt > b.createdAt ? -1 : 1;
      });
    setApplicableCourses(newRegisterCourses);

    if (newRegisterCourses.length === 0) return;
    setCurrentCourse(newRegisterCourses[0]);
  }, [today, registerCourses]);

  const getStatusUI = (text: string, bgColor: string) => (
    <div
      key={text}
      className={`cursor-default w-max whitespace-nowrap py-[7px] px-[19px] rounded-[30px] ${
        bgColor || 'bg-[#337CCF]'
      }`}>
      <p className="text-white text-xl font-bold">{text}</p>
    </div>
  );

  const currentCourseStatus = useCallback(
    (course: RegisterCourse) => {
      if (!course) return <p className="text-white text-[18px] font-bold"></p>;
      const statuses = [];

      switch (course.applicationStatus) {
        case 'ENROLLED':
          return getStatusUI('신청승인', '');
        case 'WAITING':
          return getStatusUI('신청대기', '');
        default:
          if (course.recruitStatus === 'N')
            statuses.push({bgColor: 'bg-[#bfbfbf]', text: '모집완료'});
          else if (course.recruitStatus === 'Y')
            statuses.push({bgColor: 'bg-[#337CCF]', text: '모집중'});
          else {
            statuses.push({bgColor: 'bg-[#ADCBEC]', text: '모집예정'});
          }
          if (today >= course.startsAt && today <= course.endsAt)
            statuses.push({bgColor: 'bg-[#FF5353]', text: '교육 진행 중'});

          return statuses.map(status =>
            getStatusUI(status.text, status.bgColor),
          );
      }
    },
    [today],
  );

  return (
    <div className="flex justify-center px-auto py-[135px] bg-[#fbfbfb]">
      <div className="max-w-[1180px] w-full relative">
        <h3 className="font-bold text-[40px] mb-[10px]">진행 중인 교육과정</h3>
        <div className="flex justify-between mb-[50px]">
          <p className="font-medium text-[20px] text-[#337CCF]">
            현재 진행, 모집 중인 교육과정입니다.
          </p>
        </div>
        {currentCourse && applicableCourses
          ? applicableCourses.slice(0, 3).map(course => (
              <div
                key={course.id}
                className="flex h-[268px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden mb-[30px]">
                <div className="flex min-w-[432px] h-full">
                  <img
                    src={course.thumbnailUrl || NoRegisteredThumbnail}
                    alt="교육과정 썸네일"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="w-full h-[268px] flex flex-col justify-between pl-[48px] pr-[57px] pt-[40px] pb-[90px]">
                  <div className="flex flex-col justify-between gap-5">
                    <div className="flex gap-3">
                      {currentCourseStatus(course)}
                    </div>
                    <h4 className="leading-none font-bold text-[#191D88] text-[25px] break-keep course-title-style">
                      {course.name}
                    </h4>
                    <div className="gap-[5px]">
                      <div className="flex text-[18px] gap-[30px]">
                        <span className="font-bold text-[#404040]">
                          모집기간
                        </span>
                        <p className="font-medium text-[#00000080]">
                          {`${replaceDateFormat(
                            course.recruitStartsAt,
                            'YYYY.MM.DD',
                          )}
                      ~
                      ${replaceDateFormat(course.recruitEndsAt, 'YYYY.MM.DD')}`}
                        </p>
                      </div>
                      <div className="flex text-[18px] gap-[30px]">
                        <span className="font-bold text-[#404040]">
                          교육기간
                        </span>
                        <p className="font-medium text-[#00000080]">
                          {`${replaceDateFormat(
                            course.startsAt,
                            'YYYY.MM.DD',
                          )} ~
                      ${replaceDateFormat(course.endsAt, 'YYYY.MM.DD')}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link to={`/register/${course.id}`}>
                    {/*<Link to={`/new-register/${currentCourse.id}`}>*/}
                    {/* 강의 신청 상세페이지 */}
                    <div className="flex items-center justify-end text-[25px] font-medium gap-3 text-primary ">
                      <p>강의 신청하기</p>
                      <ArrowRightThin width={24} height={24} fill="#337ccf" />
                    </div>
                  </Link>
                </div>
              </div>
            ))
          : !isLoading && (
              <div className="flex h-[450px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden mb-[30px] items-center justify-center">
                <div className="flex flex-col items-center justify-center gap-[42px]">
                  <NonCoursesIcon width={246} height={195} />
                  <p className="text-black text-opacity-50 text-[25px] font-medium">
                    모집 중이거나 진행 중인 교육과정이 없습니다.
                  </p>
                </div>
              </div>
            )}
      </div>
    </div>
  );
}
