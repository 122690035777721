import React, {ChangeEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {
  BoardContentEditor,
  BoardLayout,
  NewPrimaryButton,
} from '@/components/common';
import {sendSiteQNA} from '@/services/boardService';
import siteState from '@/stores/site';
import {decodeHyphenPhoneNumber, encodeHyphenPhoneNumber} from '@/utils/format';
import {ValidateEmailSchema, ValidatePhoneSchema} from '@/utils/validator';

const INIT_ASK_DATA = {
  userName: '',
  userEmail: '',
  userPhone: '',
};

const INIT_ASK_CONTENT = `<p>원할한 상담을 위해 문의 유형을 선택해주세요.</p><p>1. 환불문의&nbsp;&nbsp;&nbsp;&nbsp;2. 장애 신고&nbsp;&nbsp;&nbsp;&nbsp;3. 개선 요구&nbsp;&nbsp;&nbsp;&nbsp;4. 기타 문의</p><p><br></p><p><br></p><p>유형: </p><p>내용: </p><p><br></p><p><br></p>`;

// TODO: 고객문의 페이지 구현
function AskPage() {
  const navigate = useNavigate();
  const [askData, setAskData] = useState(INIT_ASK_DATA);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(INIT_ASK_CONTENT);

  const siteInfo = useRecoilValue(siteState.siteInfoState);

  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, id} = e.target;

    let newValue;
    if (id === 'title') {
      setTitle(value);
      return;
    } else if (id === 'phone') {
      newValue = {...askData, userPhone: encodeHyphenPhoneNumber(value)};
    } else {
      newValue = {...askData, [id]: value};
    }
    setAskData(newValue);
  };

  const submitAsk = async () => {
    const {userName, userEmail, userPhone} = askData;
    try {
      // 데이터 입력 여부 판별
      if (!userName) {
        alert('고객명을 입력해주세요.');
        return;
      }
      if (!title) {
        alert('제목을 입력해주세요.');
        return;
      }
      ValidateEmailSchema.validateSync(userEmail);
      ValidatePhoneSchema.validateSync(decodeHyphenPhoneNumber(userPhone));

      const data = {
        ...askData,
        title,
        content,
        siteId: siteInfo?.id || '',
      };
      await sendSiteQNA(data);
      alert('문의가 완료되었습니다.');
      navigate('/');
    } catch (e: any) {
      console.error('error', e);
    }
  };

  return (
    <BoardLayout boardType="Ask" boardName="고객문의" bgWhite={true}>
      <div className="pt-[70px] pb-[186px]">
        <div className="px-[70px] py-[51px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden">
          {/* 고객명 입력 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">
              고객명
            </h5>
            <div
              className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
              <input
                id="userName"
                type="text"
                value={askData.userName}
                onChange={handleForm}
                className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
                placeholder="고객명을 입력하세요."
              />
            </div>
          </div>
          {/* 이메일 입력 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">
              이메일
            </h5>
            <div
              className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
              <input
                id="userEmail"
                type="email"
                value={askData.userEmail}
                onChange={handleForm}
                className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
                placeholder="이메일 입력하세요."
              />
            </div>
          </div>
          {/* 연락처 입력 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">
              연락처
            </h5>
            <div
              className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
              <input
                id="phone"
                type="userPhone"
                value={askData.userPhone}
                maxLength={13}
                onChange={handleForm}
                className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
                placeholder="연락처를 입력하세요."
              />
            </div>
          </div>
          {/* 제목 입력 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">제목</h5>
            <div
              className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleForm}
                maxLength={13}
                className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
                placeholder="문의 제목을 입력하세요."
              />
            </div>
          </div>
          {/* 문의 내용 작성 에디터 */}
          <div className="mb-[40px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">
              문의 내용
            </h5>
            <BoardContentEditor value={content} onChange={setContent} />
          </div>
          <div className="w-full px-4 pb-2 flex justify-center">
            <NewPrimaryButton
              button="primary"
              onClick={submitAsk}
              text="문의하기"
              addClass=" w-[456px] h-[61px]"
            />
          </div>
        </div>
      </div>
    </BoardLayout>
  );
}

export default AskPage;
