import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import ArrowDownIcon from '@/assets/newImg/arrow-down.svg';
import applyState from '@/stores/application';
import {AnswerProps, optionsProps} from '@/types/application.type';

function InputComboBox({
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  upperQuestion?: AnswerProps;
  questionIndex?: number;
}) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<optionsProps | null>(
    null,
  );

  const currentValue = upperQuestion
    ? answerRegisterState.answers
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState.answers.find(({seq}) => seq === index);

  const updateAnswer = (selectedOption: optionsProps | null) => {
    setAnswerRegisterState(prev => {
      const newAnswers = prev.answers.map(item => {
        if (currentValue?.seq === item.seq && !upperQuestion) {
          return {
            ...item,
            answer: selectedOption || null,
          };
        } else if (item.seq === upperQuestion?.seq) {
          return {
            ...item,
            subQuestions: (item.subQuestions || []).map(subItem => {
              if (subItem.seq === index) {
                return {
                  ...subItem,
                  answer: selectedOption || null,
                };
              }
              return subItem;
            }),
          };
        }
        return item;
      });
      return {...prev, answers: newAnswers};
    });
  };

  useEffect(() => {
    if (currentValue?.answer?.value !== undefined && selectedOption === null) {
      const matchingOption = currentValue.options?.find(
        option => option.value === currentValue.answer?.value,
      );
      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    }
  }, [currentValue, selectedOption]);

  useEffect(() => {
    if (!answerRegisterState || questionIndex === undefined || !currentValue) {
      return;
    }

    const upperAnswerValues = upperQuestion?.answer;
    const ifConditionAnswerKeys = currentValue?.config?.ifConditionAnswerKey;

    const isConditionMatched = Array.isArray(upperAnswerValues)
      ? ifConditionAnswerKeys?.some((key: string) =>
          upperAnswerValues.find((ans: {key: string}) => ans.key === key),
        )
      : true;

    setDisabled(!isConditionMatched);

    if (!isConditionMatched) {
      setSelectedOption(null);
      updateAnswer(null);
    }
  }, [answerRegisterState.answers?.[questionIndex || 0]?.answer]);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(prev => !prev);
    }
  };

  const handleSelectOption = (option: optionsProps) => {
    setSelectedOption(option);
    updateAnswer(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full relative">
      <div
        onClick={handleToggle}
        className={`w-full px-[30px] flex justify-between items-center rounded-[10px] border border-[#e5e5e5] py-2 focus:outline-none hover:cursor-pointer ${
          disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
        }`}>
        <span>
          {selectedOption
            ? typeof selectedOption.value === 'object' &&
              'content' in selectedOption.value
              ? selectedOption.value.content
              : selectedOption.value
            : '선택해주세요'}
        </span>
        <img src={ArrowDownIcon} alt="" />
      </div>

      {isOpen && !disabled && (
        <div className="absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white z-[99999999]">
          <div className="py-1">
            {currentValue?.options?.map((option, optionIndex) => (
              <button
                key={optionIndex}
                onClick={() => handleSelectOption(option)}
                className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}>
                {typeof option.value !== 'object' ? option.value : null}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default InputComboBox;
