import {useRecoilValue} from 'recoil';

import Footer from '@/components/common/NewFooter';
import Header from '@/components/common/NewHeader';
import {
  MainNotice,
  MainBanner,
  MainCourses,
  MainReviews,
} from '@/pages/new-main/components';
import siteState from '@/stores/site';

export function Main() {
  const site = useRecoilValue(siteState.siteInfoState);
  if (!site) return null; // TODO: 로딩 컴포넌트 추가

  return (
    <div className="flex flex-col w-full">
      {/* 헤더 */}
      <Header />
      {/* 메인 */}
      <main>
        {/* 배너 - 목데이터 */}
        <MainBanner />
        {/* 진행 중인 교육과정 */}
        <MainCourses />
        {/* 공지사항 / FAQ 미리보기 */}
        <MainNotice siteData={site} />
        {/* 다양한 수강후기 - 목데이터 */}
        <MainReviews />
      </main>
      <Footer />
    </div>
  );
}
