import React, {useEffect, useState} from 'react';

import {useRecoilState, useRecoilValue} from 'recoil';

import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  ApplicationFormProps,
} from '@/types/application.type';

function InputLikertScale({
  index,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const applyRegisterState = useRecoilValue(applyState.applyRegisterState);
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  const handleOptionClick = (optionIndex: number) => {
    setSelectedItem(optionIndex);
  };

  useEffect(() => {
    setAnswerRegisterState(prev => {
      const newAnswerRegisterState = prev.answers.map(item =>
        currentQuestion?.seq === item.seq && !upperQuestion
          ? {
              ...item,
              answer:
                selectedItem !== null
                  ? currentQuestion.options?.[selectedItem]
                  : null,
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer:
                        selectedItem !== null
                          ? currentQuestion?.options?.[selectedItem]
                          : null,
                    }
                  : subItem,
              ),
            }
          : item,
      );
      return {
        ...prev,
        answers: newAnswerRegisterState,
      } as {
        answers: AnswerProps[];
      };
    });
  }, [selectedItem]);

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      answerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setAnswerRegisterState((prev: AnswerFormProps) => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentQuestion?.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: '',
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: '',
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);

  return (
    <div className="w-full mb-4">
      {currentQuestion?.options?.map((option, optionIndex) => {
        const isSelected = selectedItem === optionIndex;
        const borderColorClass =
          isSelected && !disabled ? 'border-2 border-[#191D88]' : 'border';
        return (
          <div
            key={optionIndex}
            className={`flex rounded-xl p-4 my-2 ${borderColorClass}`}
            onClick={() => !disabled && handleOptionClick(optionIndex)}>
            <div
              className={`mr-4 ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              {Number(option.value) + 1}
            </div>
            <div
              className={`${disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'}`}>
              {option.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default InputLikertScale;
