import React, {useEffect, useState} from 'react';

import {useRecoilState, useRecoilValue} from 'recoil';

import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  ApplicationFormProps,
  optionsProps,
} from '@/types/application.type';

function InputSelect({
  type,
  index,
  upperQuestion,
  questionIndex,
}: {
  type: string;
  index: number;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const applyRegisterState = useRecoilValue(applyState.applyRegisterState);
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      answerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setAnswerRegisterState((prev: AnswerFormProps) => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentQuestion?.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: '',
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: '',
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);
  const handleOnOptionChange = (
    selectedOption: optionsProps,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAnswerRegisterState((prev: {answers: AnswerProps[]}) => ({
      ...prev,
      answers: prev.answers.map((item: AnswerProps) => {
        if (currentQuestion?.seq === item.seq && !upperQuestion) {
          return {
            ...item,
            answer:
              item.type === 'SINGLE_SELECT'
                ? e.target.checked
                  ? [selectedOption]
                  : []
                : e.target.checked
                ? [...(item.answer || []), selectedOption]
                : (item.answer || []).filter(
                    (option: optionsProps) =>
                      option.value !== selectedOption.value,
                  ),
          };
        } else if (item.seq === upperQuestion?.seq) {
          return {
            ...item,
            subQuestions: (item.subQuestions || []).map(subItem => {
              if (subItem.seq === index) {
                return {
                  ...subItem,
                  answer:
                    subItem.type === 'SINGLE_SELECT'
                      ? e.target.checked
                        ? [selectedOption]
                        : []
                      : e.target.checked
                      ? [...(subItem.answer || []), selectedOption]
                      : (subItem.answer || []).filter(
                          (option: optionsProps) =>
                            option.value !== selectedOption.value,
                        ),
                };
              } else {
                return subItem;
              }
            }),
          };
        } else {
          return item;
        }
      }),
    }));
  };

  return (
    <div>
      {currentQuestion?.options?.map((option, optionIndex) => (
        <div key={optionIndex} className="flex items-center mb-2">
          <div className="flex items-center p-2 my-2">
            <input
              type={type === 'SINGLE_SELECT' ? 'radio' : 'checkbox'}
              name={
                upperQuestion
                  ? upperQuestion &&
                    (upperQuestion.seq * 1000000).toString() + index
                  : index.toString()
              }
              value={
                (typeof option.value === 'object' && option.value?.content) ||
                ''
              }
              onChange={e => handleOnOptionChange(option, e)}
              disabled={disabled}
              checked={disabled ? false : undefined}
            />
          </div>
          {
            typeof option.value === 'object' && option.value.type === 'TEXT' ? (
              <label
                className={`absolute left-[50px] ${
                  disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
                }`}
              >
                {option.value.content}
              </label>
            ) : (
              typeof option.value === 'object' && option.value.type === 'IMAGE' && (
                <img src={option.value.content.toString()} alt={'업로드 이미지'} />
              )
            )
          }

        </div>
      ))}
    </div>
  );
}

export default InputSelect;
