import React, {useEffect, useState} from 'react';

import {useRecoilState} from 'recoil';

import DeleteFile from '@/assets/newImg/delete_file.png';
import {ReactComponent as UploadIcon} from '@/assets/newImg/upload.svg';
import {Input} from '@/components/common/NewInput';
import {uploadAllTypeFileService} from '@/services/commonService';
import applyState from '@/stores/application';
import {
  AnswerFormProps,
  AnswerProps,
  ApplicationFormProps,
} from '@/types/application.type';

function InputFile({
  index,
  courseId,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  courseId: string;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const [applyRegisterState, setApplyRegisterState] = useRecoilState(
    applyState.applyRegisterState,
  );

  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = upperQuestion
    ? applyRegisterState.applicationForm
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : applyRegisterState.applicationForm.find(({seq}) => seq === index);

  const [attachedFiles, setAttachedFiles] = useState<
    Array<{
      id: string;
      fileName: string;
      fileUrl: string;
    }>
  >([]);

  useEffect(() => {
    if (!currentQuestion?.config?.maxCount) {
      setApplyRegisterState(prev => {
        const newApplicationForm = prev.applicationForm.map(item =>
          item.seq === index && !upperQuestion
            ? {
                ...item,
                config: {
                  ...item.config,
                  minCount: 1,
                  maxCount: 1,
                },
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        config: {
                          ...subItem.config,
                          minCount: 1,
                          maxCount: 1,
                        },
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          applicationForm: newApplicationForm,
        };
      });
    }
  }, [currentQuestion?.config?.maxCount]);

  const onClickUploadFile = async () => {
    if (attachedFiles.length < (currentQuestion?.config.maxCount || 1)) {
      uploadAllTypeFileService(
        {parentId: courseId, parentType: 'COURSE_APPLICATION'},
        ({fileId, fileName, fileUrl}) => {
          setAttachedFiles(prevFiles => [
            ...prevFiles,
            {
              id: fileId,
              fileName: fileName,
              fileUrl: fileUrl,
            },
          ]);
        },
      );
    }
    setAnswerRegisterState(prev => ({
      ...prev,
      answers: prev.answers.map(item =>
        currentQuestion?.seq === item.seq && !upperQuestion
          ? {
              ...item,
              answer: attachedFiles.map(file => ({
                fileName: file.fileName,
                url: file.fileUrl,
              })),
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: attachedFiles.map(file => ({
                        fileName: file.fileName,
                        url: file.fileUrl,
                      })),
                    }
                  : subItem,
              ),
            }
          : item,
      ),
    }));
  };

  useEffect(() => {
    setAnswerRegisterState(prev => ({
      ...prev,
      answers: prev.answers.map(item =>
        currentQuestion?.seq === item.seq && !upperQuestion
          ? {
              ...item,
              answer: attachedFiles.map(file => ({
                fileName: file.fileName,
                url: file.fileUrl,
              })),
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: attachedFiles.map(file => ({
                        fileName: file.fileName,
                        url: file.fileUrl,
                      })),
                    }
                  : subItem,
              ),
            }
          : item,
      ),
    }));
  }, [attachedFiles]);

  const handleOnDeleteFile = (file: {
    id: string;
    fileName: string;
    fileUrl: string;
  }) => {
    setAttachedFiles(prevFiles =>
      prevFiles.filter(prevFile => prevFile.id !== file.id),
    );
    setAnswerRegisterState(prev => {
      const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
        currentQuestion?.seq === item.seq && !upperQuestion
          ? {
              ...item,
              answer: item.answer.filter(
                (fileItem: {fileName: string; url: string}) =>
                  fileItem.url !== file.fileUrl,
              ),
            }
          : item.seq === upperQuestion?.seq
          ? {
              ...item,
              subQuestions: (item.subQuestions || []).map(subItem =>
                subItem.seq === index
                  ? {
                      ...subItem,
                      answer: subItem.answer.filter(
                        (fileItem: {fileName: string; url: string}) =>
                          fileItem.url !== file.fileUrl,
                      ),
                    }
                  : subItem,
              ),
            }
          : item,
      );
      return {
        ...prev,
        answers: newAnswerRegisterState,
      };
    });
  };

  useEffect(() => {
    if (
      !answerRegisterState ||
      !answerRegisterState.answers ||
      questionIndex === undefined
    )
      return;

    if (
      answerRegisterState.answers?.[questionIndex]?.answer?.[0] &&
      currentQuestion?.config.ifConditionAnswerKey.includes(
        answerRegisterState.answers?.[questionIndex].answer?.[0].key,
      )
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (!disabled) {
      setAttachedFiles([]);
      setAnswerRegisterState((prev: AnswerFormProps) => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentQuestion?.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: '',
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: [],
                      }
                    : subItem,
                ),
              }
            : item,
        );
        return {
          ...prev,
          answers: newAnswerRegisterState,
        };
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);
  return (
    <div className="w-full mb-[10px]">
      {!disabled &&
        attachedFiles.map((file, i) => (
          <div className={'mb-4'} key={i}>
            <div
              className={`w-full border p-4 rounded-2xl flex items-center ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              <label className={'text-[#337CCF] mr-3 w-fit'}>
                {file.fileName}
              </label>
              <img
                src={DeleteFile}
                alt="파일삭제아이콘"
                className={'w-5 h-fit'}
                onClick={() => handleOnDeleteFile(file)}
              />
            </div>
          </div>
        ))}
      {attachedFiles.length < (currentQuestion?.config?.maxCount || 1) && (
        <Input
          id="attachment"
          placeholder="파일을 업로드 해 주세요."
          labelStyle={{color: '#cccccc'}}
          addClass="cursor-pointer"
          rightIcon={
            <UploadIcon
              width={24}
              height={24}
              fill="#337ccf"
              onClick={() => !disabled && onClickUploadFile()}
            />
          }
        />
      )}
    </div>
  );
}

export default InputFile;
