import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as NonCoursesIcon} from '@/assets/newImg/main-non-course.svg';
import {
  Skeleton,
  Pagination,
  BoardLayout,
  NewInputSearch,
} from '@/components/common';
import {useRegisterCourse} from '@/hooks/useRegisterCourse';
import {CourseInfoCard} from "@/pages/new-register/components";
import siteState from '@/stores/site';
import {RegisterCourse} from '@/types/course.type';

export function NewRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const siteInfo = useRecoilValue(siteState.siteInfoState);
  const [courseApplicationInfo, setCourseApplicationInfo] = useState('');

  const {registerCourses, isLoading, paginationInfo, onChangePage, refetch} =
    useRegisterCourse(searchQuery);

  function searchCourseList(query: string) {
    if (!query) {
      navigate('/register');
      return;
    }
    navigate(`/register?search=${query}`);
  }

  useEffect(() => {
    refetch();
  }, [searchQuery, registerCourses]);

  useEffect(() => {
    if (!siteInfo) return;
    if (!siteInfo.variation?.courseApplicationInfo) return;

    let courseApplicationInfoData =
      siteInfo.variation.courseApplicationInfo.split('<body')[1];
    const startIndex = courseApplicationInfoData.indexOf('>') + 1;
    const endIndex = courseApplicationInfoData.indexOf('</body');
    courseApplicationInfoData = courseApplicationInfoData.substring(
      startIndex,
      endIndex,
    );
    setCourseApplicationInfo(courseApplicationInfoData);
  }, [siteInfo]);

  return (
    <BoardLayout boardName={'교육신청'} boardType={'register'} bgWhite>
      {/*{courseApplicationInfo && (*/}
      {/*  <div*/}
      {/*    className={*/}
      {/*      'mx-4 mt-5 px-10 py-6 bg-[#efefef] text-xs border border-transparent rounded-2xl'*/}
      {/*    }*/}
      {/*    dangerouslySetInnerHTML={{__html: courseApplicationInfo}}></div>*/}
      {/*)}*/}
      <div className="w-full flex flex-col items-center">
        <NewInputSearch
          placeholder="검색어를 입력해주세요"
          onSearch={searchCourseList}
        />
      </div>
      <div className="w-full">
        <div className="flex justify-between pt-[3px] pb-[10px] text-[16px] text-[#b4b4b4]">
          <div>
            전체 <span className="text-primary">{paginationInfo.total}</span>건
          </div>
          <div>
            현재 페이지{' '}
            <span className="text-primary">{paginationInfo.currentPage}</span>/
            <span>{paginationInfo.totalPages || 1}</span>
          </div>
        </div>
        {isLoading ? (
          <Skeleton />
        ) : registerCourses.length > 0 ? (
          registerCourses.map((item: RegisterCourse) => {
            return (
              <div key={item.id} className="mb-[30px]">
                <CourseInfoCard courseInfo={item} />
              </div>
            );
          })
        ) : (
          <div className="flex h-[268px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden mb-[30px] items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-[22px]">
              <NonCoursesIcon width={194} height={154} />
              <p className="text-[#808080] text-xl font-medium">
                모집 중이거나 진행 중인 교육과정이 없습니다.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mb-[90px]">
        <Pagination
          totalCount={paginationInfo.total || 1}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages || 1}
          onClick={onChangePage}
        />
      </div>
    </BoardLayout>
  );
}
