import {
  NewStatusButton,
  NewTableData,
  NewTableHeader,
  NewTableRow,
  Pagination,
} from '@/components/common';
import {PaginationInfoProps} from '@/types/common.type';
import {genTableReverseIndex, replaceDateFormat} from '@/utils/format';

interface HomeworkListCardProps {
  paginationInfo: PaginationInfoProps;
  chapterHomeworks: any;
  onClickHomework: (id: string) => void;
  onChangePage: (page: number) => void;
}

export function HomeworkListTable({
  paginationInfo,
  chapterHomeworks,
  onClickHomework,
  onChangePage,
}: HomeworkListCardProps) {
  const genWhetherToSubmit = (
    score: number,
  ): {type: 'readOnly' | 'primary'; text: string} => {
    if (score === -10)
      return {
        type: 'readOnly',
        text: '미제출',
      };
    if (score === -1)
      return {
        type: 'primary',
        text: '제출',
      };
    return {
      type: 'readOnly',
      text: '-',
    };
  };

  const genSubmitStatus = (
    score: number,
  ): {type: 'readOnly' | 'primary' | 'disabled'; text: string} => {
    if (score === 100)
      return {
        type: 'primary',
        text: '합격',
      };
    if (score === 0)
      return {
        type: 'disabled',
        text: '불합격',
      };
    return {
      type: 'readOnly',
      text: '',
    };
  };

  return (
    <>
      <table className="w-full">
        <thead className="bg-[#f2f2f2]">
          <NewTableRow header>
            <NewTableHeader hiddenMobile addClass="w-[150px]">
              번호
            </NewTableHeader>
            <NewTableHeader>제목</NewTableHeader>
            <NewTableHeader addClass="w-[154px]">제출여부</NewTableHeader>
            <NewTableHeader addClass="w-[118px]">등록일</NewTableHeader>
            <NewTableHeader addClass="w-[160px]">결과</NewTableHeader>
          </NewTableRow>
        </thead>
        <tbody className="border-b-[1.4px] border-[#bfbfbf]">
          {chapterHomeworks.length > 0 &&
            chapterHomeworks.map((item: any, index: number) => {
              const whetherToSubmit = genWhetherToSubmit(item.score);
              const submitStatus = genSubmitStatus(item.score);
              const rowReverseIndex = genTableReverseIndex(
                paginationInfo.currentPage,
                paginationInfo.size,
                index,
                paginationInfo.total,
              );
              return (
                <NewTableRow
                  key={item.id}
                  addClass="cursor-pointer"
                  onClick={() => onClickHomework(item.id)}>
                  {/* 번호 */}
                  <NewTableData hiddenMobile addClass="font-medium">
                    <p>{rowReverseIndex}</p>
                  </NewTableData>
                  {/* 과제 제목 */}
                  <NewTableData addClass="font-medium text-start">
                    {item.title}
                  </NewTableData>
                  {/* 제출 여부 */}
                  <NewTableData>
                    <NewStatusButton
                      type={whetherToSubmit.type}
                      text={whetherToSubmit.text}
                    />
                  </NewTableData>
                  {/* 과제 등록일 */}
                  <NewTableData addClass="font-medium whitespace-nowrap">
                    <p>{replaceDateFormat(item.homeworkAt, 'YYYY-MM-DD')}</p>
                  </NewTableData>
                  {/* 제출 결과 */}
                  <NewTableData>
                    {submitStatus.text ? (
                      <NewStatusButton
                        type={submitStatus.type}
                        text={submitStatus.text}
                      />
                    ) : (
                      '-'
                    )}
                  </NewTableData>
                </NewTableRow>
              );
            })}
        </tbody>
      </table>
      <div className="mt-[40px] mb-[151px]">
        <Pagination
          totalCount={paginationInfo.total || 1}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages || 1}
          onClick={onChangePage}
        />
      </div>
    </>
  );
}
