import {reqCertificate, reqCertificateZipFile} from '@/api/certificateApi';

export async function printCertificateService(courseId: string) {
  const res = await reqCertificate(courseId);
  return res.data;
}

export async function downloadCertificateService(
  id: string,
  userIds: string[],
) {
  const res = await reqCertificateZipFile(id, userIds);
  return res.data.data;
}
