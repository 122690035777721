import {useQuery} from 'react-query';

import {getSitePreferencesService} from '@/services/siteService';

export function useSitePreference({siteId}: {siteId: string}) {
  const {data} = useQuery({
    queryKey: ['sitePreference', siteId],
    queryFn: async ({queryKey}) => {
      return await getSitePreferencesService(queryKey[1]);
    },
    enabled: !!siteId,
  });

  return {
    sitePreference: data,
  };
}
