import {ButtonWithTextProps, NewButtonWithTextProps} from '@/types/common.type';

export function NewPrimaryButton({
  type,
  button,
  text,
  onClick,
  addClass,
  hasNoHoverEffect,
    buttonStyle,
}: NewButtonWithTextProps) {
  let typeOfStyle;
  let hoverStyle;

  if (button === 'primary') {
    typeOfStyle = 'bg-primary text-white';
    hoverStyle = 'cursor-pointer hover:bg-[#2963A6] hover:text-white';
  }
  if (button === 'secondary') {
    typeOfStyle = 'border-secondary text-secondary';
    hoverStyle =
      'cursor-pointer hover:bg-secondary hover:text-white hover:border-transparent';
  }
  if (button === 'disabled') {
    typeOfStyle = 'bg-disabled text-white border-transparent';
    hoverStyle = 'cursor-default';
  }
  if (button === 'success') {
    typeOfStyle = 'text-primary border-primary bg-success';
    hoverStyle = 'cursor-pointer';
  }
  if (button === 'emphasis') {
    typeOfStyle = 'bg-emphasis text-white';
    hoverStyle = 'cursor-pointer ';
  }
  if (button === 'cancel') {
    typeOfStyle = 'bg-white border border-[#e5e5e5]';
    hoverStyle = 'cursor-pointer hover:bg-[#f2f2f2]';
  }

  // No hover effect
  if (hasNoHoverEffect) {
    hoverStyle = 'cursor-default';
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`whitespace-nowrap px-auto py-auto font-bold w-[130px] h-[50px] text-base border rounded-[30px] transition-all ease-in-out delay-50 ${typeOfStyle} ${hoverStyle} ${addClass} `}
      style={buttonStyle}
      disabled={button === 'disabled'}
    >
      {text}
    </button>
  );
}

export function NewAttendButton({
  text,
  onClick,
  type,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-primary text-primary bg-success';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-transparent text-primary bg-success ';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-[#999999] border-transparent bg-[#f2f2f2]';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`cursor-default whitespace-nowrap rounded-full text-lg font-bold py-[6px] md:px-3 lg:px-[19px] border-2 duration-100 
    ${typeOfStyle} ${addClass}
    `}>
      {text}
    </button>
  );
}

export function NewStatusButton({
  text,
  onClick,
  type,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-primary text-primary bg-success';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-transparent text-primary bg-success ';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-[#999999] border-transparent bg-[#f2f2f2]';
  }
  if (type === 'readOnly') {
    typeOfStyle = 'text-white border-transparent bg-[#bfbfbf]';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`cursor-default whitespace-nowrap rounded-full text-sm font-bold py-[6px] lg:px-[13px] border-2 duration-100 
    ${typeOfStyle} ${addClass}
    `}>
      <p>{text}</p>
    </button>
  );
}
