import React, {useState, ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as EyeInvisible} from '@/assets/newImg/eye-invisible-bold-s.svg';
import {ReactComponent as EyeVisible} from '@/assets/newImg/eye-visible-bold-s.svg';
import {NewLayout, FormLayout} from '@/components/common';
import {NewPrimaryButton} from '@/components/common/NewButton';
import {Input} from '@/components/common/NewInput';
import {Modal} from '@/components/common/NewModal';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {deleteUserService} from '@/services/userService';
import {clearLoginStatus} from '@/utils/common';

export function UserWithDraw() {
  const navigate = useNavigate();
  const {user} = useUser();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const [password, setPassword] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);

  function handleChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  const handleSubmit = async () => {
    if (!user?.id) {
      return;
    }

    // 비밀번호 입력 여부 검증
    if (!password) {
      openModal({
        type: 'error',
        message: '비밀번호를 입력해주세요.',
      });
      return;
    }

    try {
      await deleteUserService(password, user.id);
      openModal({
        type: 'check',
        message: '회원 탈퇴가 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              clearLoginStatus();
              closeModal();
              navigate('/');
            },
          },
        ],
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  };

  return (
    <>
      <NewLayout bgWhite>
        <FormLayout bgWhite>
          <p className="font-bold text-[30px] mb-8">비밀번호 변경</p>
          <form className="w-full flex flex-col items-center mb-[41px]">
            <Input
              id="currentPassword"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호를 입력하세요."
              label="기존 비밀번호"
              value={password}
              onChange={handleChangePassword}
              rightIcon={
                visiblePassword ? (
                  <EyeVisible
                    className="ml-2"
                    width={22}
                    height={15}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                ) : (
                  <EyeInvisible
                    className="ml-2"
                    width={22}
                    height={17}
                    fill="#404040"
                    onClick={() => {
                      setVisiblePassword(prev => !prev);
                    }}
                  />
                )
              }
            />
          </form>
          <NewPrimaryButton
            type="button"
            button={password ? 'primary' : 'disabled'}
            text="회원탈퇴"
            addClass=" w-full h-[61px] text-white text-xl font-bold mb-[15px]"
            onClick={handleSubmit}
          />
        </FormLayout>
      </NewLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
    </>
  );
}
