import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import {
  BoardLayout,
  NewTableData,
  NewTableHeader,
  NewTableRow,
  TableRowEmpty,
} from '@/components/common';
import {useCourseDetail} from '@/hooks/useCourse';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {
  CourseInfoDetailCard,
  CourseRegisterModal,
} from '@/pages/new-register/components';
import {replaceDateFormat} from '@/utils/format';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

export function NewRegisterDetail() {
  const modal = useModal();
  const navigate = useNavigate();
  const {id: courseId = ''} = useParams<{id: string}>();

  const [isContentsSelected, setIsContentsSelected] = useState(false);
  const {data: courseInfo, refetch} = useCourseDetail(courseId);
  const {user} = useUser();

  useEffect(() => {
    if (!courseId) {
      alert('잘못된 요청이거나 해당 교육 과정이 없습니다.');
      navigate('/register');
      return;
    }
  }, [courseId]);

  const onClickBoard = (type: string) => {
    if (type === 'contents' && !isContentsSelected) {
      setIsContentsSelected(!isContentsSelected);
    } else if (type === 'courseInfo' && isContentsSelected) {
      setIsContentsSelected(!isContentsSelected);
    }
  };

  const handleRegisterCourse = async () => {
    if (!courseId || !courseInfo) {
      // TODO: 적절히 에러처리하기
      return;
    }
    if (!user?.id) {
      alert('로그인 후 이용해주세요.');
      navigate(`/sign-in?redirect=${location.pathname}`);
      return;
    }
    modal.openModal({});
  };

  useEffect(() => {
    refetch().then();
  }, [modal.isShowing]);

  return (
    <BoardLayout
      boardName="교육신청"
      boardType="register"
      bgWhite
      boardDetailName="강의설명">
      <div className="pt-[83px]">
        {courseInfo && (
          <>
            {/* 상단 수강 정보 카드 */}
            <CourseInfoDetailCard
              courseInfo={courseInfo}
              handleRegisterCourse={handleRegisterCourse}
            />
            {courseInfo.id && user && (
              <CourseRegisterModal
                modal={modal}
                userName={user.name}
                courseInfo={courseInfo}
              />
            )}
            {/* 교육 소개 자료 */}
            <div className="relative pt-[77px] pb-[100px]">
              {/* 메뉴 버튼 */}
              <div className="grid grid-cols-2 font-bold text-xl hover:cursor-pointer ">
                <div
                  className={`text-center pt-4 w-full h-[55px] border-t rounded-t-[40px] border-transparent ${
                    !isContentsSelected
                      ? 'text-white bg-primary'
                      : 'text-gray-400 bg-readOnly'
                  }`}
                  onClick={() => onClickBoard('courseInfo')}>
                  강의 소개
                </div>
                <div
                  className={`text-center pt-4 w-full h-[55px] border-t rounded-t-[40px] border-transparent ${
                    isContentsSelected
                      ? 'text-white bg-primary'
                      : 'text-gray-400 bg-readOnly'
                  }`}
                  onClick={() => onClickBoard('contents')}>
                  목차
                </div>
              </div>

              {/* 본문 */}
              {/* 강의 소개*/}
              {!isContentsSelected && (
                <div className=" w-full bg-white border course-shadow px-[60px] py-10 ">
                  <div className="mb-12">
                    {/* 강의 정보 */}
                    <h4 className="font-bold text-3xl mb-[26px]">강의 정보</h4>
                    <div className="flex">
                      <div className="w-2/5 flex flex-col">
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-[5px]">
                            모집기간
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {replaceDateFormat(
                              courseInfo.recruitStartsAt,
                              'YYYY.MM.DD',
                            )}{' '}
                            ~{' '}
                            {replaceDateFormat(
                              courseInfo.recruitEndsAt,
                              'YYYY.MM.DD',
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-[5px]">
                            교육기간
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {replaceDateFormat(
                              courseInfo.startsAt,
                              'YYYY.MM.DD',
                            )}{' '}
                            ~{' '}
                            {replaceDateFormat(courseInfo.endsAt, 'YYYY.MM.DD')}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            수강료
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.courseFee}원
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            모집인원
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.applicantsCount}명
                          </div>
                        </div>
                      </div>
                      <div className="w-3/5 flex flex-col">
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            우대조건
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.preferentialCondition || '없음'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            교육형태
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.courseType === 'ONLINE'
                              ? '원격'
                              : courseInfo.courseType === 'OFFLINE'
                              ? '집합'
                              : '-'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            교육일정
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.schedule || ' 없음'}
                          </div>
                        </div>
                        <div className="flex flex-col mb-4">
                          <div className="text-primary font-bold text-xl mb-1">
                            지원조건
                          </div>
                          <div className="font-medium text-lg text-gray-500">
                            {courseInfo.applicationCondition || ' 없음'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-[30px]">
                    {/* 세부 설명 */}
                    <h4 className="font-bold text-3xl mb-[26px]">세부 설명</h4>
                    <div className=" mx-auto flex flex-col align-center">
                      {parseHtmlContentWithSanitize(courseInfo?.content ?? '')}
                    </div>
                  </div>
                </div>
              )}
              {/* 목차 */}
              {isContentsSelected && (
                <div className="w-full bg-white border course-shadow py-[55px] ">
                  <div className="flex items-center justify-center">
                    <p className="text-[#808080] font-medium text-2xl">
                      준비 중인 기능입니다.
                    </p>
                  </div>
                  {/*<table className="w-full">*/}
                  {/*  <thead className="bg-[#f2f2f2] border-y border-y-[#bfbfbf]">*/}
                  {/*    <NewTableRow header addClass="">*/}
                  {/*      <NewTableHeader addClass="w-[17%]">날짜</NewTableHeader>*/}
                  {/*      <NewTableHeader*/}
                  {/*        addClass=" border-x border-x-[#bfbfbf] w-[10%]"*/}
                  {/*        hiddenMobile>*/}
                  {/*        구분*/}
                  {/*      </NewTableHeader>*/}
                  {/*      <NewTableHeader addClass=" border-x border-x-[#bfbfbf]">*/}
                  {/*        주제 및 내용*/}
                  {/*      </NewTableHeader>*/}
                  {/*      <NewTableHeader addClass=" border-x border-x-[#bfbfbf] w-[10%]">*/}
                  {/*        시간*/}
                  {/*      </NewTableHeader>*/}
                  {/*      <NewTableHeader addClass="w-[11%]">강사</NewTableHeader>*/}
                  {/*    </NewTableRow>*/}
                  {/*  </thead>*/}
                  {/*  <tbody className="border-b border-disabled">*/}
                  {/*    <NewTableRow*/}
                  {/*      key={courseInfo.id}*/}
                  {/*      addClass={'border-y border-y-[#bfbfbf]'}>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass="text-lg text-[#666666] border-r border-r-[#bfbfbf]"*/}
                  {/*        hiddenMobile>*/}
                  {/*        <p>10.23 (월)</p>*/}
                  {/*        <p className="whitespace-nowrap">19:00 ~ 22:00</p>*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData addClass="text-lg text-[#666666] border-x border-x-[#bfbfbf]">*/}
                  {/*        {'1강'}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass={*/}
                  {/*          'text-start text-lg text-[#666666] border-x border-x-[#bfbfbf]'*/}
                  {/*        }>*/}
                  {/*        {courseInfo.name}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass="text-lg text-[#666666]"*/}
                  {/*        hiddenTablet>*/}
                  {/*        {'시간'}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData addClass="text-lg text-[#666666] border-l border-l-[#bfbfbf]">*/}
                  {/*        {'강사'}*/}
                  {/*      </NewTableData>*/}
                  {/*    </NewTableRow>*/}
                  {/*    <NewTableRow*/}
                  {/*      key={courseInfo.id}*/}
                  {/*      addClass={'border-y border-y-[#bfbfbf]'}>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass="text-lg text-[#666666] border-r border-r-[#bfbfbf]"*/}
                  {/*        hiddenMobile>*/}
                  {/*        <p>10.23 (월)</p>*/}
                  {/*        <p className="whitespace-nowrap">19:00 ~ 22:00</p>*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData addClass="text-lg text-[#666666] border-x border-x-[#bfbfbf]">*/}
                  {/*        {'1강'}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass={*/}
                  {/*          'text-start text-lg text-[#666666] border-x border-x-[#bfbfbf]'*/}
                  {/*        }>*/}
                  {/*        {courseInfo.name}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData*/}
                  {/*        addClass="text-lg text-[#666666]"*/}
                  {/*        hiddenTablet>*/}
                  {/*        {'시간'}*/}
                  {/*      </NewTableData>*/}
                  {/*      <NewTableData addClass="text-lg text-[#666666] border-l border-l-[#bfbfbf]">*/}
                  {/*        {'강사'}*/}
                  {/*      </NewTableData>*/}
                  {/*    </NewTableRow>*/}
                  {/*    {!courseInfo && <TableRowEmpty text="검색 결과 없음" />}*/}
                  {/*    /!*{isLoading && <Skeleton />}*!/*/}
                  {/*  </tbody>*/}
                  {/*</table>*/}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </BoardLayout>
  );
}
