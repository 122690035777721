import React, {useEffect, useState, useRef} from 'react';

import {useRecoilState} from 'recoil';

import DeleteFile from '@/assets/newImg/delete_file.png';
import {ReactComponent as UploadIcon} from '@/assets/newImg/upload.svg';
import {Input} from '@/components/common/NewInput';
import {uploadAllTypeFileService} from '@/services/commonService';
import applyState from '@/stores/application';
import {AnswerProps, ApplicationFormProps} from '@/types/application.type';

function InputFile({
  index,
  courseId,
  upperQuestion,
  questionIndex,
}: {
  index: number;
  courseId: string;
  upperQuestion?: ApplicationFormProps;
  questionIndex?: number;
}) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [attachedFiles, setAttachedFiles] = useState<
    Array<{fileName: string; fileUrl: string}>
  >([]);
  const initialLoad = useRef(true);

  const currentValue = upperQuestion
    ? answerRegisterState.answers
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState.answers.find(({seq}) => seq === index);

  console.log(currentValue);

  useEffect(() => {
    if (
      currentValue &&
      Array.isArray(currentValue.answer) &&
      initialLoad.current
    ) {
      const newAttachedFiles = currentValue.answer.map(
        (file: {fileName: string; url: string}) => ({
          fileName: file.fileName,
          fileUrl: file.url,
        }),
      );
      setAttachedFiles(newAttachedFiles);
      initialLoad.current = false;
    }
  }, [currentValue]);

  useEffect(() => {
    if (!answerRegisterState || questionIndex === undefined || !currentValue) {
      return;
    }

    const upperAnswerValues =
      answerRegisterState.answers?.[questionIndex]?.answer;
    const ifConditionAnswerKeys = currentValue?.config?.ifConditionAnswerKey;

    const isConditionMatched = Array.isArray(upperAnswerValues)
      ? ifConditionAnswerKeys?.some((key: string) =>
          upperAnswerValues.find(ans => ans.key === key),
        )
      : false;

    setDisabled(!isConditionMatched);

    if (!isConditionMatched && upperQuestion) {
      setAttachedFiles([]);
      setAnswerRegisterState(prev => {
        const newAnswerRegisterState = prev.answers.map((item: AnswerProps) =>
          currentValue?.seq === item.seq && !upperQuestion
            ? {...item, answer: []}
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index ? {...subItem, answer: []} : subItem,
                ),
              }
            : item,
        );
        return {...prev, answers: newAnswerRegisterState};
      });
    }
  }, [answerRegisterState.answers?.[questionIndex || 0].answer]);

  useEffect(() => {
    if (
      currentValue &&
      JSON.stringify(attachedFiles) !== JSON.stringify(currentValue.answer)
    ) {
      setAnswerRegisterState(prev => ({
        ...prev,
        answers: prev.answers.map(item =>
          currentValue.seq === item.seq && !upperQuestion
            ? {
                ...item,
                answer: attachedFiles.map(file => ({
                  fileName: file.fileName,
                  url: file.fileUrl,
                })),
              }
            : item.seq === upperQuestion?.seq
            ? {
                ...item,
                subQuestions: (item.subQuestions || []).map(subItem =>
                  subItem.seq === index
                    ? {
                        ...subItem,
                        answer: attachedFiles.map(file => ({
                          fileName: file.fileName,
                          url: file.fileUrl,
                        })),
                      }
                    : subItem,
                ),
              }
            : item,
        ),
      }));
    }
  }, [attachedFiles]);

  const onClickUploadFile = async () => {
    if (attachedFiles.length < (currentValue?.config.maxCount || 1)) {
      uploadAllTypeFileService(
        {parentId: courseId, parentType: 'COURSE_APPLICATION'},
        ({fileId, fileName, fileUrl}) => {
          const updatedFiles = [...attachedFiles, {fileName, fileUrl}];
          setAttachedFiles(updatedFiles);

          setAnswerRegisterState(prev => ({
            ...prev,
            answers: prev.answers.map(item =>
              currentValue?.seq === item.seq && !upperQuestion
                ? {
                    ...item,
                    answer: updatedFiles.map(file => ({
                      fileName: file.fileName,
                      url: file.fileUrl,
                    })),
                  }
                : item.seq === upperQuestion?.seq
                ? {
                    ...item,
                    subQuestions: (item.subQuestions || []).map(subItem =>
                      subItem.seq === index
                        ? {
                            ...subItem,
                            answer: updatedFiles.map(file => ({
                              fileName: file.fileName,
                              url: file.fileUrl,
                            })),
                          }
                        : subItem,
                    ),
                  }
                : item,
            ),
          }));
        },
      );
    }
  };

  const handleOnDeleteFile = (file: {fileName: string; fileUrl: string}) => {
    const updatedFiles = attachedFiles.filter(
      prevFile => prevFile.fileUrl !== file.fileUrl,
    );
    setAttachedFiles(updatedFiles);
  };

  return (
    <div className="w-full mb-[10px]">
      {!disabled &&
        attachedFiles.map((file, i) => (
          <div className={'mb-4'} key={i}>
            <div
              className={`w-full border p-4 rounded-2xl flex items-center ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}>
              <label className={'text-[#337CCF] mr-3 w-fit'}>
                {file.fileName}
              </label>
              <img
                src={DeleteFile}
                alt="파일삭제아이콘"
                className={'w-5 h-fit'}
                onClick={() => handleOnDeleteFile(file)}
              />
            </div>
          </div>
        ))}

      {attachedFiles.length < (currentValue?.config?.maxCount || 1) && (
        <Input
          id="attachment"
          placeholder="파일을 업로드 해 주세요."
          labelStyle={{color: '#cccccc'}}
          addClass="cursor-pointer"
          rightIcon={
            <UploadIcon
              width={24}
              height={24}
              fill="#337ccf"
              onClick={() => !disabled && onClickUploadFile()}
            />
          }
        />
      )}
    </div>
  );
}

export default InputFile;
