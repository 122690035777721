import {useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import {reqGetChapterList} from '@/api/chapterApi';
import {reqGetLectureList} from '@/api/courseApi';
import {searchChapterService} from '@/services/chapterService';
import {getXProgressService} from '@/services/courseService';
import siteState from '@/stores/site';
import {Chapter} from '@/types/chapter.type';
import {XProgress} from '@/types/common.type';

export function useChapter(courseId: string) {
  const siteData = useRecoilValue(siteState.siteInfoState);
  const siteId = siteData?.id;

  const {
    data: chapterList,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['chapterList', {siteId, courseId}],
    queryFn: async ({queryKey}) => {
      const {siteId, courseId} = queryKey[1] as {
        siteId: string;
        courseId: string;
      };

      if (!siteId || !courseId) return [];

      // 현재 코스의 렉쳐 리스트 요청
      const lectureListResponse = await reqGetLectureList(courseId);
      const lectureList = lectureListResponse.data.data.content;

      // 렉쳐마다의 챕터 조회
      const chapterListResponse = await reqGetChapterList(lectureList[0].id);
      const chapterList = chapterListResponse.data.data.content;

      // 챕터별 수강률 조회
      const chapterProgress = await getXProgressService({
        xType: 'CHAPTER',
        siteId,
        courseId,
      });

      return chapterList
        .map((item: Chapter) => {
          const chapterProg = chapterProgress.find(
            (data: XProgress) => data.chapterId === item.id,
          );
          if (chapterProg) {
            return {...item, ...chapterProg};
          }
          return item;
        })
        .filter((data: Chapter) => data !== undefined);
    },
    enabled: !!siteId && !!courseId,
    initialData: [],
  });

  return {chapterList, refetch, isLoading: isLoading || isFetching};
}

export const useChapterDetail = (chapterId: string) => {
  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: ['chapterIdDetail', chapterId],
    queryFn: async ({queryKey}) => {
      const chapterId = queryKey[1] as string;
      return await searchChapterService(chapterId);
    },
    enabled: !!chapterId,
  });

  return {data, isLoading: isLoading || isFetching, refetch};
};

