import {memo} from 'react';
import {createPortal} from 'react-dom';
import {
  AiFillAlert,
  AiOutlineCheckCircle,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import {BiErrorCircle} from 'react-icons/bi';

import {ModalButton as ModalButtonProps} from '@/components/common/NewModal';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

export interface ModalProps {
  isShowing: boolean;
  type?: 'error' | 'alert' | 'question' | 'check' | 'policy';
  title?: string;
  message?: string;
  buttons: ModalButtonProps[];
  content?: React.ReactNode;
  noIcon?: boolean;
}

const ModalIcon = memo(({type}: {type?: string}) => {
  if (type === 'alert') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl">
        <AiFillAlert />
      </div>
    );
  }
  if (type === 'error') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-red-600">
        <BiErrorCircle />
      </div>
    );
  }
  if (type === 'question') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-gray-600">
        <AiOutlineQuestionCircle />
      </div>
    );
  }
  if (type === 'check') {
    return (
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10 text-2xl text-green-600">
        <AiOutlineCheckCircle />
      </div>
    );
  }

  return null;
});

ModalIcon.displayName = 'ModalIcon';

const ModalButton = memo(
  ({
    onClick,
    style,
    text,
  }: {
    onClick: () => void;
    style: string;
    text: string;
  }) => {
    const genStyle = () => {
      if (style === 'normal') {
        return 'mt-3 bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0';
      }
      if (style === 'positive') {
        return 'bg-green-500 hover:bg-green-400 sm:ml-3 text-white';
      }
      if (style === 'negative') {
        return 'bg-red-500 hover:bg-red-400 sm:ml-3 text-white';
      }
    };

    return (
      <button
        type="button"
        onClick={onClick}
        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto mx-1 ${genStyle()}`}>
        {text}
      </button>
    );
  },
);

ModalButton.displayName = 'ModalButton';

const NewModalButton = memo(
  ({
    onClick,
    style,
    text,
  }: {
    onClick: () => void;
    style: string;
    text: string;
  }) => {
    const genStyle = () => {
      if (style === 'normal') {
        return 'bg-primary text-white sm:mt-0 hover:bg-[#2963A6] hover:text-white';
      }
      if (style === 'positive') {
        return 'bg-green-500 hover:bg-green-400 sm:ml-3 text-white';
      }
      if (style === 'negative') {
        return 'bg-white hover:bg-readOnly sm:ml-3 lg:mr-[28px]';
      }
    };

    return (
      <button
        type="button"
        onClick={onClick}
        className={`whitespace-nowrap px-auto py-auto font-semibold w-[162px] h-[50px] text-[16x] md:text-base border rounded-[30px] transition-all ease-in-out delay-50  ${genStyle()}`}>
        {text}
      </button>
    );
  },
);

NewModalButton.displayName = 'NewModalButton';

const Modal = ({
  isShowing,
  type,
  title,
  content,
  message,
  buttons,
}: ModalProps) =>
  isShowing
    ? createPortal(
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {type && <ModalIcon type={type} />}

                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title">
                        {title}
                      </h3>

                      {title === '설문조사 진행 안내' && message ? (
                        <div className="mt-2 ">
                          <p className="text-sm text-gray-500 px-2">
                            <div
                              dangerouslySetInnerHTML={{__html: message}}></div>
                          </p>
                        </div>
                      ) : type === 'policy' ? (
                        <div className="mt-2 block">
                          <p className="text-sm text-gray-500 px-2 h-[50vh] overflow-y-auto">
                            {parseHtmlContentWithSanitize(message!)}
                          </p>
                        </div>
                      ) : (
                        <div className="mt-2 ">
                          <p className="text-sm text-gray-500 px-2">
                            {parseHtmlContentWithSanitize(message!)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {buttons.map((item, index) => (
                    <ModalButton
                      key={index}
                      text={item.text}
                      style={item.style}
                      onClick={item.onClick}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;

export default Modal;

export const ModalWrapper = ({
  isShowing,
  children,
  modalWidth,
}: {
  isShowing: boolean;
  children: React.ReactNode;
  modalWidth: string;
}) =>
  isShowing
    ? createPortal(
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center items-center p-8">
              <div
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-${modalWidth} px-8 flex flex-col items-center`}>
                {children}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;

///////////////////////

export const NewModalWrapper = ({
  isShowing,
  children,
  modalWidth,
}: {
  isShowing: boolean;
  children: React.ReactNode;
  modalWidth: string;
}) =>
  isShowing
    ? createPortal(
        <div
          className="relative z-50"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center items-center p-8">
              <div className="max-w-[1180px] w-[590px] bg-white  border-gray-200 rounded-[30px] course-shadow">
                <div className="pt-[46px] pb-[50px] px-[70px] flex flex-col items-start">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
