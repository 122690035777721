import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as NonCoursesIcon} from '@/assets/newImg/main-non-course.svg';
import {
  Skeleton,
  Pagination,
  NewInputSearch,
  BoardLayout,
} from '@/components/common';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {useMyCourse} from '@/hooks/useMyCourse';
import {CourseInfoCard} from "@/pages/new-course/components";
import siteState from '@/stores/site';
import {Course, CourseWithProgress} from '@/types/course.type';

export function NewCourse() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const siteData = useRecoilValue(siteState.siteInfoState);

  const {
    myCourseList,
    isLoading,
    paginationInfo,
    onChangePage,
    refetchMyCourses,
  } = useMyCourse(searchQuery);

  function searchCourseList(query: string) {
    if (!query) {
      navigate('/course');
      return;
    }
    navigate(`/course?search=${query}`);
  }

  useEffect(() => {
    if (siteData && myCourseList && myCourseList.length >= 2) {
      let firstCategoryCompleted = false;
      let secondCategoryCompleted = false;
      myCourseList.map((myCourse: Course) => {
        if (myCourse.completeDate.length > 0) {
          if (myCourse.category === '가 영역') {
            firstCategoryCompleted = true;
          } else if (myCourse.category === '나 영역') {
            secondCategoryCompleted = true;
          }
        }
      });
      if (!(firstCategoryCompleted && secondCategoryCompleted)) {
        return;
      }
      if (siteData?.variation?.surveyUrl === '') {
        return;
      }
      openModal({
        type: 'alert',
        title: '설문조사 진행 안내',
        message: `교육과정 수강이 완료되었습니다. 설문조사를 진행해주시길 바랍니다.</br>
                  <a style="text-decoration: underline; color:#1a0dab" target="_blank" href=${siteData?.variation.surveyUrl}>[바로가기]</a></br> `,
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });
    }
  }, [siteData, myCourseList]);

  useEffect(() => {
    refetchMyCourses();
  }, [searchQuery, siteData]);

  return (
    <>
      <BoardLayout boardName="교육수강" boardType="course" bgWhite>
        <div className="w-full flex flex-col items-center">
          <NewInputSearch
            placeholder="검색어를 입력해주세요"
            onSearch={searchCourseList}
          />
        </div>
        <div className="w-full">
          <div className="flex justify-between pt-[3px] pb-[10px] text-[16px] text-[#b4b4b4]">
            <div>
              전체 <span className="text-primary">{paginationInfo.total}</span>
              건
            </div>
            <div>
              현재 페이지{' '}
              <span className="text-primary">{paginationInfo.currentPage}</span>
              /<span>{paginationInfo.totalPages || 1}</span>
            </div>
          </div>
          {isLoading ? (
            <Skeleton />
          ) : myCourseList.length > 0 ? (
            myCourseList.map((item: CourseWithProgress, index: number) => {
              return (
                <div className="mb-[30px]" key={index}>
                  <CourseInfoCard courseInfo={item} />
                </div>
              );
            })
          ) : (
            <div className="flex h-[268px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden mb-[30px] items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-[22px]">
                <NonCoursesIcon width={194} height={154} />
                <p className="text-[#808080] text-xl font-medium">
                  수강 중인 교육과정이 없습니다.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="mb-[90px]">
          <Pagination
              totalCount={paginationInfo.total || 1}
              pageSize={paginationInfo.size}
              currentPage={paginationInfo.currentPage}
              totalPage={paginationInfo.totalPages || 1}
              onClick={onChangePage}
          />
        </div>
      </BoardLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
    </>
  );
}
