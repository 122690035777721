import React, {useEffect, useState} from 'react';

import {ModalButton} from '@/components/common/NewModal';

interface OpenModalParams {
  type?: 'error' | 'alert' | 'question' | 'check' | 'policy';
  title?: string;
  message?: string;
  content?: string | React.ReactNode;
  buttons?: ModalButton[];
}

export interface ModalProps {
  isShowing: boolean;
  modalData?: OpenModalParams;
  openModal: (newModalData: OpenModalParams) => void;
  closeModal: () => void;
}

export default function useModal() {
  const [isShowing, setIsShowing] = useState(false);
  const [modalData, setModalData] = useState<OpenModalParams>();

  function openModal(newModalData: OpenModalParams) {
    setModalData(prev => {
      return {
        ...prev,
        ...newModalData,
      };
    });
    setIsShowing(true);
  }

  function closeModal() {
    setModalData({});
    setIsShowing(false);
  }

  useEffect(() => {
    if (isShowing) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'unset';
  }, [isShowing]);

  return {
    isShowing,
    modalData,
    openModal,
    closeModal,
  };
}
