import {AxiosResponse} from 'axios';

import {Api} from '@/App';

import {
  AnswerFormProps,
  ResponseApplyFormList,
  ResponseApplyFormListContentProps,
} from '@/types/application.type';
import {BaseResponse} from '@/types/common.type';

export function requestGetApplicationDetail(
  courseId: string,
): Promise<
  AxiosResponse<
    BaseResponse<ResponseApplyFormList<ResponseApplyFormListContentProps>>
  >
> {
  return Api.get(`/forms/applications`, {
    params: {
      courseId,
    },
  });
}

export function requestPostFormAnswer(
  uid: string,
  answeredForm: AnswerFormProps,
) {
  return Api.post(`/forms/applications/${uid}/answers`, {
    answers: answeredForm.answers,
  });
}
