import {ChangeEvent, useEffect, useMemo, useState} from 'react';

import {ReactComponent as CloseIcon} from '@/assets/newImg/close.svg';
import {ReactComponent as DownloadIcon} from '@/assets/newImg/download.svg';
import {ReactComponent as UploadIcon} from '@/assets/newImg/upload.svg';
import {NewPrimaryButton} from '@/components/common';
import {Input} from '@/components/common/NewInput';
import {ModalWrapper} from '@/components/common/NewModal';
import {ModalProps} from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {uploadAllTypeFileService} from '@/services/commonService';
import {submitHomeworkService} from '@/services/homeworkService';
import {HomeworkDetailInfo} from '@/types/chapter.type';

export function HomeworkSubmitModal({
  modal,
  homework,
}: {
  modal: ModalProps;
  homework: HomeworkDetailInfo;
}) {
  const {isShowing, closeModal} = modal;
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [attachedFile, setAttachedFile] = useState({
    id: '',
    fileName: '',
    fileUrl: '',
  });
  const {user} = useUser();

  const isSubmitted = useMemo(() => !!homework.submitHomeworkId, [homework]);
  const handleOnInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {id, value} = e.target;
    if (id === 'title') {
      setTitle(value);
    } else if (id === 'content') {
      setContent(value);
    }
  };

  function onClickCloseModal() {
    setTitle('');
    setContent('');
    setAttachedFile({id: '', fileName: '', fileUrl: ''});
    closeModal();
  }

  const onClickDownloadFile = (fileUrl: string) => {
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl.split('?')[0], '_blank', 'noopener noreferrer');
  };
  const onClickUploadFile = () => {
    uploadAllTypeFileService(
      {parentId: homework.homeworkId, parentType: 'SUBMIT_HOMEWORK'},
      ({fileId, fileName, fileUrl}) => {
        setAttachedFile({
          id: fileId,
          fileName: fileName,
          fileUrl: fileUrl,
        });
      },
    );
  };

  const submitHomework = async () => {
    if (!homework.homeworkId) {
      return;
    }
    if (!title) {
      alert('제목을 입력해주세요');
      return;
    }
    if (!content || content === '') {
      alert('내용을 입력해주세요');
      return;
    }
    const homeworkFile = {
      id: attachedFile.id,
      fileName: attachedFile.fileName,
      fileUrl: attachedFile.fileUrl,
    };
    const submitData = {
      title: title,
      content: content,
      attachments: homeworkFile.id ? [attachedFile] : [],
    };

    try {
      const result = await submitHomeworkService(
        homework.homeworkId,
        submitData,
      );
      if (result) {
        alert('과제 제출이 완료되었습니다.');
        onClickCloseModal();
      }
    } catch (e: any) {
      alert('과제 제출에 실패했습니다.');
    }
  };

  useEffect(() => {
    return () =>
      document.body
        .querySelectorAll(':scope > input')
        .forEach(item => item.remove());
  }, []);

  return (
    <ModalWrapper isShowing={isShowing}>
      {/* 과제 정보 */}
      <div className="pt-[46px] pb-[40px] pl-[70px] pr-[70px] relative">
        <div
          className="absolute top-[46px] right-[46px] cursor-pointer"
          onClick={onClickCloseModal}>
          <CloseIcon width={24} height={24} fill="black" />
        </div>
        <h3 className="font-bold text-3xl mb-8">
          {isSubmitted ? '제출한 과제' : '과제 제출'}
        </h3>
        <div className="w-full flex flex-col gap-3 mb-[30px]">
          <div className="relative">
            <Input
              id="name"
              value={user?.name ?? ''}
              label="이름"
              disabled={true}
              labelStyle={{color: '#404040'}} // TODO: twin.macro 도입 검토
              addClass="bg-readOnly"
            />
            <span className="absolute font-medium top-[8px] left-[44px] text-[#FF5353] text-[11px]">
              본인 확인용이므로 회원가입 시 입력했던 이름으로 자동 설정되어
              수정이 불가능합니다.
            </span>
          </div>
          <Input
            id="title"
            label="제목"
            labelStyle={{color: '#404040'}}
            disabled={isSubmitted}
            value={homework.submitHomeworkTitle ?? title}
            onChange={handleOnInput}
            placeholder="과제 게시글 제목을 입력해주세요."
            addClass={`${homework.submitHomeworkTitle ? 'bg-readOnly' : ''}`}
          />
          <div className="w-full flex flex-col">
            <label className="mb-[10px] text-[#808080] text-[18px]">내용</label>
            <div className="flex items-center bg-white">
              <div
                className={`w-full min-h-[132px] lg:px-[30px] lg:py-5 flex items-center rounded-[10px] border ${
                  homework.submitHomeworkContent ? 'bg-readOnly ' : ''
                }`}>
                <textarea
                  id="content"
                  value={homework.submitHomeworkContent ?? content}
                  placeholder={'내용을 입력해주세요.'}
                  disabled={isSubmitted}
                  onChange={handleOnInput}
                  className={`
                  ${
                    homework.submitHomeworkContent
                      ? 'bg-readOnly'
                      : 'bg-transparent'
                  } w-full min-h-[92px] overflow-y-auto text-[#404040] placeholder:text-[16px] placeholder-disabled p-0 appearance-none border-none focus:ring-0 text-base
                `}></textarea>
              </div>
            </div>
          </div>
          <Input
            id="attachment"
            placeholder="파일을 업로드해주세요."
            value={
              homework.attachments &&
              homework.attachments.length > 0 &&
              homework.attachments[0].fileUrl
                ? homework.attachments[0].fileName
                : attachedFile.fileName
            }
            label="첨부파일"
            labelStyle={{color: '#404040'}}
            disabled={true}
            addClass={`${isSubmitted ? 'bg-readOnly' : 'cursor-pointer'}`}
            onClick={onClickUploadFile}
            rightIcon={
              isSubmitted ? (
                <DownloadIcon
                  width={24}
                  height={24}
                  fill="#337ccf"
                  onClick={() => {
                    onClickDownloadFile(
                      homework?.attachments?.[0]?.fileUrl || '',
                    );
                  }}
                />
              ) : (
                <UploadIcon
                  width={24}
                  height={24}
                  fill="#337ccf"
                  onClick={onClickUploadFile}
                />
              )
            }
          />
        </div>
        {homework.submitHomeworkId ? (
          <></>
        ) : (
          <NewPrimaryButton
            button="primary"
            type="submit"
            text="과제 제출하기"
            onClick={submitHomework}
            addClass="text-xl w-full h-[60px]"
          />
        )}
      </div>
    </ModalWrapper>
  );
}
