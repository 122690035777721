import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {ReactComponent as ArrowRight} from '@/assets/newImg/arrow-right-bold-s.svg';
import {ReactComponent as NonBoardIcon} from '@/assets/newImg/main-non-board.svg';
import {useBoards} from '@/hooks/useBoards';
import siteState from '@/stores/site';
import {BoardList} from '@/types/boards.type';
import {Site} from '@/types/site.type';
import {replaceDateFormat} from '@/utils/format';

interface MainNoticeProps {
  siteData: Site;
}

export default function MainNotice({siteData}: MainNoticeProps) {
  const siteInfo = useRecoilValue(siteState.siteInfoState);
  const isShowFaq = (siteInfo?.subDomain) !== 'gwcloud' ;

  const [isFAQSelected, setIsFAQSelected] = useState(false);
  const {
    data: noticeData,
    isLoading: isLoadingNotice,
    refetch: refetchNotice,
  } = useBoards({
    category: 'Notice',
  });

  const {
    data: qnaData,
    isLoading: isLoadingQna,
    refetch: refetchQna,
  } = useBoards({
    category: 'SERVICE',
  });

  const onClickBoard = (type: string) => {
    if (type === 'FAQ' && !isFAQSelected) {
      setIsFAQSelected(!isFAQSelected);
    } else if (type === 'notice' && isFAQSelected) {
      setIsFAQSelected(!isFAQSelected);
    }
  };

  const renderNonBoard = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[39px] pb-9">
          <NonBoardIcon width={163} height={175} />
          <p className="text-black text-opacity-50 text-[25px] font-medium">
            게시글이 없습니다.
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      await refetchNotice();
      await refetchQna();
    })();
  }, [siteData]);

  return (
    <>
      <div className="flex justify-center px-auto pb-[135px] bg-[#fbfbfb]">
        <div className="max-w-[1180px] w-full ">
          <div className="py-[36px] pl-[57px] pr-[79px] flex flex-col bg-white border-gray-200 rounded-[30px] course-shadow  min-h-[472px]">
            <div className="flex justify-between items-center mb-10">
              <div className="flex font-bold text-[35px] hover:cursor-pointer gap-[45px]">
                <div
                  className="flex flex-col justify-center"
                  onClick={() => onClickBoard('notice')}>
                  <p
                    className={
                      !isFAQSelected ? 'text-[#191D88]' : 'text-gray-400'
                    }>
                    공지사항
                  </p>
                  {!isFAQSelected && (
                    <div className="flex self-center h-1 rounded-[4px] w-[90%] bg-[#191D88]" />
                  )}
                </div>
                {isShowFaq && (
                  <div
                    className="flex flex-col justify-center"
                    onClick={() => onClickBoard('FAQ')}>
                    <p
                      className={
                        isFAQSelected ? 'text-[#191D88]' : 'text-gray-400'
                      }>
                      FAQ
                    </p>
                    {isFAQSelected && (
                      <div className="flex self-center h-1 rounded-[4px] w-[90%] bg-[#191D88]" />
                    )}
                  </div>
                )}
              </div>
              <Link to={isFAQSelected ? '/question' : '/notice'}>
                <ArrowRight width={15} height={25} fill="#191d88" />
              </Link>
            </div>

            {!isFAQSelected && (
              <div className="flex flex-col gap-[19px] pl-8">
                {!isLoadingNotice && noticeData.length > 0
                  ? noticeData.slice(0, 5).map((item: BoardList) => {
                      return (
                        <Link to={`/notice/${item.id}`} key={item.id}>
                          <div className="flex items-center justify-between hover:bg-gray-100 hover:rounded-md">
                            <div className="font-bold text-[25px] text-[#666666] w-[82%] main-notice-title-style">
                              {item.title}
                            </div>
                            <div className="text-[20px] text-[#B4B4B4]">
                              {replaceDateFormat(
                                item.createdAt,
                                'YYYY.MM.DD (dd)',
                              )}
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  : renderNonBoard()}
              </div>
            )}

            {isFAQSelected && (
              <div className="flex flex-col gap-[19px] pl-8">
                {!isLoadingQna && qnaData.length > 0
                  ? qnaData.slice(0, 5).map((item: BoardList) => {
                      return (
                        <Link to={`/question/service/${item.id}`} key={item.id}>
                          <div className="flex items-center justify-between hover:bg-gray-100 hover:rounded-md">
                            <div className="font-bold text-[25px] text-[#666666] w-[82%] main-notice-title-style">
                              {item.title}
                            </div>
                            <div className="text-[20px] text-[#B4B4B4]">
                              {replaceDateFormat(
                                item.createdAt,
                                'YYYY.MM.DD (dd)',
                              )}
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  : renderNonBoard()}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
