import React, {useEffect} from 'react';

import {useRecoilState} from 'recoil';

import {ReactComponent as CloseIcon} from '@/assets/newImg/close.svg';
import {NewPrimaryButton} from '@/components/common';
import {Input} from '@/components/common/NewInput';
import {Modal, ModalWrapper} from '@/components/common/NewModal';
import SurveyAnswerInput from '@/components/survey/SurveyAnswerInput';
import useModal, {ModalProps} from '@/hooks/useModal';
import {useSurveyTemplate} from '@/hooks/useSurvey';
import {registerSurveyAnswer} from '@/services/surveyService';
import {surveyState} from '@/stores/survey';
import {Course} from '@/types/course.type';
import {SurveyAnswerInfoProps, SurveyInfo} from '@/types/survey.type';
import {EMAIL_REGEX, NUMBER_REGEX} from '@/utils/regex';

export function SurveyAnswerModal({
  modal,
  userName,
  surveyInfo,
  courseInfo,
}: {
  modal: ModalProps;
  userName: string;
  surveyInfo: SurveyInfo;
  courseInfo: Course;
}) {
  const {
    isShowing: confirmShowing,
    modalData: confirmModalData,
    closeModal: confirmCloseModal,
    openModal: confirmOpenModal,
  } = useModal();
  const {isShowing, closeModal} = modal;

  const {useSurvey, id} = courseInfo;
  const [surveyRegisterState, setSurveyRegisterState] = useRecoilState(
    surveyState.surveyRegisterState,
  );
  const [surveyAnswerRegisterState, setSurveyAnswerRegisterState] =
    useRecoilState(surveyState.surveyAnswerRegisterState);
  const {data: surveyTemplateData, refetch: refetchSurveyTemolateData} =
    useSurveyTemplate(surveyInfo && surveyInfo.templateId);

  useEffect(() => {
    setTimeout(() => {
      refetchSurveyTemolateData && refetchSurveyTemolateData();
      if (!surveyTemplateData?.content?.[0]) return;
      if (surveyTemplateData) {
        setSurveyRegisterState(surveyTemplateData?.content?.[0]);
        setSurveyAnswerRegisterState({
          answers: surveyTemplateData?.content?.[0].template.map(
            ({subQuestions, ...item}) => {
              const {...restItem} = item;
              return {
                ...restItem,
                questionId: item.questionId || '',
                answer: '',
                subQuestions: subQuestions
                  ? subQuestions.map(({...subItem}) => {
                      return {...subItem, answer: ''};
                    })
                  : null,
              } as SurveyAnswerInfoProps;
            },
          ),
        });
      }
    }, 1);
  }, [surveyTemplateData]);

  useEffect(() => {
    return () =>
      document.body
        .querySelectorAll(':scope > input')
        .forEach(item => item.remove());
  }, []);

  const submitSurvey = async () => {
    if (!surveyInfo.id) return;

    let isRequiredFilled = true;
    for (const item of surveyAnswerRegisterState.answers) {
      if (
        item.isRequired &&
        item.answer.length === 0 &&
        surveyAnswerRegisterState
      ) {
        isRequiredFilled = false;
        break;
      } else {
        if (item.subQuestions) {
          for (const subItem of item.subQuestions) {
            if (
              subItem.isRequired &&
              subItem.answer?.length === 0 &&
              surveyAnswerRegisterState &&
              subItem.config &&
              subItem.config.ifConditionAnswerKey &&
              subItem.config.ifConditionAnswerKey.includes(
                item.answer[0]?.key,
              ) &&
              subItem.type !== ''
            ) {
              isRequiredFilled = false;
              break;
            }
          }
        }
      }
    }

    if (!isRequiredFilled) {
      confirmOpenModal({
        type: 'check',
        title: '확인',
        message: '필수 입력 항목을 전부 입력해주세요.',
      });
      return;
    }

    for (const item of surveyAnswerRegisterState.answers) {
      if (item.subQuestions) {
        for (const subItem of item.subQuestions) {
          if (
            subItem.config.regex &&
            subItem.config.ifConditionAnswerKey &&
            subItem.config.ifConditionAnswerKey.includes(item.answer[0]?.key)
          ) {
            if (subItem.config.regex === 'EMAIL') {
              if (!EMAIL_REGEX.test(subItem.answer)) {
                confirmOpenModal({
                  type: 'check',
                  title: '확인',
                  message: '이메일 형식에 맞게 입력해주세요.',
                });
                return;
              }
            } else if (subItem.config.regex === 'NUMBER') {
              if (!NUMBER_REGEX.test(subItem.answer)) {
                confirmOpenModal({
                  type: 'check',
                  title: '확인',
                  message: '숫자만 입력해주세요.',
                });
                return;
              }
            }
          }
        }
      } else {
        if (item.config.regex) {
          if (item.config.regex === 'EMAIL') {
            if (!EMAIL_REGEX.test(item.answer)) {
              confirmOpenModal({
                type: 'check',
                title: '확인',
                message: '이메일 형식에 맞게 입력해주세요.',
              });
              return;
            }
          } else if (item.config.regex === 'NUMBER') {
            if (!NUMBER_REGEX.test(item.answer)) {
              confirmOpenModal({
                type: 'check',
                title: '확인',
                message: '숫자만 입력해주세요.',
              });
              return;
            }
          }
        }
      }
    }

    for (const item of surveyAnswerRegisterState.answers) {
      if (item.config.minLength && item.answer.length < item.config.minLength) {
        confirmOpenModal({
          type: 'check',
          title: '확인',
          message: `${item.seq + 1}번 항목 \n최소 ${
            item.config.minLength
          }자 이상 입력해주세요.`,
        });
        return;
      } else if (item.subQuestions) {
        for (const subItem of item.subQuestions) {
          if (
            subItem.config.minLength &&
            subItem.answer.length < subItem.config.minLength &&
            subItem.config.ifConditionAnswerKey &&
            subItem.config.ifConditionAnswerKey.includes(item.answer[0]?.key) &&
            subItem.type !== ''
          ) {
            confirmOpenModal({
              type: 'check',
              title: '확인',
              message: `${item.seq + 1}-${subItem.seq + 1}번 항목 \n최소 ${
                subItem.config.minLength
              }자 이상 입력해주세요.`,
            });
            return;
          }
        }
      }
    }
    try {
      if (useSurvey) {
        const response = await registerSurveyAnswer({
          uid: surveyInfo.id,
          answers: surveyAnswerRegisterState.answers,
        });
        if (response) {
          confirmOpenModal({
            type: 'check',
            title: '확인',
            message: '응답이 등록되었습니다.',
          });
          closeModal();
        }
      }
    } catch (e) {
      confirmOpenModal({
        type: 'check',
        title: '확인',
        message: '응답 중 오류가 발생하였습니다.',
      });
    }
  };

  return (
    <>
      <ModalWrapper isShowing={isShowing}>
        <div className="pt-[46px] pb-[40px] pl-[70px] pr-[70px] relative">
          {!surveyInfo.requiredAnswer ? (
            <div
              className="absolute top-[50px] right-[46px] cursor-pointer"
              onClick={closeModal}>
              <CloseIcon width={24} height={24} fill="black" />
            </div>
          ) : (
            <div
              className="absolute md:top-[20px] top-[80px] right-[46px] cursor-pointer">
              <label className={'text-sm font-semibold text-[#FF5353]'}>
                수강 전 필수 설문조사 입니다.
              </label>
            </div>
          )}
          <h3 className="font-bold text-3xl mb-8">
            {surveyInfo.title}
          </h3>
          {useSurvey && (
            <div className="w-full flex flex-col gap-3 mb-10">
              <div className="relative">
                <Input
                  id="name"
                  value={userName}
                  label="이름"
                  disabled={true}
                  labelStyle={{color: '#404040'}} // TODO: twin.macro 도입 검토
                  addClass="bg-readOnly"
                />
                <span className="absolute font-medium top-[7px] left-[44px] text-[#FF5353] text-[11px] w-full">
                  본인 확인용이므로 회원가입 시 입력했던 이름으로 자동 설정되어
                  수정이 불가능합니다.
                </span>
              </div>
              {surveyRegisterState?.template.map((item, index) => (
                <div key={index} className="relative">
                  <SurveyAnswerInput
                    index={index}
                    label={item.question}
                    labelStyle={{color: '#404040'}}
                    courseId={id}
                  />
                  {item.subQuestions
                    ?.filter(
                      item =>
                        item.question &&
                        item.config.ifConditionAnswerKey &&
                        item.type,
                    )
                    .map((subItem, subIndex) => (
                      <div key={subIndex}>
                        <SurveyAnswerInput
                          index={subIndex}
                          label={subItem.question}
                          labelStyle={{color: '#404040'}}
                          courseId={id}
                          questionIndex={index}
                          upperQuestion={item}
                        />
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
          <div className={'w-full flex items-center justify-evenly'}>
            <NewPrimaryButton
              button="primary"
              type="submit"
              onClick={submitSurvey}
              text="응답하기"
              addClass={`text-xl ${
                !surveyInfo.requiredAnswer ? 'w-[250px]' : 'w-full'
              } h-[60px]`}
            />
            {!surveyInfo.requiredAnswer && (
              <NewPrimaryButton
                button="secondary"
                type="button"
                onClick={closeModal}
                text="건너뛰기"
                addClass="text-xl w-3/12 h-[60px]"
              />
            )}
          </div>
        </div>
      </ModalWrapper>
      <Modal
        isShowing={confirmShowing}
        type="alert"
        title={confirmModalData?.title}
        message={confirmModalData?.message}
        buttons={
          confirmModalData?.buttons || [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                confirmCloseModal();
              },
            },
          ]
        }
      />
    </>
  );
}
