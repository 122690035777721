import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import NoRegisteredThumbnail from '@/assets/newImg/no-registered-thumbnail.jpg';
import {NewPrimaryButton} from '@/components/common';
import {Course} from '@/types/course.type';
import {getToday, replaceDateFormat} from '@/utils/format';

export function CourseInfoDetailCard({
  courseInfo,
  isCourseDetail,
  handleRegisterCourse,
}: {
  courseInfo: Course;
  isCourseDetail?: boolean;
  handleRegisterCourse?: () => void;
}) {
  const navigate = useNavigate();
  const isRecruitEnd = new Date() > new Date(courseInfo.recruitEndsAt);
  const isRecruitStart = new Date() < new Date(courseInfo.recruitStartsAt);
  const today = useMemo(() => getToday(), []);

  const getStatusUI = (text: string, bgColor: string) => (
    <div
      key={text}
      className={`cursor-default w-max whitespace-nowrap py-[7px] px-[19px] rounded-[30px] h-max-[40px] ${
        bgColor || 'bg-[#337CCF]'
      }`}>
      <p className="text-white text-lg font-bold">{text}</p>
    </div>
  );

  const currentCourseStatus = useCallback(() => {
    if (!courseInfo) return <p className="text-white text-xl font-bold"></p>;
    const statuses = [];

    switch (courseInfo.applicationStatus) {
      case 'ENROLLED':
      case 'WAITING':
      default:
        if (courseInfo.recruitStatus === 'N' && isRecruitEnd)
          statuses.push({bgColor: 'bg-[#bfbfbf]', text: '모집완료'});
        else if (courseInfo.recruitStatus === 'Y')
          statuses.push({bgColor: 'bg-[#337CCF]', text: '모집중'});
        else {
          statuses.push({bgColor: 'bg-[#ADCBEC]', text: '모집예정'});
        }
        if (today >= courseInfo.startsAt && today <= courseInfo.endsAt)
          statuses.push({bgColor: 'bg-[#FF5353]', text: '교육 진행 중'});

        return statuses.map(status => getStatusUI(status.text, status.bgColor));
    }
  }, [courseInfo]);

  return (
    <div className="max-w-[1180px] w-full relative">
      <div className="w-full grid grid-cols-2 grid-rows-1 gap-[43px]">
        <div className="flex items-center border border-transparent rounded-[30px] overflow-hidden">
          <img
            src={courseInfo.thumbnailUrl || NoRegisteredThumbnail}
            alt="교육과정 썸네일"
            className=" h-[366px] object-cover"
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex flex-col">
            <div className="flex gap-2.5 mb-2.5">{currentCourseStatus()}</div>
            <h4 className="leading-relaxed font-bold text-[#191D88] mb-[10px] text-[25px] break-keep">
              {courseInfo.name}
            </h4>
            <div className="flex mb-[20px] gap-7">
              <div>
                <div className="flex mb-[5px] text-[18px]">
                  <span className="font-bold w-[80px] ">모집기간</span>
                  <p className="text-[#00000080] text-lg font-medium">
                    {replaceDateFormat(
                      courseInfo.recruitStartsAt,
                      'YYYY.MM.DD',
                    )}{' '}
                    ~{' '}
                    {replaceDateFormat(courseInfo.recruitEndsAt, 'YYYY.MM.DD')}
                  </p>
                </div>
                <div className="flex mb-[5px] text-[18px]">
                  <span className="font-bold w-[80px] ">교육기간</span>
                  <p className="text-[#00000080] text-lg font-medium">
                    {replaceDateFormat(courseInfo.startsAt, 'YYYY.MM.DD')} ~{' '}
                    {replaceDateFormat(courseInfo.endsAt, 'YYYY.MM.DD')}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex mb-[5px] text-[18px]">
                  <span className="font-bold w-[80px] ">수강료</span>
                  <p className="text-[#00000080] text-lg font-medium">
                    {courseInfo.courseFee}원
                  </p>
                </div>
                <div className="flex mb-[5px] text-[18px]">
                  <span className="font-bold w-[80px] ">모집인원</span>
                  <p className="text-[#00000080] text-lg font-medium">
                    {courseInfo.applicantsCount}명
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            {/* 신청됨 -> 신청 완료 */}
            {courseInfo.applicationStatus === 'ENROLLED' &&
              today >= courseInfo.startsAt &&
              today <= courseInfo.endsAt && (
                <NewPrimaryButton
                  text="수강하기"
                  button="primary"
                  addClass="w-full h-[65px] text-xl font-bold"
                  onClick={() => {
                    navigate(`/course/${courseInfo.id}`);
                    return;
                  }}
                />
              )}
            {courseInfo.applicationStatus === 'ENROLLED' &&
              courseInfo.endsAt < today && (
                <NewPrimaryButton
                  text="과정종료"
                  addClass="w-full h-[65px] text-xl font-bold"
                  button="disabled"
                />
              )}
            {/* 신청안했는데 신청못함 */}
            {!isCourseDetail &&
              courseInfo.applicationStatus === 'NULL' &&
              (isRecruitEnd ||
                isRecruitStart ||
                courseInfo.applicantsCount === 0) && (
                <NewPrimaryButton
                  text="신청불가"
                  addClass="w-full bg-readOnly border-transparent text-disabled h-[65px] text-xl"
                  hasNoHoverEffect={true}
                />
              )}
            {/* 신청안했는데 신청가능 */}
            {!isCourseDetail &&
              courseInfo.applicationStatus === 'NULL' &&
              !isRecruitEnd &&
              !isRecruitStart &&
              courseInfo.applicantsCount > 0 && (
                <NewPrimaryButton
                  text="수강 신청하기"
                  addClass="w-full h-[65px] text-xl"
                  button="primary"
                  onClick={handleRegisterCourse}
                />
              )}
            {/* 승인대기중 */}
            {!isCourseDetail && courseInfo.applicationStatus === 'WAITING' && (
              <NewPrimaryButton
                text="승인대기중"
                addClass="w-full h-[65px] text-xl"
                button="success"
                onClick={() => {
                  navigate(`/new-register/${courseInfo.id}`);
                }}
              />
            )}
            {/* 탈락~! */}
            {!isCourseDetail && courseInfo.applicationStatus === 'FAILURE' && (
              <NewPrimaryButton
                text="탈락"
                addClass="w-full h-[65px] text-xl"
                button="disabled"
              />
            )}
            {isCourseDetail && (
              <NewPrimaryButton
                text="수료증 다운로드"
                addClass="w-full h-[65px] text-xl"
                button="primary"
                onClick={() => navigate('/certificate')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
