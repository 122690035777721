import {ReactComponent as AttachmentIcon} from '@/assets/newImg/attachment-clip.svg';
import {ReactComponent as DownloadIcon} from '@/assets/newImg/download.svg';
import {ReactComponent as PencilIcon} from '@/assets/newImg/pencil.svg';
import useModal from '@/hooks/useModal';
import {HomeworkSubmitModal} from '@/pages/new-course/components/HomeworkSubmitModal';
import {HomeworkDetailInfo} from '@/types/chapter.type';
import {Attachment} from "@/types/common.type";
import {replaceDateFormat} from '@/utils/format';

interface HomeworkDetailProps {
  homeworkDetail: HomeworkDetailInfo;
  homeworkAttachments: Attachment[] // homeworkDetail 에 있는 attachments 는 제출한 과제의 첨부파일이다.
}

export function HomeworkDetail({homeworkDetail,homeworkAttachments}: HomeworkDetailProps) {
  const modal = useModal();
  return (
    <>
      <div className="">
        {/* 제목, 정보 */}
        <div className="flex flex-col px-[30px] gap-[30px]">
          <div className="w-full pl-[1px]">
            <h3 className="text-3xl font-bold text-[#404040]">
              {homeworkDetail.homeworkTitle}
            </h3>
          </div>
          <div className="flex items-center ml-[-1px] mb-8 gap-5 text-neutral-500">
            <div className="flex items-center">
              <p className="font-bold text-lg mr-2">등록일</p>
              <p className="font-medium text-base">
                {replaceDateFormat(homeworkDetail.homeworkAt, 'YYYY-MM-DD')}
              </p>
            </div>
            {/*<div className="flex items-center">*/}
            {/*  <p className="font-bold text-lg mr-[10px]">조회수</p>*/}
            {/*  <p className="font-medium text-base">92</p>*/}
            {/*</div>*/}
          </div>
        </div>

        {/* 첨부파일 */}
        <div className="flex items-center px-[30px] h-[60px] bg-[#f2f2f2] gap-2.5 text-neutral-500">
          <span className="font-bold text-lg">첨부파일</span>
          {homeworkAttachments.length > 0 ? (
            <div
              className="flex items-center gap-2.5 cursor-pointer"
              onClick={() => {
                if (homeworkAttachments[0]?.fileUrl) {
                  window.open(
                      homeworkAttachments[0].fileUrl.split('?')[0],
                    '_blank',
                    'noopener noreferrer',
                  );
                  return;
                }
              }}>
              <span className="font-medium text-base">
                {homeworkAttachments[0].fileName}
              </span>
              <DownloadIcon width={24} height={24} fill="#337ccf" />
            </div>
          ) : (
            <span className="text-base font-medium">없음</span>
          )}
        </div>
        {/* 본문 */}
        <div className="px-[35px] py-[30px] border-y border-gray-400 ">
          {homeworkDetail.homeworkContent}
        </div>
        {/* 과제 제출 */}
        <div className="w-full pl-[29px] pr-[39px] pt-[17px] pb-[20px]">
          <div className="flex justify-between items-center">
            <span className="text-3xl leading-[43px] font-bold text-[#404040]">
              과제 제출
            </span>
            {!homeworkDetail.submitHomeworkId && (
              <button
                type="button"
                onClick={() => modal.openModal({})}
                className={`flex items-center max-h-[50px] gap-2.5 px-[19px] py-[14px] border rounded-[30px] transition-all ease-in-out delay-50 bg-primary text-xl text-white whitespace-nowrap cursor-pointer hover:bg-[#2963A6]`}>
                <PencilIcon width={24} height={24} fill="white" />
                <p className="text-[20px] leading-[21px] font-bold">제출하기</p>
              </button>
            )}
          </div>
          {homeworkDetail.submitHomeworkId && (
            <div
              className="h-[60px] border rounded-[10px] bg-readOnly cursor-pointer flex justify-between items-center px-[30px] py-[15px] mt-6"
              onClick={() => {
                modal.openModal({})
              }}>
              <div className="font-bold text-xl text-[#404040]">
                {homeworkDetail.submitHomeworkTitle}
              </div>
              <div className="flex gap-[31px]">
                <div className="flex items-center gap-2">
                  <span className="text-[#737373] text-lg font-bold">
                    등록일
                  </span>
                  <span className="text-[#999999] text-base font-medium">
                    {replaceDateFormat(
                      homeworkDetail.submitHomeworkAt,
                      'YYYY-MM-DD',
                    )}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-[#737373] text-lg font-bold">
                    첨부파일
                  </span>
                  {homeworkDetail.attachments ? (
                    <span className="text-primary text-base font-medium">
                      <AttachmentIcon width={24} height={24} fill="#999999" />
                    </span>
                  ) : (
                    <span className="text-[#999999] text-base font-medium">
                      없음
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* 이전글 */}
        <div className="px-[35px] border-t border-gray-400 cursor-pointer hover:bg-readOnly">
          <div className="flex items-center h-[60px]  text-gray-400 mr-5">
            <div className="font-bold text-[18px] text-[#737373] mr-[27px] whitespace-nowrap">
              이전글
            </div>
            <div className="text-[16px]">
              {homeworkDetail.homeworkTitle ?? '이전글이 없습니다.'}
            </div>
          </div>
        </div>
        {/* 다음글 */}
        <div className="px-[35px] border-y border-gray-400 cursor-pointer hover:bg-readOnly">
          <div className="flex items-center h-[60px] text-gray-400 mr-5">
            <div className="font-bold text-[18px] text-[#737373] mr-[27px] whitespace-nowrap">
              다음글
            </div>
            <div className="text-[16px]">
              {homeworkDetail.homeworkTitle ?? '다음글이 없습니다.'}
            </div>
          </div>
        </div>
      </div>
      {homeworkDetail && (
        <HomeworkSubmitModal modal={modal} homework={homeworkDetail} />
      )}
    </>
  );
}
