import {
  answerRegisterState,
  applyRegisterState,
} from '@/stores/application/atom';

const applyState = {
  applyRegisterState,
  answerRegisterState,
};

export default applyState;
