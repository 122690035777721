import {Link} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import siteState from '@/stores/site';

export default function Footer() {
  const siteInfo = useRecoilValue(siteState.siteInfoState);
  if (!siteInfo) return null;
  const {withUs} = siteInfo.variation;
  const {footer} = siteInfo.variation;

  return (
    <footer>
      <div className="flex justify-center px-auto bg-white pt-[35px] pb-[46px]">
        <div className="max-w-[1180px] w-full">
          {/* 함께하는 분들 */}
          {withUs.length > 0 && (
            <div className={`flex justify-center flex-wrap gap-[7.5px] pb-[82px]`}>
              {withUs.map((item, index) => (
                <img
                  key={item.id}
                  src={item.url}
                  alt={`site_ci_${index + 1}`}
                  className="w-[230px] object-contain"
                />
              ))}
            </div>
          )}

          {/* 약관 및 정보 */}
          <div className="w-full flex flex-col items-center">
            <div className="w-full max-w-[450px] md:max-w-full flex flex-col md:flex-row justify-center">
              <div className="flex md:flex-col md: justify-center md:mr-[200px] gap-5">
                <Link
                  to="/privacy"
                  className="text-[20px] p-2.5 font-semibold text-[#337CCF] whitespace-nowrap">
                  개인정보처리방침
                </Link>
                <Link
                  to="/service"
                  className="text-[20px] p-2.5 font-semibold whitespace-nowrap">
                  이용약관
                </Link>
              </div>
              <div className="flex flex-col justify-center ">
                {footer.map((item: string) => {
                  return (
                    <p key={item} className="text-[20px] font-thin">
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
