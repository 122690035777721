import StarFull from '@/assets/img/star-full.png';
import StarGray from '@/assets/img/star-gray.png';
import StarHalf from '@/assets/img/star-half.png';

type StarResult = {
  fullStars: number;
  halfStars: number;
  grayStars: number;
};

// 별 등급을 계산하는 함수
function calculateStars(rating: number): StarResult {
  const fullStars: number = Math.floor(rating);
  const halfStars: number = rating % 1 !== 0 ? 1 : 0;
  const grayStars: number = 5 - fullStars - halfStars;

  return {
    fullStars,
    halfStars,
    grayStars,
  };
}

// 별을 렌더링하는 함수
function renderStars(rating: number): JSX.Element[] {
  const {fullStars, halfStars, grayStars} = calculateStars(rating);
  const elements: JSX.Element[] = [];

  for (let i = 0; i < fullStars; i++) {
    elements.push(
      <img
        key={`full-${i}`}
        src={StarFull}
        alt="full"
        className="w-[22px] h-[22px]"
      />,
    );
  }

  for (let i = 0; i < halfStars; i++) {
    elements.push(
      <img
        key={`half-${i}`}
        src={StarHalf}
        alt="half"
        className="w-[22px] h-[22px]"
      />,
    );
  }

  for (let i = 0; i < grayStars; i++) {
    elements.push(
      <img
        key={`gray-${i}`}
        src={StarGray}
        alt="gray"
        className="w-[22px] h-[22px]"
      />,
    );
  }

  return elements;
}

export default function StarRating({
  rating,
  classNames,
}: {
  rating: number;
  classNames?: string;
}) {
  return <div className={classNames}>{renderStars(rating)}</div>;
}
