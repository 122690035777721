import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import {PAGINATION_DATA} from '@/constants/common';
import {
  getBoardDetailService,
  getBoardList,
  getCommentsService,
} from '@/services/boardService';
import siteState from '@/stores/site';
import {siteInfoState} from '@/stores/site/atom';
import {ReqBoardListParams} from '@/types/boards.type';

export function useBoards({
  type,
  typeId,
  category,
  searchQuery,
}: ReqBoardListParams) {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 10});
  const siteData = useRecoilValue(siteState.siteInfoState);
  const siteId = siteData?.id;

  const {data, isLoading, refetch} = useQuery({
    queryKey: [
      `boardsList${category}`,
      {
        type,
        typeId,
      },
    ],
    queryFn: async () => {
      const params = {
        category,
        page: pageParams.page,
        size: pageParams.pageSize,
        author_uid: '',
        type: '',
        typeId: '',
        siteId,
        ...(searchQuery ? {title: searchQuery} : {}),
      };

      if (!siteId) {
        return PAGINATION_DATA;
      }

      if (type === 'QNA' && typeId) {
        // QNA things
        params.type = type;
        params.typeId = typeId;
      }

      return await getBoardList(params);
    },
    enabled: !!(type && typeId && category),
    initialData: PAGINATION_DATA,
  });

  useEffect(() => {
    refetch().then().catch();
  }, [pageParams]);

  function onChangePage(page: number) {
    setPageParams({
      ...pageParams,
      page,
    });
  }

  return {
    data: data?.content,
    noticeData: data!.content.Notice,
    policyData: data!.content.Policy,
    qnaData: data!.content.QNA,
    isLoading,
    paginationInfo: data!.page,
    onChangePage,
    refetch,
  };
}

export function useBoardDetail({boardId}: {boardId?: string}) {
  const {data, refetch, isLoading, isFetching} = useQuery({
    queryKey: ['boardDetail', {boardId}],
    queryFn: async () => {
      if (!boardId) return undefined;
      return await getBoardDetailService(boardId);
    },
    enabled: !!boardId,
  });
  return {
    data,
    refetch,
    isLoading: isLoading && isFetching,
  };
}

export function useBoardDetailWithComment({boardId}: {boardId?: string}) {
  const siteInfo = useRecoilValue(siteInfoState);
  const siteId = siteInfo?.id || '';

  const {
    data: boardDetailData,
    refetch: refetchBoardDetail,
    isLoading: isLoadingBoardDetail,
    isFetching: isFetchingBoardDetail,
  } = useQuery({
    queryKey: ['boardDetail', {boardId}],
    queryFn: async () => {
      if (!boardId) return undefined;
      return await getBoardDetailService(boardId);
    },
    enabled: !!boardId,
  });

  const {
    data: commentData,
    refetch: refetchComment,
    isLoading: isLoadingComment,
    isFetching: isFetchingComment,
  } = useQuery({
    queryKey: ['boardDetailComment', {boardId, siteId}],
    queryFn: async () => {
      if (!boardId || !siteId) return undefined;
      return await getCommentsService({boardId, siteId});
    },
    enabled: !!boardId && !!siteId,
  });

  const refetch = async () => {
    refetchBoardDetail().then().catch();
    refetchComment().then().catch();
  };

  return {
    boardDetailData,
    commentData,
    isLoading:
        (isLoadingBoardDetail || isLoadingComment) &&
        (isFetchingBoardDetail || isFetchingComment),
    refetch,
  };
}
