import React, {ReactNode, useMemo} from 'react';


export function TableRowEmpty({text}: {text: string}) {
  return (
    <>
      <tr className="w-full">
        <td colSpan={99} className="text-center text-xl text-[#808080]">
          <p className="pt-[45px] pb-[46px]">{text}</p>
        </td>
      </tr>
    </>
  );
}

export function NewTableHeader({
  children,
  hiddenMobile,
  hiddenTablet,
  addClass,
}: {
  children: ReactNode;
  hiddenMobile?: boolean;
  hiddenTablet?: boolean;
  addClass?: string;
}) {
  return (
    <th
      className={`
      whitespace-nowrap
      ${hiddenTablet ? 'hidden md:table-cell' : ''}
      ${hiddenMobile ? 'hidden lg:table-cell' : ''}
      text-xl text-center table-cell py-4 font-bold text-neutral-500 ${addClass}`}>
      {children}
    </th>
  );
}

export function NewTableRow({
  header,
  children,
  onClick,
  addClass = '',
}: {
  header?: boolean;
  children: ReactNode;
  addClass?: string;
  onClick?: () => void;
}) {
  const headerStyle = header ? 'border-b-[1.2px]' : '';

  return (
    <tr
      className={`
       border-b-[1.4px] border-[#bfbfbf] ${headerStyle} ${addClass}
       `}
      onClick={onClick}>
      {children}
    </tr>
  );
}

export function NewTableData({
  width,
  span,
  children,
  hiddenMobile,
  hiddenTablet,
  addClass,
}: {
  width?: number | string;
  span?: number;
  children: ReactNode;
  hiddenMobile?: boolean;
  hiddenTablet?: boolean;
  addClass?: string;
}) {
  const visibleStyle = useMemo(() => {
    if (hiddenTablet && hiddenMobile) {
      return 'hidden';
    }
    if (hiddenTablet) {
      return 'hidden md:table-cell';
    }
    if (hiddenMobile) {
      return 'hidden lg:table-cell';
    }
    return '';
  }, [hiddenMobile, hiddenTablet]);

  return (
    <td
      width={width}
      colSpan={span}
      className={`
      py-4 text-lg text-center text-[#666666] font-medium
      ${visibleStyle} ${addClass}`}>
      {children}
    </td>
  );
}
