import {AxiosResponse} from 'axios';

import {Api} from '@/App';

import {RegisterSurveyAnswerResponseProps} from '@/services/surveyService';
import {BaseResponse, PaginationResponseProps} from '@/types/common.type';
import {
  SurveyAnswerInfoProps,
  SurveyAnswerListInfoProps,
  SurveyInfo,
  SurveyListProps,
  SurveyTemplateRequestProps,
  SurveyTemplateResponseProps,
} from '@/types/survey.type';

export async function requestGetSurveyList(
  params?: SurveyListProps,
): Promise<AxiosResponse<BaseResponse<PaginationResponseProps<SurveyInfo>>>> {
  return await Api.get('/surveys', {params});
}

export async function requestGetSurveyTemplateList(
  params?: SurveyTemplateRequestProps,
): Promise<
  AxiosResponse<
    BaseResponse<PaginationResponseProps<SurveyTemplateResponseProps>>
  >
> {
  return await Api.get('/surveys/templates', {params});
}

export async function requestPostSurveyAnswerRegister(
  answers: SurveyAnswerInfoProps[],
  uid: string,
): Promise<
  AxiosResponse<
    BaseResponse<PaginationResponseProps<RegisterSurveyAnswerResponseProps>>
  >
> {
  return await Api.post(`/surveys/${uid}/answers`, {answers: answers});
}

export async function requestGetSurveyAnswerList(
  surveyId?: string,
  userId?: string,
): Promise<
  AxiosResponse<
    BaseResponse<PaginationResponseProps<SurveyAnswerListInfoProps>>
  >
> {
  return await Api.get(`/surveys/answers`, {
    params: {
      surveyId,
      userId,
    },
  });
}
