import React, {useEffect} from 'react';
import {useNavigate, useParams, useLocation, Link} from 'react-router-dom';

import {ReactComponent as DownloadIcon} from '@/assets/newImg/download.svg';
import {BoardLayout, NewPrimaryButton} from '@/components/common';
import Divider from '@/components/common/Divider';
import {ModalWrapper} from '@/components/common/NewModal';
import {useBoardDetailWithComment} from '@/hooks/useBoards';
import useModal from '@/hooks/useModal';
import {BoardList, Comment} from '@/types/boards.type';
import {Attachment} from '@/types/common.type';
import {replaceDateFormat} from '@/utils/format';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

type ParamsType = {
  item: BoardList;
  prevItem: BoardList;
  nextItem: BoardList;
};

export function NewQuestionCourseDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const {isShowing, closeModal, openModal} = useModal();
  const {
    item: questionItem,
    prevItem,
    nextItem,
  } = (location.state as ParamsType) || {
    item: undefined,
    prevItem: undefined,
    nextItem: undefined,
  };
  const {id: courseId} = useParams<{id: string}>();
  const {boardDetailData, commentData, isLoading, refetch} =
    useBoardDetailWithComment({
      boardId: courseId,
    });

  const handleOnClickAdjacentQuestion = (query: string) => {
    if (!query) {
      navigate('/question/course');
      return;
    }
    navigate(`/question/course/${query}`);
  };

  useEffect(() => {
    // 페이지 맨 위로
    window.scrollTo(0, 0);
    if (!courseId) {
      navigate('/question/course');
      return;
    }
    if (!questionItem) {
      refetch().then();
      return;
    }
  }, [courseId]);

  return (
    <>
      <BoardLayout
        boardType="question/course"
        boardName="교육문의"
        boardDetailName="게시글"
        bgWhite>
        <div className="my-[60px]">
          {boardDetailData && (
            <>
              {/* 제목, 정보 */}
              <Divider />
              <div className="px-[30px]">
                <div className="w-full py-7">
                  <h3 className="text-3xl font-bold text-neutral-700">
                    {boardDetailData.title}
                  </h3>
                </div>
                <div className="w-full flex items-center mb-8 gap-5 text-neutral-500">
                  <div className="flex items-center">
                    <p className="font-bold text-lg mr-2">등록일</p>
                    <p className="font-medium text-base">
                      {replaceDateFormat(
                        boardDetailData.createdAt,
                        'YYYY-MM-DD',
                      )}
                    </p>
                  </div>
                  {/*<div className="flex items-center">*/}
                  {/*  <p className="font-bold text-lg mr-[10px]">조회수</p>*/}
                  {/*  <p className="font-medium text-base">92</p>*/}
                  {/*</div>*/}
                </div>
              </div>
              {/* 첨부파일 */}
              <div className="flex items-center px-[30px] h-[60px] bg-[#f2f2f2] gap-2.5 text-neutral-500">
                <span className="font-bold text-lg">첨부파일</span>
                {boardDetailData.attachments.length > 0 ? (
                  boardDetailData.attachments.length === 1 ? (
                    <div
                      className="flex items-center gap-2.5 cursor-pointer"
                      onClick={() => {
                        window.open(
                          boardDetailData.attachments[0].fileUrl.split('?')[0],
                          '_blank',
                          'noopener noreferrer',
                        );
                      }}>
                      <span className="font-medium text-base">
                        {boardDetailData.attachments[0].fileName}
                      </span>
                      <DownloadIcon width={24} height={24} fill="#337ccf" />
                    </div>
                  ) : (
                    <button
                      onClick={() => openModal({})}
                      className="text-blue-500 text-base font-medium">
                      모두보기
                    </button>
                  )
                ) : (
                  <span className="text-base font-medium">없음</span>
                )}
              </div>
              <Divider />
              {/* 본문 */}
              <div className="px-[35px] pt-[30px] pb-[44px]">
                {parseHtmlContentWithSanitize(boardDetailData.content)}
              </div>
              <Divider />
              {/* 답글 */}
              {commentData &&
                commentData.content.length > 0 &&
                commentData.content.map((item: Comment, index: number) => (
                  <React.Fragment key={index}>
                    <div>
                      <div className="w-full flex flex-col px-[30px] bg-[#FBFBFB]">
                        <h3 className="text-3xl font-bold py-7">답변</h3>
                        <div className="w-full flex items-center px-[2px] pt-[2px] pb-[40px] gap-5 text-[#737373]">
                          <div className="flex items-center ">
                            <p className="font-bold text-lg mr-2">등록일</p>
                            <p className="text-base font-medium">
                              {replaceDateFormat(item.createdAt, 'YYYY-MM-DD')}
                            </p>
                          </div>
                          <div className="flex items-center ">
                            <p className="font-bold text-lg mr-2">답변자</p>
                            <p className="text-base font-medium">
                              {item.author.name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="px-[35px] pt-[30px] pb-[44px]">
                        {parseHtmlContentWithSanitize(item?.content)}
                      </div>
                    </div>
                    <Divider />
                  </React.Fragment>
                ))}
              {(prevItem || nextItem) && (
                <>
                  {/* 이전글 */}
                  <div className="flex items-center px-[35px] py-[17px] cursor-pointer hover:bg-readOnly">
                    <p className="font-bold text-lg text-neutral-500 mr-[27px] whitespace-nowrap">
                      이전글
                    </p>
                    <p
                      className="text-lg font-medium text-[#808080]"
                      onClick={() => {
                        handleOnClickAdjacentQuestion(prevItem?.id ?? '');
                      }}>
                      {prevItem?.title ?? '이전글이 없습니다.'}
                    </p>
                  </div>
                  <Divider />
                  {/* 다음글 */}

                  <div className="flex items-center px-[35px] py-[17px] cursor-pointer hover:bg-readOnly">
                    <p className="font-bold text-lg text-neutral-500 mr-[27px] whitespace-nowrap">
                      다음글
                    </p>
                    <p
                      className="text-lg font-medium text-[#808080]"
                      onClick={() => {
                        handleOnClickAdjacentQuestion(nextItem?.id ?? '');
                      }}>
                      {nextItem?.title ?? '다음글이 없습니다.'}
                    </p>
                  </div>
                  <Divider />
                </>
              )}
              {/* 목록으로 */}
              <div className="text-center py-[34px]">
                <Link to={'/question/course'}>
                  <NewPrimaryButton
                    addClass="w-[162px]"
                    text={'목록으로'}
                    button={'primary'}
                  />
                </Link>
              </div>
            </>
          )}
        </div>
        {!isLoading && !boardDetailData && (
          <div>잘못된 요청이거나 오류가 발생했습니다.</div>
        )}
      </BoardLayout>
      <ModalWrapper isShowing={isShowing}>
        {boardDetailData && (
          <div className="flex flex-col items-center justify-center pt-[46px] pb-[50px] px-[70px] ">
            <h2 className="text-black text-opacity-70 font-bold text-3xl mb-4">
              첨부파일 목록
            </h2>
            <ul className="w-full py-5">
              {boardDetailData.attachments.map(
                (attachment: Attachment, index: number) => {
                  const fileUrl = attachment?.fileUrl ?? '';
                  if (!fileUrl) {
                    return;
                  }
                  return (
                    <li
                      key={index}
                      className="flex items-center justify-between mb-2.5">
                      <p>{`파일명 : ${attachment.fileName}`}</p>
                      <div className="border rounded-[8px] bg-amber-50 p-2">
                        <a
                          href={fileUrl.split('?')[0]}
                          target="_blank"
                          rel="noopener noreferrer">
                          <DownloadIcon width={24} height={24} fill="#337ccf" />
                        </a>
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
            <NewPrimaryButton
              addClass="w-[162px]"
              text="닫기"
              button={'primary'}
              onClick={closeModal}
            />
          </div>
        )}
      </ModalWrapper>
    </>
  );
}
