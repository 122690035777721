import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import {useRecoilState} from 'recoil';

import {ReactComponent as CloseIcon} from '@/assets/newImg/close.svg';
import AnswerInput from '@/components/applicationForm/AnswerInput';
import {NewPrimaryButton} from '@/components/common';
import {Input} from '@/components/common/NewInput';
import {Modal, ModalWrapper} from '@/components/common/NewModal';
import useModal, {ModalProps} from '@/hooks/useModal';
import {
  getApplicationFormDetail,
  postFormAnswer,
} from '@/services/applicationService';
import {uploadAllTypeFileService} from '@/services/commonService';
import {
  applyCourseService,
  applyKacwCourseService,
} from '@/services/courseService';
import applyState from '@/stores/application';
import {AnswerFormProps, AnswerProps} from '@/types/application.type';
import {Course} from '@/types/course.type';
import {EMAIL_REGEX, NUMBER_REGEX} from '@/utils/regex';

export function CourseRegisterModal({
  modal,
  courseInfo,
  userName,
}: {
  modal: ModalProps;
  courseInfo: Course;
  userName: string;
}) {
  const {id: courseId, useApplicationForm} = courseInfo;

  const [uuid, setUuid] = useState('');
  const [applyRegisterState, setApplyRegisterState] = useRecoilState(
    applyState.applyRegisterState,
  );
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );

  const {
    isShowing: confirmShowing,
    modalData: confirmModalData,
    closeModal: confirmCloseModal,
    openModal: confirmOpenModal,
  } = useModal();
  const {isShowing, closeModal} = modal;
  const [attachedFile, setAttachedFile] = useState({
    id: '',
    fileName: '',
    fileUrl: '',
  });

  //  재직 증명서 파일업로드
  const onClickUploadFile = () => {
    uploadAllTypeFileService(
      {parentId: courseId, parentType: 'COURSE_APPLICATION'},
      ({fileId, fileName, fileUrl}) => {
        setAttachedFile({
          id: fileId,
          fileName: fileName,
          fileUrl: fileUrl,
        });
      },
    );
  };

  const {data: formData} = useQuery({
    queryKey: ['applicationForms', courseId],
    queryFn: async ({queryKey}) => {
      return await getApplicationFormDetail(queryKey[1] as string);
    },
  });

  useEffect(() => {
    return () =>
      document.body
        .querySelectorAll(':scope > input')
        .forEach(item => item.remove());
  }, []);

  useEffect(() => {
    if (!formData?.content?.[0]) {
      return;
    }

    const {
      id,
      surveySvrSurveyId,
      createdAt,
      updatedAt,
      title,
      description,
      ...rest
    } = formData.content[0];

    setUuid(id);

    if (!rest.applicationForm) return;

    const editData = rest.applicationForm.map(
      ({questionId, subQuestions, ...item}) => {
        const {...restItem} = item;
        if (subQuestions) {
          const updatedSubQuestions = subQuestions.map(({...subItem}) => {
            return subItem;
          });
          return {...restItem, subQuestions: updatedSubQuestions};
        } else {
          return {...restItem, subQuestions: null};
        }
      },
    );

    const answerRegister: AnswerFormProps = {
      answers: rest.applicationForm.map(({subQuestions, ...item}) => {
        const {...restItem} = item;
        return {
          ...restItem,
          questionId: item.questionId || '',
          answer: '',
          subQuestions: subQuestions
            ? subQuestions.map(({...subItem}) => {
                return {...subItem, answer: ''};
              })
            : null,
        } as AnswerProps;
      }),
    };

    setAnswerRegisterState(answerRegister);

    setApplyRegisterState({
      title: title,
      description: description,
      applicationForm: editData,
    });
  }, [formData]);

  const submitApplication = async () => {
    if (!courseId) {
      return;
    }

    let isRequiredFilled = true;
    for (const item of answerRegisterState.answers) {
      if (item.isRequired && item.answer.length === 0 && useApplicationForm) {
        isRequiredFilled = false;
        break;
      } else {
        if (item.subQuestions) {
          for (const subItem of item.subQuestions) {
            if (
              subItem.isRequired &&
              subItem.answer?.length === 0 &&
              useApplicationForm &&
              subItem.config &&
              subItem.config.ifConditionAnswerKey &&
              subItem.config.ifConditionAnswerKey.includes(
                item.answer[0]?.key,
              ) &&
              subItem.type !== ''
            ) {
              isRequiredFilled = false;
              break;
            }
          }
        }
      }
    }

    if (!isRequiredFilled) {
      confirmOpenModal({
        type: 'check',
        title: '확인',
        message: '필수 입력 항목을 전부 입력해주세요.',
      });
      return;
    }

    for (const item of answerRegisterState.answers) {
      if (item.subQuestions) {
        for (const subItem of item.subQuestions) {
          if (
            subItem.config.regex &&
            subItem.config.ifConditionAnswerKey &&
            subItem.config.ifConditionAnswerKey.includes(item.answer[0]?.key)
          ) {
            if (
              subItem.config.regex === 'EMAIL'
            ) {
              if (!EMAIL_REGEX.test(subItem.answer)) {
                confirmOpenModal({
                  type: 'check',
                  title: '확인',
                  message: '이메일 형식에 맞게 입력해주세요.',
                });
                return;
              }
            } else if (subItem.config.regex === 'NUMBER') {
              if (!NUMBER_REGEX.test(subItem.answer)) {
                confirmOpenModal({
                  type: 'check',
                  title: '확인',
                  message: '숫자만 입력해주세요.',
                });
                return;
              }
            }
          }
        }
      } else {
        if (item.config.regex) {
          if (item.config.regex === 'EMAIL') {
            if (!EMAIL_REGEX.test(item.answer)) {
              confirmOpenModal({
                type: 'check',
                title: '확인',
                message: '이메일 형식에 맞게 입력해주세요.',
              });
              return;
            }
          } else if (item.config.regex === 'NUMBER') {
            if (!NUMBER_REGEX.test(item.answer)) {
              confirmOpenModal({
                type: 'check',
                title: '확인',
                message: '숫자만 입력해주세요.',
              });
              return;
            }
          }
        }
      }
    }

    for (const item of answerRegisterState.answers) {
      if (item.config.minLength && item.answer.length < item.config.minLength) {
        confirmOpenModal({
          type: 'check',
          title: '확인',
          message: `${item.seq + 1}번 항목 \n최소 ${
            item.config.minLength
          }자 이상 입력해주세요.`,
        });
        return;
      } else if (item.subQuestions) {
        for (const subItem of item.subQuestions) {
          if (
            subItem.config.minLength &&
            subItem.answer.length < subItem.config.minLength &&
            subItem.config.ifConditionAnswerKey &&
            subItem.config.ifConditionAnswerKey.includes(item.answer[0]?.key) &&
            subItem.type !== ''
          ) {
            confirmOpenModal({
              type: 'check',
              title: '확인',
              message: `${item.seq + 1}-${subItem.seq + 1}번 항목 \n최소 ${
                subItem.config.minLength
              }자 이상 입력해주세요.`,
            });
            return;
          }
        }
      }
    }

    try {
      if (!useApplicationForm) {
        try {
          const answerIdResult = await applyCourseService(courseId);
          if (answerIdResult) {
            confirmOpenModal({
              type: 'check',
              title: '성공',
              message: '강의 신청이 완료되었습니다.',
            });
          }
        } catch (e) {
          throw new Error('강의 신청에 실패했습니다.');
        }
      } else {
        try {
          const result = await postFormAnswer(uuid, answerRegisterState);
          if (result.data.result) {
            try {
              const answerIdResult = await applyCourseService(
                courseId,
                result.data.id,
              );
              if (answerIdResult) {
                confirmOpenModal({
                  type: 'check',
                  title: '성공',
                  message: '강의 신청이 완료되었습니다.',
                });
              }
            } catch (e) {
              throw new Error('강의 신청에 실패했습니다.');
            }
          }
        } catch (e) {
          throw new Error('강의 신청에 실패했습니다.');
        }
      }
    } catch (e: any) {
      confirmOpenModal({
        type: 'error',
        title: '실패',
        message: e.message ?? '강의 신청에 실패했습니다.',
      });
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <ModalWrapper isShowing={isShowing}>
        <div className="pt-[46px] pb-[40px] pl-[70px] pr-[70px] relative">
          <div
            className="absolute top-[46px] right-[46px] cursor-pointer"
            onClick={closeModal}>
            <CloseIcon width={24} height={24} fill="black" />
          </div>
          <h3 className="font-bold text-3xl mb-8">
            {useApplicationForm ? applyRegisterState.title : courseInfo.name}
          </h3>
          {useApplicationForm ? (
            <div className="w-full flex flex-col gap-3 mb-10">
              <div className="relative">
                <Input
                  id="name"
                  value={userName}
                  label="이름"
                  disabled={true}
                  labelStyle={{color: '#404040'}} // TODO: twin.macro 도입 검토
                  addClass="bg-readOnly"
                />
                <span className="absolute font-medium top-[7px] left-[44px] text-[#FF5353] text-[11px]">
                  본인 확인용이므로 회원가입 시 입력했던 이름으로 자동 설정되어
                  수정이 불가능합니다.
                </span>
              </div>
              {applyRegisterState.applicationForm.map((item, index) => (
                <div key={index} className="relative">
                  <AnswerInput
                    index={index}
                    label={item.question}
                    labelStyle={{color: '#404040'}}
                    courseId={courseId}
                  />
                  {item.subQuestions
                    ?.filter(
                      item =>
                        item.question &&
                        item.config.ifConditionAnswerKey &&
                        item.type,
                    )
                    .map((subItem, subIndex) => (
                      <div key={subIndex}>
                        <AnswerInput
                          index={subIndex}
                          label={subItem.question}
                          labelStyle={{color: '#404040'}}
                          courseId={courseId}
                          questionIndex={index}
                          upperQuestion={item}
                        />
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full flex flex-col gap-3 mb-10">
              <div className="relative">
                <Input
                  id="name"
                  value={userName}
                  label="이름"
                  disabled={true}
                  labelStyle={{color: '#404040'}} // TODO: twin.macro 도입 검토
                  addClass="bg-readOnly"
                />
                <span className="absolute font-medium top-[7px] left-[44px] text-[#FF5353] text-[11px]">
                  본인 확인용이므로 회원가입 시 입력했던 이름으로 자동 설정되어
                  수정이 불가능합니다.
                </span>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-[24px] font-medium">
                  강의 신청하시겠습니까?
                </div>
              </div>
            </div>
          )}
          <NewPrimaryButton
            button="primary"
            type="submit"
            onClick={submitApplication}
            text="강의 신청하기"
            addClass="text-xl w-full h-[60px]"
          />
        </div>
      </ModalWrapper>
      <Modal
        isShowing={confirmShowing}
        type="alert"
        title={confirmModalData?.title}
        message={confirmModalData?.message}
        buttons={
          confirmModalData?.buttons || [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                confirmCloseModal();
              },
            },
          ]
        }
      />
    </>
  );
}
