import React, {useState, ChangeEvent} from 'react';
import {Link} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import {NewLayout, FormLayout} from '@/components/common';
import {NewPrimaryButton as PrimaryButton} from '@/components/common/NewButton';
import {Input} from '@/components/common/NewInput';
import {
  requestSmsCodeService,
  validateSmsCodeService,
  findEmailService,
} from '@/services/userService';
import siteState from '@/stores/site';
import {encodeHyphenPhoneNumber, decodeHyphenPhoneNumber} from '@/utils/format';
import {ValidatePhoneSchema} from '@/utils/validator';

export function NewUserFindEmail() {
  const siteData = useRecoilValue(siteState.siteInfoState);
  const [phone, setPhone] = useState('');
  const [phoneCaption, setPhoneCaption] = useState('');
  const [code, setCode] = useState('');
  const [codeCaption, setCodeCaption] = useState('');
  const [isSentCode, setIsSentCode] = useState(false);
  const [showSendButton, setShowSendButton] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [email, setEmail] = useState('');

  if (!siteData) return null;

  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const newValue = encodeHyphenPhoneNumber(value);
    setPhone(newValue);

    try {
      if (newValue.length === 0) {
        setShowSendButton(false);
      } else {
        setShowSendButton(true);
        ValidatePhoneSchema.validateSync(decodeHyphenPhoneNumber(phone));
        setPhoneCaption('');
      }
    } catch (e: any) {
      setPhoneCaption(e.message);
    }
  }

  function handleCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setCode(e.target.value);
  }

  async function sendVerificationCode() {
    const decodedPhone = decodeHyphenPhoneNumber(phone);
    try {
      ValidatePhoneSchema.validateSync(decodedPhone);
      setPhoneCaption('');
    } catch (e: any) {
      setPhoneCaption(e.message);
      return;
    }

    try {
      await requestSmsCodeService(siteData!.id, 'FIND_EMAIL', decodedPhone);
      setIsSentCode(true);
      alert('인증 문자가 발송되었습니다.');
    } catch (e: any) {
      alert(e.data.error.message);
    }
  }

  async function verifyVerificationCode() {
    if (code.length === 0) {
      setCodeCaption('인증번호를 입력해주세요.');
      return;
    }

    const decodedPhone = decodeHyphenPhoneNumber(phone);

    try {
      const verifiedId = await validateSmsCodeService(
        siteData!.id,
        'FIND_EMAIL',
        decodedPhone,
        code,
      );
      setIsPhoneChecked(true);
      setUserEmail(verifiedId);
      setCodeCaption('');
    } catch (e: any) {
      setCodeCaption(e.data.error.message);
    }
  }

  async function setUserEmail(verifiedId: string) {
    const decodedPhone = decodeHyphenPhoneNumber(phone);
    try {
      const userEmail = await findEmailService(
        siteData!.id,
        decodedPhone,
        verifiedId,
      );
      setEmail(userEmail);
    } catch (e: any) {
      alert(e.data.error.message);
    }
  }

  return (
    <NewLayout>
      <FormLayout>
        <>
          <h3 className="font-bold text-[30px] mb-8">이메일 찾기</h3>
          <form className="w-full flex flex-col items-center">
            <Input
              id="phone"
              type="text"
              placeholder="휴대폰 번호를 입력하세요."
              label="휴대폰 번호"
              value={phone}
              maxLength={13}
              onChange={handlePhoneChange}
              onKeyDown={async (
                event: React.KeyboardEvent<HTMLInputElement>,
              ) => {
                if (event.key === 'Enter') await sendVerificationCode();
              }}
              addClass={
                isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
              }
              rightButton={
                !isPhoneChecked &&
                (showSendButton ? (
                  !isSentCode ? (
                    <PrimaryButton
                      type="button"
                      button="primary"
                      onClick={sendVerificationCode} // 폰명의 체크 api 호출, 번호 유효하면 isPhoneChecked true
                      text="인증번호 전송"
                      addClass={`w-[162px]`}
                    />
                  ) : (
                    <PrimaryButton
                      type="button"
                      button="primary"
                      onClick={sendVerificationCode}
                      text="인증번호 재전송"
                      addClass={`w-[162px] ${
                        isPhoneChecked
                          ? 'hidden'
                          : 'hover:bg-secondary hover:text-white'
                      }`}
                    />
                  )
                ) : (
                  <PrimaryButton
                    type="button"
                    button="disabled"
                    onClick={sendVerificationCode}
                    text="인증번호 전송"
                    addClass={`w-[162px]`}
                  />
                ))
              }
            />
            {phoneCaption ? (
              <p className="text-rose-500 w-full text-sm mb-5">
                {phoneCaption}
              </p>
            ) : (
              <div className="mb-6" />
            )}

            <>
              <Input
                id="code"
                type="text"
                placeholder="인증번호를 입력하세요."
                label="인증번호"
                value={code}
                onChange={handleCodeChange}
                onKeyDown={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') await verifyVerificationCode();
                }}
                addClass={
                  isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                }
                rightButton={
                  <>
                    {!isPhoneChecked ? (
                      !isSentCode ? (
                        <PrimaryButton
                          type="button"
                          button="disabled"
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      ) : (
                        <PrimaryButton
                          type="button"
                          button="primary"
                          onClick={verifyVerificationCode}
                          text="인증번호 확인"
                          addClass={`w-[162px]`}
                        />
                      )
                    ) : (
                      <PrimaryButton
                        type="button"
                        button="success"
                        onClick={verifyVerificationCode}
                        text="인증 완료"
                        addClass={`w-[162px]`}
                      />
                    )}
                  </>
                }
              />
              {codeCaption ? (
                <p className="text-rose-500 w-full text-sm mb-5">
                  {codeCaption}
                </p>
              ) : (
                <div className="mb-6" />
              )}
            </>
          </form>
          {email && isPhoneChecked && (
            <div className="text-center flex items-center justify-center text-xl mb-8 gap-1 flex-wrap">
              <span className="block md:inline-block text-gray-500 whitespace-nowrap">
                등록된 이메일은
              </span>
              <span className="text-xl font-bold text-primary block my-2 md:inline-block break-all">
                {email}
              </span>
              <span className="block md:inline-block text-gray-500 whitespace-nowrap">
                입니다.
              </span>
            </div>
          )}
        </>
        {/* 로그인 관련 페이지 이동 버튼 */}
        <div className="w-full mt-[25px] mb-1 px-[75px] flex justify-between items-center">
          <Link
            to="/new-sign-up"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            회원가입
          </Link>
          <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
          <Link
            to="/new-sign-in"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            로그인
          </Link>
          <span className="text-[15px] font-medium text-[#b4b4b4]">|</span>
          <Link
            to="/new-user/find/password"
            className="text-[15px] font-medium text-[#b4b4b4] hover:underline">
            비밀번호찾기
          </Link>
        </div>
      </FormLayout>
    </NewLayout>
  );
}
