import {AxiosResponse} from "axios";

import {Api} from '@/App';

import {HomeWork, HomeworkDetailInfo,Chapter} from "@/types/chapter.type";
import {BaseResponse, PaginationResponseProps} from "@/types/common.type";

export function reqGetChapter(chapterId: string) {
  return Api.get(`/chapters/${chapterId}`);
}

// TODO: Pagination 로직 개선하기
export function reqGetChapterList(
    lectureId: string,
): Promise<AxiosResponse<BaseResponse<PaginationResponseProps<Chapter>>>> {
  return Api.get(`/lectures/${lectureId}/chapters`, {
    params: {
      pageSize: 1000,
      page: 1,
    },
  });
}

export function reqSavePlayTimeHistory(
  chapterId: string,
  eventType: string,
  eventTime: number,
) {
  return Api.post(`/chapters/${chapterId}/play-time-histories`, {
    eventType,
    eventTime,
  });
}

export function reqHomeworkList() {
  return Api.get('/homeworks/submit', {
    params: {
      submit: 0,
    },
  });
}

export function reqMyHomeworkList() {
  return Api.get('/homeworks/submit', {
    params: {
      submit: 1,
    },
  });
}

export function reqGetHomework(chapterId: string) {
  return Api.get('/homeworks/submit', {
    params: {
      chapterId: chapterId,
    },
  });
}

interface ReqGetHomeworkListParams {
  page: number;
  pageSize: number;
  searchQuery?: string;
}

// chapter별 homework 정보
export function reqNewGetHomework(
  chapterId: string,
  params: ReqGetHomeworkListParams,
):Promise<AxiosResponse<BaseResponse<PaginationResponseProps<HomeWork>>>> {
  return Api.get(`/chapters/${chapterId}/homeworks`, {
    params,
  });
}

// homework 제출 정보
export function reqNewGetSubmitHomework({
  chapterId,
  homeworkId,
  userId,
  pageInfo,
}: {
  chapterId: string;
  homeworkId?: string;
  userId?: string;
  pageInfo: {
    page: number;
    pageSize: number;
  };
}) :Promise<AxiosResponse<BaseResponse<PaginationResponseProps<HomeworkDetailInfo>>>> {
  return Api.get(`/homeworks/submit`, {
    params: {
      chapterId,
      homeworkId,
      userId,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    },
  });
}
