import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  TableRowEmpty,
  Skeleton,
  Pagination,
  BoardLayout,
  NewInputSearch,
  PaginationInfo,
  NewTableHeader,
  NewTableRow,
  NewTableData,
} from '@/components/common';
import {Modal} from '@/components/common/NewModal';
import {useCourseGetCertificate} from '@/hooks/useCourse';
import useModal from '@/hooks/useModal';
import {useMyCourse} from '@/hooks/useMyCourse';
import {CourseWithProgress} from '@/types/course.type';
import {genTableReverseIndex, replaceDateFormat} from '@/utils/format';

function DownloadButton({
  status,
  certificateIssuer,
  certificateFileUrl,
  onClick,
}: {
  status: boolean;
  certificateIssuer?: string;
  certificateFileUrl?: string;
  onClick: () => void;
}) {
  const isPrintable =
    status &&
    (certificateIssuer === 'ALL' ||
      (certificateIssuer === 'ONLY_ADMIN' && certificateFileUrl)); // 출력 가능한지

  const statusText = status
    ? certificateIssuer === 'ONLY_ADMIN'
      ? certificateFileUrl
        ? '수료증 다운로드'
        : '수료증 발급'
      : certificateFileUrl
      ? '수료증 다운로드'
      : '수료증 발급'
    : '미수료';

  return (
    <div className="flex justify-center">
      <div
        className={`cursor-default w-max whitespace-nowrap py-[7px] px-[13px] rounded-[30px] h-max-[34px] ${
          isPrintable ? 'bg-[#337CCF] cursor-pointer' : 'bg-[#BFBFBF]'
        }`}
        onClick={() => {
          if (isPrintable) {
            onClick();
          }
        }}>
        <p className="text-white text-sm font-bold">{statusText}</p>
      </div>
    </div>
  );
}

export function NewCertificate() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');

  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {
    myCourseList,
    isLoading,
    paginationInfo,
    onChangePage,
    refetchMyCourses,
  } = useMyCourse(searchQuery);

  const {
    mutateAsync,
    data: certificateData,
    status,
    reset,
  } = useCourseGetCertificate();

  const handleOnDownloadCertificate = async (courseId: string) => {
    await mutateAsync(courseId).catch(e => {
      openModal({
        type: 'error',
        title: '수료증 발급',
        message: e.message || '수료증 발급에 문제가 발생했습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              reset();
            },
          },
        ],
      });
    });
  };

  const searchCourseList = (query: string) => {
    if (!query) {
      navigate('/certificate');
      return;
    }
    navigate(`/certificate?search=${query}`);
  };
  useEffect(() => {
    if (status === 'idle') return;
    if (status === 'loading') {
      setTimeout(() => {
        refetchMyCourses();
      }, 2000);
      openModal({
        type: 'alert',
        title: '수료증 발급',
        message: '수료증을 생성중입니다.',
        buttons: [
          {
            text: '잠시만 기다려주세요.',
            style: 'normal',
            onClick: () => {
              closeModal();
              reset();
            },
          },
        ],
      });
      return;
    }
    if (status === 'success' && certificateData) {
      openModal({
        type: 'alert',
        title: '수료증 다운로드',
        message: '수료증을 다운로드하시겠습니까?',
        buttons: [
          {
            text: '취소',
            style: 'cancel',
            onClick: () => {
              closeModal();
              reset();
            },
          },
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              window.open(certificateData.certificateUrl, '_blank');
              closeModal();
              reset();
            },
          },
        ],
      });
    } else {
      openModal({
        type: 'alert',
        title: '수료증 발급',
        message: `수료증 발급에 문제가 발생했습니다.\n재시도해주세요.`,
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              reset();
            },
          },
        ],
      });
    }
  }, [certificateData, status]);

  return (
    <>
      <BoardLayout boardName="수료증" boardType="certificate" bgWhite>
        <div className="w-full flex flex-col items-center">
          <NewInputSearch
            placeholder="검색어를 입력해주세요"
            onSearch={searchCourseList}
          />
        </div>
        <PaginationInfo paginationInfo={paginationInfo} />

        <table className="w-full">
          <thead className="bg-[#f2f2f2] ">
            <NewTableRow header>
              <NewTableHeader addClass="w-[96px]">번호</NewTableHeader>
              <NewTableHeader>교육명</NewTableHeader>
              <NewTableHeader addClass="w-[128px]">교육기간</NewTableHeader>
              <NewTableHeader addClass="w-[100px]">수강률</NewTableHeader>
              <NewTableHeader addClass="w-[100px]">출석률</NewTableHeader>
              <NewTableHeader addClass="w-[100px]">과제수행률</NewTableHeader>
              <NewTableHeader addClass="w-[156px]">수료증</NewTableHeader>
            </NewTableRow>
          </thead>
          <tbody className=" border-b-[1.4px] border-[#bfbfbf]">
            {!isLoading && myCourseList && myCourseList.length > 0 ? (
              myCourseList.map((item: CourseWithProgress, index: number) => {
                const rowReverseIndex = genTableReverseIndex(
                  paginationInfo.currentPage,
                  paginationInfo.size,
                  index,
                  paginationInfo.total,
                );
                // 수료증 출력 가능 여부
                const {
                  progressRate = 0,
                  attendRate = 0,
                  homeworkRate = 0,
                } = item;
                const isCertificateIssuable =
                  item.completeDate ||
                  (progressRate === 100 &&
                    attendRate === 100 &&
                    homeworkRate === 100);

                return (
                  <NewTableRow key={index}>
                    <NewTableData addClass="text-[18px]">
                      <p>{rowReverseIndex}</p>
                    </NewTableData>
                    <NewTableData addClass="text-start">
                      {item.name}
                    </NewTableData>
                    <NewTableData addClass="whitespace-nowrap text-[16px]">
                      <p>{replaceDateFormat(item.startsAt, 'YYYY.MM.DD')}</p>
                      <p>~</p>
                      <p>{replaceDateFormat(item.endsAt, 'YYYY.MM.DD')}</p>
                    </NewTableData>
                    <NewTableData>{progressRate}%</NewTableData>
                    <NewTableData>{attendRate}%</NewTableData>
                    <NewTableData hiddenTablet>{homeworkRate}%</NewTableData>
                    <NewTableData addClass="">
                      <DownloadButton
                        status={!!(isCertificateIssuable && item.id)}
                        certificateIssuer={
                          myCourseList?.[index]?.certificateIssuer
                        }
                        certificateFileUrl={
                          myCourseList?.[index]?.certificateFileUrl
                        }
                        onClick={
                          myCourseList?.[index]?.certificateFileUrl
                            ? () =>
                                window.open(
                                  myCourseList?.[index]?.certificateFileUrl,
                                  '_blank',
                                )
                            : async () => {
                                await handleOnDownloadCertificate(item.id);
                              }
                        }
                      />
                    </NewTableData>
                  </NewTableRow>
                );
              })
            ) : (
              <TableRowEmpty text="검색 결과 없음" />
            )}

            {isLoading && (
              <tr className="w-full">
                <td colSpan={99} className="text-center h-40">
                  <Skeleton />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="mt-[30px] mb-[100px]">
          <Pagination
            totalCount={paginationInfo.total || 1}
            pageSize={paginationInfo.size}
            currentPage={paginationInfo.currentPage}
            totalPage={paginationInfo.totalPages || 1}
            onClick={onChangePage}
          />
        </div>
      </BoardLayout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
    </>
  );
}
