import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useRecoilValue} from 'recoil';

import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {useSitePreference} from '@/hooks/useSitePreference';
import useUser from '@/hooks/useUser';
import siteState from '@/stores/site';
import {clearLoginStatus} from '@/utils/common';

export default function Nav({
  isMobile,
  setIsMobileMenuOpen,
}: {
  isMobile: boolean;
  setIsMobileMenuOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {user, userError} = useUser();
  const siteNav = useRecoilValue(siteState.siteNavState);
  const siteInfo = useRecoilValue(siteState.siteInfoState);
  const [mobileNav, setMobileNav] = useState<{name: string; path: string}[]>(
    [],
  );
  const preferences = useSitePreference({siteId: siteInfo?.id ?? ''});

  const isLoggedIn = !!localStorage.getItem('at');

  const navWrapperClass = isMobile
    ? ' top-16 border-b-2 border-t-2 border-gray-200'
    : 'hidden md:flex md:items-center';
  const navItemClass = isMobile
    ? 'w-full text-md hover:text-primary duration-100 text-center py-3 hover:bg-gray-100 cursor-pointer bg-white'
    : 'text-[22px] whitespace-nowrap hover:text-primary duration-100 nav-item';

  useEffect(() => {
    if (userError) {
      if (userError?.code === '1014' && localStorage.getItem('at')) {
        openModal({
          title: '알림',
          message:
            '최근 로그인 시도로 인해 로그아웃 되었습니다. <br>다시 로그인해주세요.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                clearLoginStatus();
                navigate('/sign-in');
                closeModal();
              },
            },
          ],
        });
      }
    }
  }, [userError]);

  function onLogout() {
    clearLoginStatus();
    openModal({
      title: '로그아웃',
      message: '정상적으로 로그아웃되었습니다.',
      buttons: [
        {
          text: '확인',
          style: 'normal',
          onClick: () => {
            closeModal();
            navigate('/');
          },
        },
      ],
    });
  }

  const handleCloseMenu = () => {
    if (setIsMobileMenuOpen) setIsMobileMenuOpen(false);
  };

  function onClickPayment() {
    if (!user || user?.id === '') {
      openModal({
        title: '수강권 결제',
        message: '로그인이 필요한 서비스입니다.',
      });
    } else {
      openModal({
        title: '수강권 결제',
        message: '수강권을 결제하시겠습니까?',
        buttons: [
          {
            text: '취소',
            style: 'normal',
            onClick: () => {
              closeModal();
            },
          },
          {
            text: '결제하기',
            style: 'normal',
            onClick: () => {
              closeModal();
              navigate('/pay-results');
            },
          },
        ],
      });
    }
  }

  useEffect(() => {
    if (isMobile) {
      if (isLoggedIn) {
        setMobileNav(() => [
          ...siteNav,
          {name: '마이페이지', path: '/user'},
          // {name: '마이페이지', path: '/user/update'},
        ]);
        return;
      }
      setMobileNav(() => [
        ...siteNav,
        {name: '로그인/회원가입', path: '/new-sign-in'},
      ]);
    }
  }, [isMobile, isLoggedIn]);

  return (
    <>
      <Modal
        isShowing={isShowing}
        type="alert"
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                closeModal();
              },
            },
          ]
        }
      />
      <nav className="z-40">
        <ul className={`${navWrapperClass} font-bold gap-5`}>
          {isMobile
            ? mobileNav.map(item => (
                <Link to={item.path} key={item.name} onClick={handleCloseMenu}>
                  <li className={`${navItemClass}`}>{item.name}</li>
                </Link>
              ))
            : siteNav.map(item => (
                <Link to={item.path} key={item.name} onClick={handleCloseMenu}>
                  <li className={`${navItemClass}`}>{item.name}</li>
                </Link>
              ))}
          {isMobile && isLoggedIn && (
            <li className={`${navItemClass}`}>
              <button onClick={onLogout}>로그아웃</button>
            </li>
          )}
          {/*{preferences.sitePreference?.payments === true && (*/}
          {/*  <li className={`text-[22px] mr-[30px] whitespace-nowrap`}>*/}
          {/*    <button onClick={onClickPayment}>수강권 결제</button>*/}
          {/*  </li>*/}
          {/*)}*/}
        </ul>
      </nav>
    </>
  );
}
