import React, {ChangeEvent, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as ArrowThinIcon} from '@/assets/newImg/arrow-right-thin-s.svg';
import {
  BoardContentEditor,
  BoardLayout,
  NewPrimaryButton,
} from '@/components/common';
import Divider from '@/components/common/Divider';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {useMyCourse} from '@/hooks/useMyCourse';
import {submitQuestionService} from '@/services/boardService';
import {Course} from '@/types/course.type';

export function NewQuestionWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseIdQuery = queryParams.get('id');

  const {isShowing, closeModal, modalData, openModal} = useModal();

  const {myCourseList, isLoading: isLoadingCourse} = useMyCourse();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [viewDropdown, setViewDropdown] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({
    id: '',
    name: '',
  });

  function handleTitleInput(e: ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewDropdown(!viewDropdown);
  };
  const handleOnSelectCourse = (item: {id: string; name: string}) => {
    setSelectedCourse({
      id: item.id,
      name: item.name,
    });
    setViewDropdown(false);
  };

  const submitQuestion = async () => {
    if (!selectedCourse.id) {
      openModal({
        type: 'error',
        message: '강의를 선택해주세요.',
      });
      return;
    }
    if (!title) {
      openModal({
        type: 'error',
        message: '제목을 입력해주세요.',
      });
      return;
    }
    if (!content) {
      openModal({
        type: 'error',
        message: '문의 내용을 작성해주세요.',
      });
      return;
    }

    const submitData = {
      title,
      content,
      course: selectedCourse.id,
    };
    try {
      await submitQuestionService(submitData);
      openModal({
        type: 'check',
        message: '문의접수가 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => navigate('/question/course'),
          },
        ],
      });
    } catch (e: any) {
      const errorMessage = e.data?.error.message;
      openModal({
        type: 'error',
        message: errorMessage || '문제가 발생했습니다.',
      });
    }
  };

  useEffect(() => {
    if (!courseIdQuery) return;
    if (myCourseList && myCourseList.length > 0) {
      setSelectedCourse({
        id: courseIdQuery,
        name:
          myCourseList.find((item: Course) => item.id === courseIdQuery)
            ?.name || '',
      });
    }
  }, [courseIdQuery, myCourseList]);

  return (
    <BoardLayout
      boardType="question/course"
      boardName="교육문의"
      boardDetailName="글쓰기">
      {/* 모달 */}
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="pt-[70px] pb-[145px]">
        <div className="px-[70px] pt-[52px] pb-[69px] border-gray-200 rounded-[30px] bg-white course-shadow overflow-hidden">
          {/* 교육 선택 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">
              교육 선택
            </h5>
            <div
              className={
                'relative w-full h-[60px] flex items-center rounded-[10px] border-[#e5e5e5] border px-[30px] py-[18px] '
              }>
              <button
                className={'w-full flex items-center justify-between'}
                onClick={handleButtonClick}>
                <p className="text-base font-normal text-[#404040] whitespace-nowrap">
                  {selectedCourse.name || '교육 선택'}
                </p>
                <ArrowThinIcon
                  width={19}
                  height={19}
                  fill="#404040"
                  className={`"transform-gpu" ${
                    viewDropdown ? 'rotate-[270deg]' : 'rotate-90'
                  }`}
                />
              </button>
              {viewDropdown && (
                <div
                  className={
                    'absolute w-full top-[64px] left-0 flex flex-col bg-white border border-[#e5e5e5] rounded-[10px] z-[10] overflow-hidden '
                  }>
                  {!isLoadingCourse &&
                    myCourseList &&
                    myCourseList.length > 0 &&
                    myCourseList.map((item: Course) => (
                      <>
                        <button
                          className={
                            'text-start text-[18px] text-[#404040] hover:bg-gray-200 px-[30px] py-[18px] '
                          }
                          key={item.id}
                          onClick={() => handleOnSelectCourse(item)}>
                          {item.name}
                        </button>
                        <Divider borderColor="border-[#e5e5e5]" />
                      </>
                    ))}
                  <button
                    className={
                      'text-start text-[18px] text-[#404040] hover:bg-gray-200 px-[30px] py-[18px] '
                    }
                    onClick={() => {
                      handleOnSelectCourse({
                        id: 'etc',
                        name: '기타',
                      });
                    }}>
                    기타
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* 제목 입력 */}
          <div className="mb-[15px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">제목</h5>
            <div
              className={`flex justify-between w-full bg-white h-[60px] px-7 py-[17px] items-center rounded-[10px] border border-[#e5e5e5] focus-within:border focus-within:border-primary`}>
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleTitleInput}
                className="appearance-none border-none p-0 w-full bg-transparent text-black placeholder:text-lg placeholder-[#b4b4b4] focus:ring-0 text-lg"
                placeholder="문의 제목을 입력하세요."
              />
            </div>
          </div>
          {/* 문의 내용 작성 에디터 */}
          <div className="mb-[67px]">
            <h5 className="text-lg font-medium text-[#808080] mb-2.5">내용</h5>
            <BoardContentEditor value={content} onChange={setContent} />
          </div>
          {/* 버튼 항목 */}
          <div className="w-full flex items-center justify-center gap-7">
            <NewPrimaryButton
              button="cancel"
              addClass="cursor-pointer w-[162px]"
              text="취소"
              onClick={() => navigate('/question/course')}
            />
            <NewPrimaryButton
              button="primary"
              onClick={submitQuestion}
              text="문의하기"
              addClass=" w-[162px]"
            />
          </div>
        </div>
      </div>
    </BoardLayout>
  );
}
