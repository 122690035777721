import {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {useRecoilState, useSetRecoilState} from 'recoil';

import ScrollToTop from '@/components/common/ScrollToTop';
import NewAskPage from '@/pages/ask';
import {NewCertificate} from '@/pages/certificate';
import {NewCourse, NewCourseDetail, NewHomework} from '@/pages/new-course';
import {NewLecture} from '@/pages/new-lecture';
import {Main as NewMain} from '@/pages/new-main';
import {NewNotice, NewNoticeDetail} from '@/pages/new-notice';
import {
  NewFAQ,
  NewFAQDetail,
  NewQuestionCourse,
  NewQuestionCourseDetail,
  NewQuestionWrite,
} from '@/pages/new-question';
import {NewRegister, NewRegisterDetail} from '@/pages/new-register';
import {
  NewSignUp,
  NewSignIn,
  MyPage,
  UserUpdate as NewUserUpdate,
  UserWithDraw as NewUserWithDraw,
} from '@/pages/new-user';
import {NewUserFindEmail} from '@/pages/new-user/NewUserFindEmail';
import {NewUserFindPassword} from '@/pages/new-user/NewUserFindPassword';
import {NotFound} from '@/pages/NotFound';
import {PayResults} from '@/pages/pay-results';
import {ServicePolicy, PrivacyPolicy} from '@/pages/policy';
import {getAcademies, getCurrentSiteService} from '@/services/siteService';
import siteState from '@/stores/site';
import {getDomain} from '@/utils/env';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// 테스트용 new routes

function Router() {
  const [siteInfo, setSiteInfo] = useRecoilState(siteState.siteInfoState);
  const setSiteNav = useSetRecoilState(siteState.siteNavState);

  useEffect(() => {
    (async () => {
      let host = window.location.host;

      try {
        if (
          process.env.NODE_ENV === 'development' ||
          host === 'design-dev.lecture-on.com'
        ) {
          // host = 'design-dev.lecture-on.com';
          host = 'dev.lecture-on.com';
          // host = 'dev.lecture-on.com';
        }
        const currentSite = await getCurrentSiteService(host);
        const academies = await getAcademies(currentSite.id);
        setSiteInfo({...currentSite, academies});
      } catch (e) {
        console.log('error', e);
        alert('등록된 사이트 정보가 없습니다.');
        location.replace(getDomain()); // 랜딩 페이지로 리다이렉트
      }
    })();
  }, []);

  useEffect(() => {
    if (!siteInfo) return;
    if (siteInfo.subDomain === 'gwcloud') {
      setSiteNav(() => [
        {name: '공지사항', path: '/notice'},
        {name: '교육신청', path: '/register'},
        {name: '교육수강', path: '/course'},
        {name: '수료증', path: '/certificate'},
        {name: '고객 문의', path: '/ask'},
      ]);
    }
  }, [siteInfo]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* 메인 페이지 */}
        <Route path="/" element={<NewMain />} />
        {/*<Route path="/" element={<Main />} />*/}
        {/* 공지 페이지 */}
        <Route path="/notice" element={<NewNotice />} />
        <Route path="/notice/:id" element={<NewNoticeDetail />} />
        {/* 약관 페이지 */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/service" element={<ServicePolicy />} />
        {/* 서비스 문의 페이지 */}
        <Route path="/ask" element={<NewAskPage />} />
        <Route path="/new-ask" element={<NewAskPage />} />
        {/* 문의 관련 */}
        <Route path="/question" element={<NewFAQ />} />
        <Route path="/question/service" element={<NewFAQ />} />
        <Route path="/question/service/:id" element={<NewFAQDetail />} />
        <Route path="/question/course" element={<NewQuestionCourse />} />
        <Route
          path="/question/course/:id"
          element={<NewQuestionCourseDetail />}
        />
        {/* 수강 신청 */}
        <Route path="/register" element={<NewRegister />} />
        <Route path="/register/:id" element={<NewRegisterDetail />} />
        {/* Public Routes */}
        <Route element={<PublicRoute />}>
          {/*<Route path="/sign-in" element={<SignIn />} />*/}
          {/*<Route path="/sign-up" element={<SignUp />} />*/}
          {/*<Route path="/user/find/email" element={<UserFindEmail />} />*/}
          {/*<Route path="/user/find/password" element={<UserFindPassword />} />*/}
          <Route path="/sign-in" element={<NewSignIn />} />
          <Route path="/sign-up" element={<NewSignUp />} />
          <Route path="/user/find/email" element={<NewUserFindEmail />} />
          <Route path="/user/find/password" element={<NewUserFindPassword />} />
        </Route>

        {/* 새 디자인 test 용 */}
        <>
          <Route path="/new-main" element={<NewMain />} />
          <Route path="/new-sign-in" element={<NewSignIn />} />
          <Route path="/new-sign-up" element={<NewSignUp />} />
          <Route path="/new-user/find/email" element={<NewUserFindEmail />} />
          <Route
            path="/new-user/find/password"
            element={<NewUserFindPassword />}
          />

          <Route path="/new-notice" element={<NewNotice />} />
          <Route path="/new-notice/:id" element={<NewNoticeDetail />} />

          <Route path="/new-register" element={<NewRegister />} />
          <Route path="/new-register/:id" element={<NewRegisterDetail />} />

          <Route path="/new-course" element={<NewCourse />} />
          <Route path="/new-course/:id" element={<NewCourseDetail />} />
          <Route path="/new-homework/:courseId" element={<NewHomework />} />

          <Route path="/new-question/course" element={<NewQuestionCourse />} />
          <Route
            path="/new-question/course/:id"
            element={<NewQuestionCourseDetail />}
          />
          <Route
            path="/new-question/course/write"
            element={<NewQuestionWrite />}
          />
          <Route path="/new-faq" element={<NewFAQ />} />
          <Route path="/new-faq/:id" element={<NewFAQDetail />} />
          <Route path="/new-lecture/:courseId" element={<NewLecture />} />
        </>

        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          {/* 결제중 페이지 */}
          <Route path="/pay-results" element={<PayResults />} />
          {/* 수료증 페이지 */}
          <Route path="/certificate" element={<NewCertificate />} />
          {/* 유저 */}
          <Route path="/user" element={<MyPage />} />
          <Route path="/user/update" element={<NewUserUpdate />} />
          <Route path="/user/withdraw" element={<NewUserWithDraw />} />
          {/* 교육과정 */}
          <Route path="/course" element={<NewCourse />} />
          <Route path="/course/:id" element={<NewCourseDetail />} />
          <Route path="/lecture/:courseId" element={<NewLecture />} />
          <Route path="/homework/:courseId" element={<NewHomework />} />
          {/* 문의 관련 */}
          <Route path="/question/course/write" element={<NewQuestionWrite />} />
        </Route>
        {/* 404 페이지 라우팅 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
