import {boolean, object, string, ref} from 'yup';

export const engKorRegex = new RegExp(/^[ㄱ-ㅎ|가-힣|a-z|A-Z|]+$/);
export const emailRegex = new RegExp(
  /[A-Za-z0-9+_.-]+@([A-Za-z0-9_]+\.)+[A-Za-z]{2,4}/,
);
export const cellphoneRegex = new RegExp(
  /^01([0|1|6|7|8|9])\s?([0-9]{3,4})\s?([0-9]{4})$/,
);
export const passwordRegex = new RegExp(
  /^(?!((?:[A-Z]+)|(?:[a-z]+)|(?:[~!@#$%^&*()_\][+=\\|;:'"`,.<>₩?/-]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*(){}_\][+=\\|;:'"`,.<>₩?/-]{8,30}$/,
);
export const birthdateRegex = new RegExp(/^\d{8}$/);
export function validateEmail(email: string) {
  return emailRegex.test(email);
}

export const ValidateEmailSchema = string()
  .required('이메일 주소를 입력해주세요.')
  .matches(emailRegex, '올바른 이메일 주소를 입력해주세요.');

export const ValidatePasswordSchema = string()
  .required('비밀번호를 입력해주세요.')
  .matches(
    passwordRegex,
    '영문 대/소문자, 숫자, 특수문자 중 2가지 이상의 조합을 사용하여 \n8~30자로 입력해 주세요.',
  );

export const ValidateSignInSchema = object({
  email: ValidateEmailSchema,
  password: ValidatePasswordSchema,
});

export const ValidateNameSchema = string()
  .required('이름을 입력해주세요.')
  .matches(engKorRegex, '한글, 영문자만 입력 가능합니다.');

export const ValidatePhoneSchema = string()
  .required('휴대폰 번호를 입력해주세요.')
  .matches(cellphoneRegex, '올바른 휴대폰 번호를 입력해주세요.');

export const ValidateBirthdateSchema = string()
  .required('생년월일을 입력해주세요.')
  .matches(birthdateRegex, '8자리 숫자를 입력해 주세요.(ex:20230918)');

export const ValidateSignUpFormSchema = object({
  name: ValidateNameSchema,
  email: ValidateEmailSchema,
  phone: ValidatePhoneSchema,
  password: ValidatePasswordSchema,
  rePassword: ValidatePasswordSchema.oneOf(
    [ref('password')],
    '동일한 비밀번호를 입력해 주세요.',
  ),
  isAuthPhone: boolean().oneOf([true], '휴대폰 인증을 진행해주세요.'),
  acceptPrivacyPolicy: boolean().oneOf(
    [true],
    '개인정보처리방침에 동의해주세요.',
  ),
  acceptServicePolicy: boolean().oneOf(
    [true],
    '서비스이용약관에 동의해주세요.',
  ),
});

export const ValidateSignUpAdditionalFormSchema = object({
  name: ValidateNameSchema,
  email: ValidateEmailSchema,
  phone: ValidatePhoneSchema,
  password: ValidatePasswordSchema,
  rePassword: ValidatePasswordSchema.oneOf(
    [ref('password')],
    '동일한 비밀번호를 입력해 주세요.',
  ),
  birthDate: ValidateBirthdateSchema,
  isAuthPhone: boolean().oneOf([true], '휴대폰 인증을 진행해주세요.'),
  acceptPrivacyPolicy: boolean().oneOf(
    [true],
    '개인정보처리방침에 동의해주세요.',
  ),
  acceptServicePolicy: boolean().oneOf(
    [true],
    '서비스이용약관에 동의해주세요.',
  ),
});

export const ValidateSignUpThirdPartySharePolicySchema = object({
  acceptThirdPartySharePolicy: boolean().oneOf(
    [true],
    '제3자정보제공약관에 동의해주세요.',
  ),
});

export const ValidateRegisterSiteSchema = object({
  name: string().required('기관명을 입력해주세요.'),
  siteId: string().required('기관 ID를 입력해주세요.'),
  subDomain: string().required('기관 URL을 입력해주세요'),
  verifySubDomain: boolean().oneOf([true], '기관 URL을 중복검사해주세요.'),
  acceptPolicy: boolean().oneOf([true], '이용약관에 동의해주세요.'),
});
