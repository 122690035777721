import {
  requestGetApplicationDetail,
  requestPostFormAnswer,
} from '@/api/applicationApi';
import {
  AnswerFormProps,
  ResponseApplyFormList,
  ResponseApplyFormListContentProps,
  ResponseDataProps,
} from '@/types/application.type';
import {BaseResponse} from '@/types/common.type';

export async function getApplicationFormDetail(
  courseId: string,
): Promise<ResponseApplyFormList<ResponseApplyFormListContentProps>> {
  if (!courseId) {
    throw new Error('교육과정 아이디를 확인해주세요.');
  }
  try {
    const response = await requestGetApplicationDetail(courseId);
    return response.data.data;
  } catch (e: any) {
    throw e.error.message;
  }
}

export async function postFormAnswer(
  uid: string,
  answeredFormData: AnswerFormProps,
): Promise<BaseResponse<ResponseDataProps>> {
  if (!uid) {
    throw new Error('교육과정 아이디를 확인해주세요.');
  }
  try {
    const response = await requestPostFormAnswer(uid, answeredFormData);
    return response.data;
  } catch (e: any) {
    throw e.error.message;
  }
}
